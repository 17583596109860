import axios, { CancelTokenSource } from 'axios';
import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import {CommitData} from "../../interfaces/CommitData";
import Config from "../../Config";
import {toast} from "react-toastify";
import Commit from "../other/Commit";
import TitleService from "../../services/TitleService";
import Pagination from "../other/Pagination";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Breadcrumb from '../other/Breadcrumb';
import InputGroup from '../other/InputGroup';
import Loading from '../Loading';

interface IProps {
    isExternal?: boolean;
}

const Commits:FC<IProps> = (props) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ skip, setSkip ] = useState<number>(0);
    const [ search, setSearch ] = useState<string>('');
    const [ activeSearch, setActiveSearch ] = useState<string>('');
    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ commitData, setCommitData ] = useState<CommitData[]>([]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        const getData = async () => {
            axios.get(`${Config.apiUrl}/commits/list`, {
                cancelToken: axiosCancelSource.current?.token,
                withCredentials: true
            }).then((response) => {
                if (!response.data.error) {
                    setCommitData(response.data);
                    setLoaded(true);
                } else
                    toast.error(response.data.error);
            }).catch(() => toast.error('Unexpected error occurred!'));
        }
        getData().then(() => console.log('Data loaded'));
        return () => axiosCancelSource.current?.cancel();
    }, []);

    useEffect(() => {
        if (!props.isExternal)
            TitleService.set('Commits');
    }, [ props.isExternal ]);

    const handleSearch = (e: FormEvent<HTMLFormElement>) => { e.preventDefault(); setActiveSearch(search); }

    const newCommitData: CommitData[] = [];
    commitData.forEach((item) => 
        (!activeSearch || (
            (item.editorId.toLowerCase().includes(activeSearch.toLowerCase())) || 
            (item.type.toLowerCase().includes(activeSearch.toLowerCase())) || 
            (item.documentId && item.documentId.toLowerCase().includes(activeSearch.toLowerCase())) || 
            (item.collectionId && item.collectionId.toLowerCase().includes(activeSearch.toLowerCase()))
        )) ? newCommitData.push(item) : null);

    return (
        <>
            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Commits</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Commits', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <Pagination 
                            skip={skip} 
                            total={commitData.length} 
                            perPage={20} 
                            next={() => setSkip(skip + 20)} 
                            prev={() => setSkip(skip - 20)} 
                        />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <form onSubmit={handleSearch}>
                            <InputGroup type="text" icon={faSearch} placeholder="Search" value={search} onChange={(e) => setSearch(e)} />
                        </form>
                    </div> 
                </div>
            </div>
            {loaded ? (
                <div className="main-container-none"> 
                    <div className={"table-container"}>
                        <div className="table-head">
                            <div className="w-10">#</div>
                            <div className="w-full sm:w-64 mr-auto">Editor</div> 
                            <div className="hidden md:w-96 lg:block">Message</div>
                            <div className="w-40">Date</div>
                        </div>
                        {newCommitData.length !== 0
                            ? newCommitData.slice(skip, skip + 20).map((item, index) => <Commit key={index} size={'regular'} index={(skip + index)} {...item} /> )
                            : <div className={"table-none"}>There are no commits at the moment, check back later!</div>
                        }
                    </div>
                </div>
            ) : <Loading isPartial isDark />}
        </>
    )
}

export default Commits;