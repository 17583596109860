import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    skip: number;
    total: number;
    perPage: number;
    next: () => void;
    prev: () => void;
}

const Pagination = (props: IProps) => {

    const { skip, total, perPage, next, prev } = props;

    return (
        <div className="flex gap-2 flex-wrap">
            <div className="w-auto my-auto text-sm">
                Showing {skip}-{(skip + perPage) > total ? total : (skip + perPage)} of {total.toLocaleString()}
            </div>
            <button onClick={prev} type="button" className={`${skip === 0 ? 'pointer-events-none opacity-30' : ''} px-2 focus:outline-none text-grayblue-100 hover:text-grayblue-60 transition ease-in-out duration-300 my-auto`}>
                <FontAwesomeIcon icon={faAngleLeft} size="2x" />
            </button>
            <button onClick={next} type="button" className={`${(total <= (skip + perPage)) ? 'pointer-events-none opacity-30' : ''} px-2 focus:outline-none text-grayblue-100 hover:text-grayblue-60 transition ease-in-out duration-300 my-auto`}>
                <FontAwesomeIcon icon={faAngleRight} size="2x" />
            </button>
        </div>
    )
}

export default Pagination;