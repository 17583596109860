import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useEditorContext } from "./contexts/Editor.context";
import Login from "./pages/Login";
import Sidebar from "./Sidebar";
import Commits from "./pages/Commits";
import Editors from "./pages/Editors";
import { ToastContainer } from "react-toastify";
import Document from './pages/Content/Document';
import Content from './pages/Content/Main';
import Collection from './pages/Content/Collection';
import SchemasView from "./pages/Schemas/View";
import SchemasMain from "./pages/Schemas/Main";
import {SchemaProvider} from "./contexts/Schema.context";
import {CollectionProvider} from "./contexts/Collection.context";
import Media from "./pages/Media";
import {ExternalProvider} from "./contexts/External.context";
import Forms from "./pages/Forms";
import {TagProvider} from "./contexts/Tag.context";
import Automation from "./pages/Automation";
import ErrorNotFound from "./pages/404"; 
import Docs from './pages/Documentation';
import { BaseProvider } from './contexts/Base.context';
import { DataStoreProvider } from './contexts/DataStore.context';
import DataStore from './pages/DataStore';
import Playground from './pages/Playground';
import Announcement from './Announcement';
import Users from './pages/Users';
import { MediaProvider } from './contexts/Media.context';
import Files from './pages/Files';
import { FileProvider } from './contexts/File.context';
import { useAdminContext } from './contexts/Admin.context';
import { ProjectProvider } from './contexts/Project.context';
import AdminLogin from './pages/Admin/Login';
import Settings from './pages/Admin/Settings';
import Overview from './pages/Admin/Overview';
import Projects from './pages/Admin/Projects';
import Billing from './pages/Admin/Billing';
import Loading from './Loading';

const Container = () => {

    const { sessionLoaded, sessionData } = useEditorContext();
    const { adminLoaded, adminData } = useAdminContext();

    /*
    console.log('Zero If:', (sessionLoaded && adminLoaded), sessionLoaded, adminLoaded);
    console.log('First If:', (!adminData && !sessionData), (sessionData !== null && sessionData?.firstLogin), sessionData?.firstLogin);
    console.log('Second If:', (adminData !== null), (sessionData !== null && !sessionData?.firstLogin));
    */

    return (sessionLoaded && adminLoaded) ? (
        <>
            <ToastContainer position={'bottom-right'} />
            {(
                (!adminData && !sessionData) || 
                (!adminData && sessionData !== null && sessionData.firstLogin)
            ) ? (
                <>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/" exact={true} component={Login} />
                            <Route path="/login/:loginId" component={Login} />
                            <Route path="/login" component={Login} />
                            <Route path="/admin/login" component={AdminLogin} />
                        </Switch>
                    </BrowserRouter>
                </>
            ) : (
                <>
                    <BaseProvider>
                        <ProjectProvider>
                            <CollectionProvider>
                                <SchemaProvider> 
                                    <FileProvider>
                                        <MediaProvider>
                                            <DataStoreProvider>
                                                <ExternalProvider>
                                                    <TagProvider>
                                                        <BrowserRouter>
                                                            <div className={"flex"}>
                                                                <Sidebar />
                                                                <div className={"w-full lg:ml-72"}>
                                                                    <Announcement />
                                                                    <Switch>
                                                                        <Route path="/" exact={true} component={Content} />

                                                                        <Route path="/admin/dashboard" component={Overview} />
                                                                        <Route path="/admin/billing" component={Billing} />
                                                                        <Route path="/admin/account" component={Settings} />
                                                                        <Route path="/admin/projects/:id" component={Projects} />
                                                                        <Route path="/admin/projects" component={Projects} />

                                                                        <Route path="/commits" component={Commits} />
                                                                        <Route path="/media" component={Media} />
                                                                        <Route path="/files" component={Files} />
                                                                        <Route path="/editors" component={Editors} />
                                                                        <Route path="/users" component={Users} />
                                                                        <Route path="/content" component={Content} />
                                                                        <Route path="/collection/:id/:document" component={Document} />
                                                                        <Route path="/schema/:id" component={SchemasView} />
                                                                        <Route path="/schemas" component={SchemasMain} />
                                                                        <Route path="/forms/:id" component={Forms} />
                                                                        <Route path="/forms" component={Forms} />
                                                                        <Route path="/automation" component={Automation} />
                                                                        <Route path="/datastore" component={DataStore} />
                                                                        <Route path="/:type/:id" component={Collection} />
                                                                        <Route path="/docs" component={Docs} />
                                                                        <Route path="/playground" component={Playground} />

                                                                        <Route component={ErrorNotFound} />
                                                                    </Switch>
                                                                </div>
                                                            </div>
                                                        </BrowserRouter>
                                                    </TagProvider>
                                                </ExternalProvider>
                                            </DataStoreProvider>
                                        </MediaProvider>
                                    </FileProvider>
                                </SchemaProvider>
                            </CollectionProvider>
                        </ProjectProvider>
                    </BaseProvider>
                </>
            )}
        </>
    ) : <Loading />
} 

export default Container;