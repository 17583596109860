import { faBullhorn, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBaseContext } from "./contexts/Base.context";

const AnnouncementComponent = () => {
    const { announcement, localAnnouncement, setLocalAnnouncement } = useBaseContext();

    return (localAnnouncement !== announcement?.created && announcement?.message) ? (
        <button type="button" className="p-1.5 block w-full text-center bg-yellow-60 hover:bg-yellow-100 transition text-black font-semibold" onClick={() => setLocalAnnouncement(announcement?.created || '')}>
            <FontAwesomeIcon icon={faBullhorn} className="mr-1" /> {announcement?.message}
            <div className="absolute right-1.5 top-1.5 hover:opacity-70 transition">
                <FontAwesomeIcon icon={faTimes} />
            </div>
        </button>
    ) : <></>
}

export default AnnouncementComponent;