import {FC, useState, createContext, useContext, useEffect, useRef} from 'react';
import { ProjectData } from "../../interfaces/ProjectData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    projectData: ProjectData[];
    setProjectData: (project: ProjectData[]) => void;
}

export const ProjectContext = createContext<ContextType | null>(null);

export const ProjectProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ projectData, setProjectData ] = useState<ProjectData[]>([]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        axios.get(`${Config.apiUrl}/projects/list`, {
            cancelToken: axiosCancelSource.current.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setProjectData(response.data);
            else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
        return () => axiosCancelSource.current?.cancel();
    }, []);

    return <ProjectContext.Provider value={{ projectData, setProjectData }}>
        {children}
    </ProjectContext.Provider>;
}

export const useProjectContext = (): ContextType => {
    const context = useContext(ProjectContext);

    if (context == null) {
        throw new Error('useProjectContext must be used within a ProjectProvider');
    }

    return context;
}