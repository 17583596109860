import {FC, useEffect, useRef, useState} from 'react';
import { ObjectProps } from './GlobalObject';
import {DocumentData} from "../../interfaces/DocumentData";
import axios, {CancelTokenSource} from "axios";
import Config from "../../Config";

interface ReferenceProps extends ObjectProps {
    useDocumentName: string;
    useDocumentCollection: string;
}

const Reference:FC<ReferenceProps> = (props) => {

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const [ data, setData ] = useState<DocumentData[]>([]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        return () => axiosCancelSource.current?.cancel();
    }, [  ]);

    useEffect(() => {
        axios.get(`${Config.apiUrl}/documents/list/${props.default}`, {
            withCredentials: true
        })
            .then((response) => {
                if (!response.data.error)
                    setData(response.data);
            })
            .catch((e) => console.log(e));
    }, [ props.default, props.name ]);

    return (
        <div>
            <label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <select onChange={props.onChange}>
                <option value={""}>Select</option>
                {data?.map((item) => ((props.useDocumentCollection === props.default && props.useDocumentName !== item.documentId) || props.useDocumentCollection !== props.default) && <option key={item._id} value={item._id} selected={item._id === `${props.value.split('[id]:')[1]}`}>{item.name || item.documentId}</option>)}
            </select>
        </div>
    )
}

export default Reference;