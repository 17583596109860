import {FC, FormEvent, useEffect, useRef, useState} from 'react';
import moment from "moment";
import axios, {CancelTokenSource} from "axios";
import {faPlus, faProjectDiagram, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from "../../../Config";
import {toast} from "react-toastify";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import {CirclePicker} from 'react-color';
import { Link, Redirect } from 'react-router-dom';
import { useProjectContext } from '../../contexts/Project.context';
import Breadcrumb from '../../other/Breadcrumb';
import InputGroup from '../../other/InputGroup';
import { useAdminContext } from '../../contexts/Admin.context';

const Projects:FC = () => {

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { _csrf } = useCSRF();
    const { adminData, adminLoaded } = useAdminContext();
    const { projectData, setProjectData } = useProjectContext();

    // Modals
    const [ modalCreate, setModalCreate ] = useState<boolean>(false);

    // Inputs
    const [ projectName, setProjectName ] = useState<string | null>(null);
    const [ projectId, setProjectId ] = useState<string | null>(null);
    const [ color, setColor ] = useState<string | null>(null);
    const [ search, setSearch ] = useState<string>('');

    const handleCreate = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = { name: projectName, uuid: projectId, color, _csrf };

        axios.post(`${Config.apiUrl}/projects/create`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success('Your project has been created!');
                setProjectName(null);
                setProjectId(null);
                setColor(null);
                setProjectData([ ...projectData, response.data ]);
                setModalCreate(false);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Projects')
        return () => axiosCancelSource.current?.cancel();
    }, []);

    const processedProjectData = projectData.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));

    return (adminLoaded && adminData !== null) ? (
        <>
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 transition ease-in-out duration-300 ${modalCreate ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"focus:outline-none absolute top-4 right-4 btn-action text-gray-700 hover:text-gray-500 transition ease-in-out duration-300"} onClick={() => setModalCreate(false)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-2xl"} />
                        </button>
                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-4"}>Create</h2>
                        <form method={"post"} onSubmit={handleCreate}>
                            <div className={"flex flex-wrap mb-2"}>
                                <div className={"w-full md:w-2/5 my-auto"}>
                                    <div className={"text-gray-900 font-semibold my-auto"}>
                                        Title
                                    </div>
                                </div>
                                <div className={"w-full md:w-3/5"}>
                                    <input type={"text"} className={"input-basic"} name={"projectName"} value={projectName ? projectName : ''} onChange={(e) => setProjectName(e.target.value)} />
                                </div>
                            </div>

                            <div className={"flex flex-wrap mb-4"}>
                                <div className={"w-full md:w-2/5 my-auto"}>
                                    <div className={"text-gray-900 font-semibold my-auto"}>
                                        Unique Name
                                    </div>
                                </div>
                                <div className={"w-full md:w-3/5"}>
                                    <input type={"text"} className={"input-basic"} name={"projectId"} value={projectId ? projectId : ''} onChange={(e) => setProjectId(e.target.value)} />
                                </div>
                            </div>

                            <div className={"flex flex-wrap mb-4"}>
                                <div className={"w-full md:w-2/5 my-auto"}>
                                    <div className={"text-gray-900 font-semibold my-auto"}>
                                        Accent Color
                                    </div>
                                </div>
                                <div className={"w-full md:w-3/5"}>
                                    <CirclePicker
                                        onChangeComplete={(color) => setColor(color.hex)}
                                    />
                                </div>
                            </div>

                            <div className={"flex flex-wrap mb-2"}>
                                <div className={"w-full md:w-2/5 my-auto"}>
                                    <div className={"text-gray-900 font-semibold my-auto"}>
                                        Plan
                                    </div>
                                </div>
                                <div className={"w-full md:w-3/5"}>
                                    Basic - Free
                                </div>
                            </div>

                            <div className={"mt-4 flex justify-end"}>
                                <button type={"submit"} className={"button-small button-cyan"}>Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Projects</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Projects', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <InputGroup type="text" icon={faSearch} onChange={(e) => setSearch(e)} value={search} placeholder="Search" />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <button className="button-small button-cyan hover:cursor-pointer" onClick={() => setModalCreate(true)}>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                        </button>
                    </div>
                </div>
            </div>

            <div className="main-container-none p-6"> 
                <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8"}>
                    {processedProjectData.map((item, key) => (
                        <Link to={`/admin/projects/${key}`} className={"block w-full border rounded-lg overflow-hidden focus:outline-none hover:border-gray-300 transition ease-in-out duration-300"}>
                            <div className={"bg-white shadow-lg border-l-4"} style={{ borderColor: item.color || '#333' }}>
                                <div className={`p-5 border-l border-r border-gray-200`}>
                                    <div className={"h2"}>{item.name}</div>
                                    <div className={"text-sm pt-1 text-gray-500"}><FontAwesomeIcon icon={faProjectDiagram} className={"text-xs"} /> {item.projectUUID}</div>

                                    <div className={"flex mt-6"}>
                                        <div className={"w-auto mr-auto"}>
                                            <span className={"rounded px-2 py-0.5 bg-gray-200 text-xs uppercase text-gray-900 font-semibold"}>
                                                {item.planTier} Plan
                                            </span>
                                        </div>
                                        <div className={"w-auto text-sm text-gray-600 mt-auto"}>
                                            Created {moment(item.created).fromNow()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                {processedProjectData.length === 0 && (
                    <div className={"table-none"}>
                        No projects have been found!
                    </div>
                )}
            </div>
        </>
    ) : adminLoaded ? <Redirect to="/" /> : <></>;
}

export default Projects;