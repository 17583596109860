import {FC, useEffect} from 'react';
import {faCreditCard, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {BillingData} from "../../../interfaces/BillingData";
import moment from "moment";
import TitleService from "../../../services/TitleService";
import { toast } from 'react-toastify';
import Breadcrumb from '../../other/Breadcrumb';
import { useAdminContext } from '../../contexts/Admin.context';
import { Redirect } from 'react-router-dom';

const Billing:FC = () => {

    const { adminLoaded, adminData } = useAdminContext();

    const data: BillingData[] = [
        {
            date: 1626800620 * 1000,
            amount: 0.32,
            card: 1938
        },
        {
            date: 1626800620 * 1000,
            amount: 0.32,
            card: 1938
        },
        {
            date: 1626800620 * 1000,
            amount: 0.32,
            card: 1938
        },
        {
            date: 1626800620 * 1000,
            amount: 0.32,
            card: 1938
        }
    ];

    useEffect(() => TitleService.set('Billing'));

    return (adminLoaded && adminData !== null) ? (
        <>  
            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Billing</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Billing', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <button type={"button"} className={"button-small button-black"} onClick={() => toast.error("This functionality has not been implemented yet!")}>
                            Pay All Oustanding
                        </button>
                    </div>
                </div>
            </div>

            <div className="main-container-none p-6"> 
                <div className={"grid grid-cols-1 lg:grid-cols-3 gap-4"}>
                    <div className={"bg-white rounded-xl shadow p-6"}>
                        <div className={"text-sm text-gray-500 uppercase font-semibold"}>Add Balance</div>
                        <div className={"relative mt-2"}>
                            <input type={"price"} className={"input-basic input-basic-absolute"} placeholder={"1.00"} value={"2.36"} disabled />
                        </div>
                    </div>
                    <div className={"bg-white rounded-xl shadow p-6"}>
                        <div className={"text-sm text-gray-500 uppercase font-semibold"}>Balance</div>
                        <div className={"text-5xl text-gray-900 font-bold"}>-$2.36</div>
                    </div>
                    <div className={"bg-white rounded-xl shadow p-6"}>
                        <div className={"text-sm text-gray-500 uppercase font-semibold"}>Usage (this month)</div>
                        <div className={"text-5xl text-gray-900 font-bold"}>$9.32</div>
                    </div>
                </div>

                <div className={"bg-white border shadow-lg rounded-lg p-6 mt-6"}>
                    <h2 className={"h1 pb-4"}>Recent Payments</h2>
                    <div className="table w-full">
                        <div className={"table-head"}>
                            <div className={"w-10 text-center"}>#</div>
                            <div className={"w-32 mr-auto"}>Date</div>
                            <div className={"w-40"}><FontAwesomeIcon icon={faCreditCard} /></div>
                            <div className={"w-20"}>Amount</div>
                        </div>

                        {data.map((item, index) => (
                            <div className={"table-row"}>
                                <div className={"w-10 text-center font-semibold"}>{index + 1}.</div>
                                <div className={"w-32 mr-auto"}>{moment(item.date).format('LL')}</div>
                                <div className={"w-40"}>Ending in {item.card}</div>
                                <div className={"w-20"}>${item.amount.toLocaleString()}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={"mt-4 p-4 bg-yellow-200 border-yellow-300 rounded border shadow mb-4"}>
                    <div className={"flex text-yellow-900"}>
                        <div className={"w-12 my-auto"}>
                            <FontAwesomeIcon icon={faInfoCircle} size={"2x"} />
                        </div>

                        <div className={"w-full"}>
                            <div className={"text-lg font-bold"}>Notice</div>
                            <div className={"-mt-1"}>
                                This page is non-functional and is purely showcasing placeholder data.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : adminLoaded ? <Redirect to="/" /> : <></>;
}

export default Billing;