import {FC, FormEvent, useEffect, useRef, useState} from 'react';
import {
    faAngleDown, faAngleLeft, faAngleRight, faBolt,
    faCropAlt, faCubes, faDatabase, faEdit, faExternalLinkSquareAlt, faFile, faFileContract, faFileInvoiceDollar, faFilePdf,
    faImage, faLayerGroup, faQuestionCircle, faSatellite, faSignOutAlt,
    faStickyNote, faTimes, faTrash, faUserCog, faUserFriends, faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import { NavLink, useLocation } from 'react-router-dom';
import {useEditorContext} from "./contexts/Editor.context";
import {toast} from "react-toastify";
import axios, {CancelTokenSource} from "axios";
import Config from "../Config";
import useCSRF from "./hooks/useCSRF";
import {useExternalContext} from "./contexts/External.context";
import {ExternalData} from "../interfaces/ExternalData";
import {confirmAlert} from "react-confirm-alert";
import config from "../Config";
import getLevel from '../services/getLevel';
import logo from '../assets/logo.png';
import { useAdminContext } from './contexts/Admin.context';

interface SidebarItemProps {
    icon: IconDefinition;
    name: string;
    url?: string;
    exact?: boolean;
    level: number;
    nlIsActive?: () => boolean;
    isActive?: boolean;
    isButton?: () => void;
    isDropdown?: boolean;
    isExternal?: boolean;
    isSideItem?: boolean;
    isSideItemWithButton?: boolean;

    isAdmin?: boolean;
}

const AuthItem = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { setSessionData, sessionData } = useEditorContext();
    const { adminData } = useAdminContext();

    const handleLogout = (onlyEditor: boolean = true) => {
        axios.get(`${Config.apiUrl}/auth/logout${onlyEditor ? '?onlyEditor=true' : ''}`, { cancelToken: axiosCancelSource.current?.token, withCredentials: true }).then((response) => {
            if (!response.data.error) {
                setSessionData(null);
                return window.location.href = '/';
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        return () => axiosCancelSource.current?.cancel();
    }, []);

    return sessionData ? (
        <div className="flex justify-between p-3">
            <div className="w-56 my-auto text-white">
                <div className={"font-semibold text-sm truncate"}>{sessionData.editorId}</div>
                <div className={"text-xs -mt-0.5"}>{getLevel(sessionData.level)}</div>
            </div>
            <div className="w-auto my-auto">
                <button className="focus:outline-none hover:opacity-70 text-white transition ease-in-out duration-300 text-xl" onClick={() => handleLogout(true)}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
            </div>
        </div>
    ) : adminData ? (
        <div className="flex justify-between p-3">
            <div className="w-56 my-auto text-white">
                <div className={"font-semibold text-sm truncate"}>{adminData.firstName} {adminData.lastName}</div>
                <div className={"text-xs -mt-0.5"}>{adminData.emailAddress}</div>
            </div>
            <div className="w-auto my-auto">
                <button className="focus:outline-none hover:opacity-70 text-white transition ease-in-out duration-300 text-xl" onClick={() => handleLogout(false)}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
            </div>
        </div>
    ) : <></>;
}

const SidebarItem:FC<SidebarItemProps> = (props) => {

    const { sessionData } = useEditorContext();

    const cssBase = (props.isSideItem || props.isSideItemWithButton) ? 'side-link' : `nav-link`;
    const cssActive = props.isDropdown ? 'is-dropdown-active' : `is-active`

    return (props.isAdmin || (!props.isAdmin && sessionData && sessionData.level >= props.level)) ? (
        <div className={"block mx-8"}>
            {props.isExternal ? (
                <a href={props.url || ''} target={"_blank"} rel={"noopener noreferrer"} className={`${cssBase}`}>
                    {!props.isSideItem && (
                        <div className={`${props.isSideItemWithButton ? 'w-6' : 'w-7'}`}>
                            <FontAwesomeIcon icon={props.icon} />
                        </div>
                    )}
                    <div>
                        {props.name}
                    </div>
                </a>
            ) : (
                (!props.isButton ? (
                    <NavLink to={props.url || ''} exact={props.exact} className={`${cssBase}`} activeClassName={cssActive} isActive={props.nlIsActive}>
                        {!props.isSideItem && (
                            <div className={`${props.isSideItemWithButton ? 'w-6' : 'w-7'}`}>
                                <FontAwesomeIcon icon={props.icon} />
                            </div>
                        )}
                        <div>
                            {props.name}
                        </div>
                    </NavLink>
                ) : (
                    <button onClick={props.isButton} className={`w-full text-left focus:outline-none ${cssBase} ${!props.isActive ? '' : cssActive}`}>
                        {!props.isSideItem && (
                            <div className={`${props.isSideItemWithButton ? 'w-6' : 'w-7'}`}>
                                <FontAwesomeIcon icon={props.icon} />
                            </div>
                        )}

                        <div>
                            {props.name}
                        </div>
                        {props.isDropdown && (
                            <div className={`ml-auto w-auto ${props.isActive ? 'transition-all transform rotate-180 transition ease-in-out' : ''}`}>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                        )}
                    </button>
                ))
            )}
        </div>
    ) : <></>;
}

const Sidebar:FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { pathname } = useLocation();
    const { _csrf } = useCSRF();
    const { sessionData, sessionLoaded } = useEditorContext();
    const { adminData, adminLoaded } = useAdminContext();
    
    const { externalData, setExternalData } = useExternalContext();

    const [ collapse, setCollapse ] = useState<string>('');
    const [ modal, setModal ] = useState(false);
    const [ type, setType ] = useState('');
    const [ input, setInput ] = useState('');
    const [ url, setUrl ] = useState('');
    const [ toggle, setToggle ] = useState(false);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        return () => axiosCancelSource.current?.cancel();
    }, []);

    const dashboardItems:SidebarItemProps[] = [
        {
            icon: faUsers,
            name: 'Editors',
            url: '/editors',
            level: 0
        },
        {
            icon: faStickyNote,
            name: 'Commits',
            url: '/commits',
            level: 0
        }
    ];

    const editorItems:SidebarItemProps[] = [
        {
            icon: faLayerGroup,
            name: 'Content',
            url: '/',
            nlIsActive: () => ['/', '/content'].includes(pathname) || pathname.includes('/collection'),
            level: 0,
        },
        {
            icon: faCropAlt,
            name: 'Schemas',
            url: '/schemas',
            nlIsActive: () => ['/schemas'].includes(pathname) || pathname.includes('/schema'),
            level: 2,
        },
        {
            icon: faDatabase,
            name: 'Data Store',
            url: '/datastore',
            level: 3,
        },
        {
            icon: faImage,
            name: 'Media',
            url: '/media',
            level: 0,
        },
        {
            icon: faFile,
            name: 'Files',
            url: '/files',
            level: 0,
        },
        {
            icon: faUserFriends,
            name: 'Users',
            url: '/users',
            level: 0,
        }
    ];

    const adminItems:SidebarItemProps[] = [
        {
            icon: faCubes,
            name: 'Projects',
            url: '/admin/dashboard',
            nlIsActive: () => ['/admin/dashboard', '/admin/projects'].includes(pathname) || pathname.includes('/admin/projects'),
            level: 0,
        },
        {
            icon: faFileInvoiceDollar,
            name: 'Billing',
            url: '/admin/billing',
            level: 0,
        },
        {
            icon: faUserCog,
            name: 'Settings',
            url: '/admin/account',
            level: 0,
        }
    ];

    const servicesItems:SidebarItemProps[] = [
        {
            icon: faFileContract,
            name: 'Forms',
            url: '/forms',
            level: 0
        },
        {
            icon: faBolt,
            name: 'Automation',
            url: '/automation',
            level: 0
        },
    ];

    const supportItems:SidebarItemProps[] = [
        {
            icon: faQuestionCircle,
            name: 'Documentation',
            url: '/docs',
            level: 0
        },
    ];

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const allowedTypes = [ 'External' ];

        if (!allowedTypes.includes(type))
            toast.error(`Invalid type provided! ${type}`);

        const formData = { name: input, url, _csrf };

        axios.post(`${Config.apiUrl}/${type === 'External' ? 'externals' : 'tags'}/create`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                if (type === 'External')
                    setExternalData([ ...externalData, response.data ]);

                setInput('');
                setUrl('');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleDelete = (id: string) => {
        const allowedTypes = [ 'External' ];

        if (!allowedTypes.includes(type))
            toast.error(`Invalid type provided! ${type}`);

        const formData = { name: id, _csrf };

        axios.post(`${Config.apiUrl}/${type === 'External' ? 'externals' : ''}/remove`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            let i:number;
            if (!response.data.error) {
                if (type === 'External') {
                    const externalDataLength = externalData.length;
                    const newData: ExternalData[] = [];

                    for (i = 0; i < externalDataLength; i++) {
                        if (externalData[i].externalId !== response.data.externalId)
                            newData.push(externalData[i]);
                    }

                    setExternalData([ ...newData ]);
                }

            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const toggleModalNav = () => {
        setModal(!modal);
        setToggle(!toggle);
    }

    return (
        <>
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 transition ease-in-out duration-300 ${modal ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>{type} Manager</h2>
                        <div className={"py-4"}>
                            <div className={"smallHeading pb-2"}>Manage</div>
                            <div className={"h-48 overflow-y-auto overflow-x-hidden"}>
                                {(type === 'External' && externalData.length !== 0) ? externalData.map((item) => (
                                    <div className={"flex px-4 py-2 mb-2 bg-gray-200 rounded-lg text-gray-700"}>
                                        <div className={"w-full font-semibold"}>
                                            {item.externalId}
                                        </div>
                                        <div className={"w-auto ml-auto"}>
                                            <button type={"button"} className="text-red-400 hover:text-red-500 focus:outline-none transition ease-in-out duration-300" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this external link?", buttons: [{ label: "Yes", onClick: () => handleDelete(item.externalId) }, { label: "No", onClick: () => false }] })}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                )) : type === 'External' && <div className={"w-full block pt-20 text-center text-gray-900 font-semibold"}>You have no external links created.</div>}
                            </div>


                            <h2 className={"smallHeading pt-4"}>Create</h2>
                            <form method={"post"} onSubmit={handleSubmit} className={"grid grid-cols-1 gap-2"}>
                                <div className={"pt-2"}>
                                    <input type={"text"} className={`input-basic`} name={"name"} placeholder={"Name - Only letters, numbers and underscores allowed."} onChange={(e) => setInput(e.target.value)} value={input} required />
                                </div>
                                {type === 'External' && (
                                    <input type={"text"} className={"input-basic"} name={"url"} placeholder={"URL - https://example.com/"} onChange={(e) => setUrl(e.target.value)} value={url} required />
                                )}
                                <div className={"flex"}>
                                    <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <button type={"button"} onClick={() => setToggle(!toggle)} className={`focus:outline-none ${toggle ? 'w-0' : 'w-8'} fixed top-0 mt-px h-8 flex bg-gray-900 hover:bg-gray-800 rounded-r`}>
                <div className={"m-auto text-white text-2xl"}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </div>
            </button>

            <div className={`bg-gray-900 border-gray-800 whitespace-nowrap fixed top-0 left-0 bottom-0 z-50 border-r transition-all ease-in-out duration-300 ${toggle ? 'w-72' : 'invisible opacity-0 w-0 lg:opacity-100 lg:visible lg:w-72'}`}>
                <div className={"relative h-screen text-gray-700"}>
                    <div className={"flex pt-4 pl-8"}>
                        <div className={"text-left text-white text-2xl font-bold w-auto mr-auto"}>
                            <div className="flex">
                                <div className="w-12 my-auto pr-2">
                                    <img className="mt-1 w-full h-auto" alt="Bene" src={logo} />
                                </div>
                                <div className="w-auto">
                                    {config.name}
                                    {sessionData !== null && <div className={'text-xs -mt-1 text-gray-400 font-normal'}>{sessionData?.projectUUID}</div>}
                                </div>
                            </div>
                        </div>
                        <button type={"button"} onClick={() => setToggle(!toggle)} className={`focus:outline-none lg:hidden flex hover:opacity-70 transition ease-in-out duration-300 pr-10 -mt-1`}>
                            <div className={"m-auto text-white text-4xl"}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                        </button>
                    </div>

                    <div className={"sidebar-overflow"}>
                        {(sessionLoaded && sessionData) && (
                            <>
                                <div className={"px-8 pt-4 pb-1.5 flex text-xs uppercase text-gray-500"}>Main</div>
                                {editorItems.map((item) => <SidebarItem key={item.url} {...item} />)}
                            </>
                        )}
                        {(sessionLoaded && sessionData) && (
                            <>
                                <div className={"px-8 pt-4 pb-1.5 flex text-xs uppercase text-gray-500"}>Team</div>
                                {dashboardItems.map((item) => <SidebarItem key={item.url} {...item} />)}
                            </>
                        )}

                        {(adminLoaded && adminData !== null) && (
                            <>
                                <div className={"px-8 pt-4 pb-1.5 flex text-xs uppercase text-gray-500"}>Admin</div>
                                {adminItems.map((item) => <SidebarItem key={item.url} {...item} isAdmin />)}
                            </>
                        )}

                        {(sessionLoaded && sessionData) && (
                            <>
                                <div className={"px-8 pt-4 pb-1.5 flex text-xs uppercase text-gray-500"}>Other</div>
                                <SidebarItem key={'servicesCollapse'} level={0} name={'Services'} icon={faSatellite} isDropdown={true} isButton={() => setCollapse(value => value === 'serviceCollapse' ? '' : 'serviceCollapse')} isActive={collapse === 'serviceCollapse'} />
                                {/* -- Services */}
                                <div className={`side-bg ${collapse === 'serviceCollapse' ? 'opacity-100 max-h-nav transition-nav' : 'max-h-0 opacity-0 pointer-events-none transition-none'} ease-in duration-300`}>
                                    {servicesItems.map((item) => <SidebarItem key={item.url} level={0} icon={faFilePdf} name={item.name} url={item.url} isSideItem />)}
                                </div>
                                <SidebarItem key={'externalCollapse'} name={'Links'} level={0} icon={faExternalLinkSquareAlt} isDropdown={true} isButton={() => setCollapse(value => value === 'externalCollapse' ? '' : 'externalCollapse')} isActive={collapse === 'externalCollapse'} />
                                {/* -- External */}
                                <div className={`side-bg ${collapse === 'externalCollapse' ? 'opacity-100 max-h-nav transition-nav' : 'max-h-0 opacity-0 pointer-events-none transition-none'} ease-in duration-300`}>
                                    {externalData.map((item) => <SidebarItem level={0} isExternal key={item.url} icon={faFilePdf} name={item.name} url={item.url} isSideItem />)}
                                    {!externalData.length && <div className={"text-gray-400 py-3 text-sm px-10"}>No external links.</div>}
                                    <div className={"border-t border-gray-600"} />
                                    <SidebarItem key={'externalEdit'} level={3} name={'Manage'} icon={faEdit} isButton={() => { setType('External'); toggleModalNav(); }} isSideItemWithButton />
                                </div>
                                {supportItems.map((item) => <SidebarItem key={item.url} {...item} />)}
                            </>
                        )}

                    </div>

                    <div className={"absolute bottom-0 left-0 right-0 border-t border-gray-700"}>
                        <AuthItem />
                    </div>
                </div>
            </div>
        </>
    )
};

export default Sidebar;