// eslint-disable-next-line
export default (
    level: number
) => {
    if (!level || level === 0)
        return "Documents";
    else if (level === 1)
        return "Documents & Collections";
    else if (level === 2)
        return "Documents, Collections and Schemas";
    else if (level === 3)
        return "Admin";
    else if (level >= 4)
        return "Creator";
}