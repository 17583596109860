import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useState } from "react";
import { CirclePicker } from "react-color";

interface IProps {
    name: string;
    type: string;
    placeholder?: string;
    label: string;
    value: string | string[] | number | boolean;
    onChange: (v: string | string[] | boolean | number) => void;
}

const SettingsOption = (props: IProps) => {

    const [ nValue, setNValue ] = useState<string>('');

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => { 
        console.log(e.key);
        if (e.key === 'Enter') { 
            e.preventDefault();
            setNValue('');
            props.onChange(props.value ? [ ...props.value as string[], nValue ] : [ nValue ]);
        }
    }

    const handleDeleteMultiple = (key: number) => {
        console.log('test');
        const oldData = props.value;
        console.log(oldData, typeof oldData);
        if (oldData && typeof oldData === 'object' && typeof oldData.length !== 'undefined') {
            oldData.splice(key, 1);
            props.onChange([ ...oldData ]);
        }
    }

    return props.type === "text" ?  (
        <div className={"flex flex-wrap"}>
            <div className={"w-full md:w-2/5 my-auto"}>
                <div className={"text-gray-900 font-semibold my-auto"}>
                    {props.label}
                </div>
            </div>
            <div className={"w-full md:w-3/5"}>
                <input type={"text"} className={"input-basic"} name={props.name} placeholder={props.placeholder} value={String(props.value)} onChange={(e) => props.onChange(e.target.value)} />
            </div>
        </div>
    ) : props.type === "none" ? (
        <div className={"flex flex-wrap"}>
            <div className={"w-full md:w-2/5 my-auto"}>
                <div className={"text-gray-900 font-semibold my-auto"}>
                    {props.label}
                </div>
            </div>
            <div className={"w-full md:w-3/5"}>
                {props.value}
            </div>
        </div>
    ) : props.type === "enable" ? (
        <div className={"flex flex-wrap"}>
            <div className={"w-full md:w-2/5 my-auto"}>
                <div className={"text-gray-900 font-semibold my-auto"}>
                    {props.label}
                </div>
            </div>
            <div className={"w-full md:w-3/5"}>
                <select className="input-basic" name={props.name} onChange={(e) => props.onChange(e.target.value === 'true')}>
                    <option value={"true"} selected={(props.value === true || props.value === "true")}>Enabled</option>
                    <option value={"false"} selected={!props.value || props.value === "false"}>Disabled</option>
                </select>
            </div>
        </div>
    ) : props.type === "level" ? (
        <div className={"flex flex-wrap"}>
            <div className={"w-full md:w-2/5 my-auto"}>
                <div className={"text-gray-900 font-semibold my-auto"}>
                    {props.label}
                </div>
            </div>
            <div className={"w-full md:w-3/5"}>
                <select className="input-basic" name={props.name} onChange={(e) => props.onChange(parseInt(e.target.value, 10))}>
                    <option value={"1"} selected={(props.value === 0 || props.value === 1)}>Level 1</option>
                    <option value={"2"} selected={props.value === 2}>Level 2</option>
                    <option value={"3"} selected={props.value === 3}>Level 3</option>
                    <option value={"4"} selected={props.value === 4}>Level 4</option>
                </select>
            </div>
        </div>
    ) : props.type === "colorpicker" ? (
        <div className={"flex flex-wrap"}>
            <div className={"w-full md:w-2/5 my-auto"}>
                <div className={"text-gray-900 font-semibold my-auto"}>
                    {props.label}
                </div>
            </div>
            <div className={"w-full md:w-3/5"}>
                <CirclePicker
                    color={props.value || '#000' as any}
                    onChangeComplete={(color) => props.onChange(color.hex)}
                />
            </div>
        </div>
    ) : (props.type === "multiple" && (!props.value || (typeof props.value === 'object' && typeof props.value !== 'undefined'))) ? (
        <div className={"flex flex-wrap"}>
            <div className={"w-full md:w-2/5 my-auto"}>
                <div className={"text-gray-900 font-semibold my-auto"}>
                    {props.label}
                </div>
            </div>
            <div className={"w-full md:w-3/5"}>
                <input type="text" className="input-basic" placeholder={props.placeholder} value={nValue} onChange={(e) => setNValue(e.target.value)} onKeyDown={handleKeyDown} />
                <div className="mt-2 flex space-x-2">
                    {((props.value || []) as string[]).map((item, key) => (
                        <div key={key} className="relative bg-gray-100 rounded-full py-1 pl-4 pr-6 text-sm font-semibold">
                            {item}
                            <button type="button" onClick={() => handleDeleteMultiple(key)} className="text-xs right-2   top-1.5 absolute">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : <></>;
}

export default SettingsOption;