import {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {faImage, faPlus, faSave, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ObjectProps } from './GlobalObject';
import { MediaData } from "../../interfaces/MediaData";
import axios, {CancelTokenSource} from "axios";
import {toast} from "react-toastify";
import Config from "../../Config";
import useCSRF from "../hooks/useCSRF";
import moment from "moment";
import getRelativeSize from '../../services/getRelativeSize';
import InputGroup from '../other/InputGroup';
import { useMediaContext } from '../contexts/Media.context';
import {formatFileName, formatMediaUrl} from '../../utils/formatS3ItemUrl';

const Image:FC<ObjectProps> = (props) => {

    const { mediaData, fetchMediaData } = useMediaContext();

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const [ modal, setModal ] = useState<boolean>(false);
    const [ loaded, setLoaded ] = useState<boolean>(true);
    const [ imageSrc, setImageSrc ] = useState<string>(props.value || '');
    const [ newImageSrc, setNewImageSrc ] = useState<string>('');
    const [ newImageData, setNewImageData ] = useState<MediaData | null>(null);
    const [ activeSearch, setActiveSearch ] = useState<string>('');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    useEffect(() => {
        setImageSrc(props.value || '');
    }, [ props.value ]);

    const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        //e.preventDefault();

        const useFile = e.target.files;
        const useFormData = new FormData();

        if (!useFile)
            return toast.error("You do not have a file to be uploaded!");

        if (useFile?.length > 1)
            return toast.error("You can only upload one file at a time!");

        if (!useFile[0].type.startsWith('image'))
            return toast.error("You can only upload images!");

        // Start upload code here
        useFormData.append("image", useFile[0], useFile[0].name);
        useFormData.append("_csrf", _csrf);

        axios.post(`${Config.apiUrl}/media/create`, useFormData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!response.data.error)
                fetchMediaData();
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>, item: MediaData) => {
        setNewImageData(item);
        setNewImageSrc(`/media/${formatFileName(e.target.value)}`);

        console.log('onChange');
    }
    const onSave = () => {
        setImageSrc(newImageSrc);
        setNewImageSrc('');
        setNewImageData(null);
        setLoaded(true);
        setModal(false);
        const objectData = {
            target: {
                value: newImageSrc
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    const onReset = () => {
        setNewImageSrc('');
        setNewImageData(null);
        setImageSrc('');
        setLoaded(false);
        const objectData = {
            target: {
                value: ''
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    const pImageData: MediaData[] = [];
    mediaData.map((item) => (
        (!activeSearch || (activeSearch && item.mediaId.toLowerCase().includes(activeSearch.toLowerCase())))
    ) ? pImageData.push(item) : null);

    return (
        <>
            {modal && (
                <div className={`fixed w-full flex h-screen top-0 right-0 bottom-0 left-0 bg-black bg-opacity-20 transition ease-in-out duration-500 z-50`}>
                    <div className="w-full m-auto px-10 relative">
                        <div className="relative bg-white rounded w-11/12 sm:w-10/12 md:w-4/5 lg:w-4/6 mx-auto p-6">
                            <div className="flex flex-wrap border-b pb-3 mb-3 border-gray-200">
                                <div className="w-auto mr-auto my-auto">
                                    <span className="pageHeading">
                                        Media
                                    </span>
                                </div>
                                <div className="w-auto">
                                    <div className="flex flex-wrap gap-2">
                                        <div className={"w-full md:w-auto my-auto"}>
                                            <InputGroup type="text" icon={faSearch} placeholder="Search" value={activeSearch} onChange={(e) => setActiveSearch(e)}/>
                                        </div>
                                        <div className="my-auto media-upload-button">
                                            <input accept="image/*" id={"fileUpload"} name={"fileUpload"} style={{ display: 'none' }} type={"file"} onChange={onImageUpload} />
                                            <label style={{ color: '#fff', padding: '10px 16px', textTransform: 'none' }} className="button-small button-black hover:cursor-pointer" htmlFor={"fileUpload"}>
                                                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Upload
                                            </label>
                                        </div>
                                        {newImageSrc && (
                                            <div className="my-auto">
                                                <button type="button" onClick={onSave} className="h-full button-small button-cyan">
                                                    <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                                                </button>
                                            </div>
                                        )}
                                        <div className="my-auto">
                                            <button type="button" className="button-small button-gray" onClick={() => { setNewImageSrc(''); setModal(false) }}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`grid grid-cols-4`}>
                                <div className={`${newImageSrc ? 'col-span-full xl:col-span-3' : 'col-span-full'}`}>
                                    <div className={"flex flex-wrap gap-8 overflow-y-scroll content-start"} style={{ height: '70vh' }}>
                                        {pImageData.map((item, index) => (
                                            <div key={item._id} className={"fileCheckbox"}>
                                                <input type={"radio"} id={`fileSelection${index}`} name={"fileSelection"} value={item.source} onChange={(e) => onChange(e, item)} defaultChecked={item.source === imageSrc} />
                                                <label htmlFor={`fileSelection${index}`} style={{ paddingBottom: '0' }}>
                                                    <img src={formatMediaUrl(item.projectUUID, item.source)} alt={item.mediaId} />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {(newImageSrc && newImageData) && (
                                    <div className={"hidden xl:block lg:col-span-1 bg-gray-50 w-full h-full"}>
                                        <div className={"text-center mx-auto px-6 pt-6"}>
                                            <img className="mx-auto border-4 border-gray-4 rounded max-h-80 object-contain" src={`${Config.mediaUrl}/${pImageData[0].projectUUID}${newImageSrc}`} alt={"preview"} />
                                            <div className={"text-xs text-gray-700 pt-2"}>Added {moment(newImageData.created).fromNow()}</div>
                                            {(newImageData.width && newImageData.height) && (
                                                <>
                                                    <div className={"mt-6 grid grid-cols-2 pb-2 mb-2 border-b border-gray-300"}>
                                                        <div className={"text-left"}>
                                                            Resolution:
                                                        </div>
                                                        <div className={"text-right"}>
                                                            {newImageData.width}x{newImageData.height}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {newImageData.size && (
                                                <div className={"grid grid-cols-2"}>
                                                    <div className={"text-left"}>
                                                        Size:
                                                    </div>
                                                    <div className={"text-right"}>
                                                        {getRelativeSize(newImageData.size)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <label htmlFor={props.name} className="block pb-1">{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
                <div className="flex">
                    <div className="w-32 h-32 flex border-4 border-gray-200 bg-gray-100 rounded">
                        {!loaded ? (
                            <div className="text-center m-auto uppercase text-gray-600 font-semibold">
                                <FontAwesomeIcon icon={faImage} size={"2x"} />
                            </div>
                        ) : (pImageData && pImageData[0]) &&(
                            <img
                                src={`${Config.mediaUrl}/${pImageData[0].projectUUID}${imageSrc}`}
                                className="w-full h-auto object-cover"
                                onError={() => setLoaded(false)}
                                alt={props.name}
                            />
                        )}
                    </div>
                    <div className="pl-5 w-auto my-auto relative">
                        <input type={"text"} className="opacity-0 absolute left-0 pointer-events-none" value={loaded ? `${Config.mediaUrl}${imageSrc}` : ''} required={props.required} />
                        <button type="button" className="button-small button-cyan" onClick={() => setModal(true)}>Open</button>
                        {imageSrc && (
                            <button type="button" className="button-small button-red ml-2" onClick={() => onReset()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        )}
                        {props.hint && <div className="text-sm text-gray-500 mt-1">{props.hint}</div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Image;