const NotFound = () => {
    return (
        <div className="flex h-screen">
            <div className="tw-contaner m-auto py-10 text-center">
                <h1>Oh no!</h1>
                <div className="mt-8 text-center w-full sm:w-11/12 md:w-9/12 lg:w-6/12 mx-auto">
                    The page you are trying to access has either moved or no longer exists, if you think this is a problem with the CMS then please let us know! Otherwise feel free to navigate off this page anywhere else!
                </div>
            </div>
        </div>
    )
}

export default NotFound;