import {FC, useState, useEffect, useRef, FormEvent} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {faCog, faFile, faFolder, faPlus, faSearch, faTag, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, {CancelTokenSource} from "axios";
import {toast} from "react-toastify";
import Config from "../../../Config";
import {useCollectionContext} from "../../contexts/Collection.context";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import {useEditorContext} from "../../contexts/Editor.context";
import {useTagContext} from "../../contexts/Tag.context";
import { useSchemaContext } from '../../contexts/Schema.context';
import Breadcrumb from '../../other/Breadcrumb';
import InputGroup from '../../other/InputGroup';
import moment from 'moment';
import Loading from '../../Loading';
import { useAdminContext } from '../../contexts/Admin.context';
import { confirmAlert } from '../../other/TextConfirm';

const Collections: FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const { sessionData, sessionLoaded } = useEditorContext();
    const { adminData } = useAdminContext();
    const { tagLoaded, tagData, fetchTagData } = useTagContext();
    const { collectionLoaded, collectionData, fetchCollectionData } = useCollectionContext();
    const { schemaData } = useSchemaContext();

    const [ modal, setModal ] = useState<number | null>(null);
    const [ search, setSearch ] = useState<string>('');

    const [ defaultSchema, setDefaultSchema ] = useState<string>('');
    const [ useSEOTab, setUseSEOTab ] = useState<boolean>(true);
    const [ formType, setFormType ] = useState<string>('');
    const [ formInput, setFormInput ] = useState<string>('');
    const [ originalInput, setOriginalInput ] = useState<string>('');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Content');
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${Config.apiUrl}/${formType}s/create`, { name: formInput, defaultSchema, useSEOTab, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchCollectionData();
                fetchTagData();
                setModal(null);
                setFormInput('');
                setDefaultSchema('');
                setUseSEOTab(true);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleEdit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${Config.apiUrl}/${formType}s/update`, { name: originalInput, newName: formInput, defaultSchema, useSEOTab, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchCollectionData();
                setModal(null);
                setFormInput('');
                setOriginalInput('');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleDelete = (type: string, name?: string) => {
        axios.post(`${Config.apiUrl}/${type}s/remove`, { name: name || originalInput, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchCollectionData();
                fetchTagData();
                setModal(null);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    return (
        <>
            {(sessionLoaded && !sessionData && adminData !== null) && <Redirect to="/admin/dashboard" />}
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 z-20 ${modal !== null ? 'opacity-100' : 'pointer-events-none opacity-0'} transition ease-in-out duration-300`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(null)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Configure</h2>
                        <form method={"post"} onSubmit={modal === 0 ? handleCreate : handleEdit} className={"grid grid-cols-1 py-4 gap-8"}>
                            {modal === 0 && (
                            <div>
                                    <label className={"block pb-2"}>Type</label>
                                    <select className={"input-basic"} name={"type"} onChange={(e) => setFormType(e.target.value.toLowerCase())}>
                                        <option value={""}>Dropdown</option>
                                        {['Collection', 'Tag'].map((item, index) => <option key={index} value={item.toLowerCase()} selected={item.toLowerCase() === formType}>{item}</option>)}
                                    </select>
                                </div> 
                            )} 
                            {modal === 0 && (
                                <div>
                                    <label className={"block pb-2"}>Name</label>
                                    <input type={"text"} className={"input-basic"} name={"name"} placeholder={"Maximum: 96 characters"} value={formInput} onChange={(e) => setFormInput(e.target.value)} required />
                                </div>
                            )}
                            {formType === 'collection' && (
                                <>
                                    <div>
                                        <label className={"block pb-2"}>Default Schema</label>
                                        <select className={"input-basic"} name={"defaultSchema"} onChange={(e) => setDefaultSchema(e.target.value)}>
                                            <option value={""}>Dropdown</option>
                                            {schemaData.map((item) => <option key={item._id} value={item._id} selected={item._id === defaultSchema}>{item.schemaId}</option>)}
                                        </select>
                                    </div>
                                    <div>
                                        <label className={"block pb-2"}>Show SEO Tab</label>
                                        <select className={"input-basic"} name={"useSEOTab"} onChange={(e) => setUseSEOTab(e.target.value === '1')}>
                                            <option value={""}>Dropdown</option>
                                            <option value="1" selected={useSEOTab === true}>Yes</option>
                                            <option value="0" selected={useSEOTab === false}>No</option>
                                        </select>
                                    </div>
                                </>
                            )}
                            <div className={"flex"}>
                                {(modal !== 0 && sessionData && sessionData?.level >= 1) 
                                    ? <button type={"button"} className="button-tiny button-red-text" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this collection, deleting this collection WILL delete all documents inside?", value: String(formInput), onSuccess: () => handleDelete('collection') })}><FontAwesomeIcon icon={faTrash} /> Delete</button>
                                    : ''
                                }
                                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Content</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Content', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <InputGroup type="text" icon={faSearch} onChange={(e) => setSearch(e)} value={search} placeholder="Search" />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <button className="button-small button-cyan hover:cursor-pointer" onClick={() => setModal(0)}>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                        </button>
                    </div>
                </div>
            </div>

            {(tagLoaded && collectionLoaded) ? (
                <div className="main-container-none"> 
                    <div className={"table-container"}>
                        <div className="table-head">
                            <div className="w-8" />
                            <div className="w-48 mr-auto">Name</div>
                            <div className="w-16"><FontAwesomeIcon icon={faFile} /></div>
                            <div className="w-40">Created</div>
                            <div className="w-10" />
                        </div>
                        {collectionData && collectionData.map((item) => (!search || (search && item.collectionId.toLowerCase().includes(search.toLowerCase()))) && (
                            <Link to={`/collection/${item.collectionId}`} className="table-row">
                                <div className="w-8 my-auto">
                                    <FontAwesomeIcon icon={faFolder} />
                                </div>
                                <div className={"w-48 mr-auto my-auto"}>
                                    {item.collectionId}
                                </div>
                                <div className={"w-16 my-auto"}>
                                    {(item.documents || 0).toLocaleString()}
                                </div>
                                <div className={"w-40 my-auto"}>
                                    {moment(item.created).fromNow()}
                                </div>
                                <div className="w-10 flex gap-x-2 justify-end" onClick={(e) => e.preventDefault()}>
                                    {(sessionData && sessionData?.level >= 1) 
                                        ? <button type={"button"} className="button-tiny button-cyan" onClick={() => { setFormType('collection'); setUseSEOTab(item.useSEOTab); setDefaultSchema(item.defaultSchema); setOriginalInput(item.collectionId); setFormInput(item.collectionId); setModal(1) }}><FontAwesomeIcon icon={faCog} /></button>
                                        : ''
                                    }
                                </div>
                            </Link>
                        ))}
                        {tagData && tagData.map((item) => (!search || (search && item.name.includes(search))) && (
                            <Link to={`/tagged/${item.name}`} className="table-row">
                                <div className="w-8 my-auto">
                                    <FontAwesomeIcon icon={faTag} className={"text-gray-400"} />
                                </div>
                                <div className={"w-48 my-auto mr-auto text-gray-800"}>
                                    {item.name}
                                </div>
                                <div className={"w-16 my-auto"}>
                                    {item.documents.toLocaleString()}
                                </div>
                                <div className={"w-40 my-auto"}>
                                    {moment(item.created).fromNow()}
                                </div>
                                <div className="w-10 flex gap-x-2 justify-end" onClick={(e) => e.preventDefault()}>
                                    {(sessionData && sessionData?.level >= 1) 
                                        ? <button type={"button"} className="button-tiny button-red" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this tag, deleting this will NOT delete all of the documents associated?", value: item.name, onSuccess: () => { setOriginalInput(item.name); handleDelete('tag', item.name)  } })}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                        : ''
                                    }
                                </div>
                            </Link>
                        ))}
                        {!tagData && !collectionData && <div className={"table-none"}>You do not have any content yet!</div>}
                    </div>
                </div>
            ) : <Loading isPartial isDark />}
        </>
    )
}

export default Collections;