import { ObjectProps } from './GlobalObject';
import { useDataStoreContext } from '../contexts/DataStore.context';

const DataStore = (props: ObjectProps) => {

    const { dataStoreData } = useDataStoreContext();

    return (
        <div>
            <label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <select onChange={props.onChange}>
                <option value={""}>Select</option>
                {dataStoreData?.map((item) => <option key={item._id} value={item.key} selected={item.key === `${String(props.value).split('[ds]:')[1]}`}>{item.key}</option>)}
            </select>
        </div>
    )
}

export default DataStore;