import { FC } from 'react';
import { ObjectProps } from './GlobalObject';

const Boolean:FC<ObjectProps> = (props) => {

    const booleanItems = [
        'No',
        'Yes'
    ]

    return (
        <div>
            <label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <select onChange={props.onChange}>
                {booleanItems?.map((item, key) => <option key={item} value={key} selected={key === parseInt(props.value as string, 10)}>{item}</option>)}
            </select>
        </div>
    )
}

export default Boolean;