import { FC } from 'react';
import { ObjectProps } from './GlobalObject';

const Select:FC<ObjectProps> = (props) => {
    return (
        <div>
            <label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <select onChange={props.onChange}>
                <option key={"d999"} value={""}>Dropdown</option>
                {props.items?.map((item) => <option key={item} value={item} selected={item === props.value}>{item}</option>)}
            </select>
        </div>
    )
}

export default Select;
