import { faFont, faTextWidth, faList, faSortNumericUp, faFile, faDatabase, faImage, faCircle, faCheck, faTimes, faFileCode, faPlus, faMinus, faDiceTwo, faNetworkWired, faArrowCircleDown, faCaretDown, faCaretUp, faSortAlphaDown, faParagraph } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";
import axios, { CancelTokenSource } from "axios";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { Prompt, useParams } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Config from "../../../Config";
import { SchemaData } from "../../../interfaces/SchemaData";
import { SchemaObjectData } from "../../../interfaces/SchemaObjectData";
import TitleService from "../../../services/TitleService";
import { useCollectionContext } from "../../contexts/Collection.context";
import { useDataStoreContext } from "../../contexts/DataStore.context";
import { useSchemaContext } from "../../contexts/Schema.context";
import useCSRF from "../../hooks/useCSRF";
import Breadcrumb from "../../other/Breadcrumb";

const Schemas:FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { id } = useParams<{id?: string}>();

    const { collectionData } = useCollectionContext();
    const { dataStoreData } = useDataStoreContext();

    const { _csrf } = useCSRF();
    const { fetchSchemaData } = useSchemaContext();
    const [ useSchema, setUseSchema ] = useState<SchemaData | null>(null);
    const [ customJSON, setCustomJSON ] = useState<string>('');
    const [ customJSONParse, setCustomJSONParse ] = useState<boolean>(true);
    const [ JSONViewer, setJSONViewer ] = useState<boolean>(true);
    const [ modal, setModal ] = useState<boolean>(false);
    const [ unsaved, setUnsaved ] = useState<boolean>(false);

    const [ current, setCurrent ] = useState<number | null>(null);
    const [ subCurrent, setSubCurrent ] = useState<number | null>(null);

    // Drag States
    const [ dragging, setDragging ] = useState<boolean>(false);

    // Configuration Options
    const listOfWidgets = [ "string", "text", "select", "multiple", "markdown", "image", "file", "number", "boolean", "reference", "datastore", "object", "list" ];
    const listOfSubWidgets = [ "string", "text", "select", "multiple", "markdown", "image", "file", "number", "boolean", "reference", "datastore", "list" ];

    // Dragging States
    const generateNewIndex = (newIndex: number, length: number) => {
        console.log('New Index', newIndex, 'Total Length', length);
        let useIndex;
        if (newIndex > length) useIndex = 0;
        else if (newIndex < 0) useIndex = length;
        else useIndex = newIndex;
        return useIndex;
    }

    const handleDragging = (direction: number, indexId: number | null, subIndexId: number | null) => {
        setUseSchema((oldSchema) => {
            if (oldSchema === null) return null;
            const newSchema = { ...oldSchema };

            if (newSchema.json) {
                console.log((indexId !== null && subIndexId !== null) ? newSchema.json[indexId].fields : null);
                if (indexId !== null && subIndexId === null)  {
                    const schemaLength = newSchema.json.length - 1;
                    const useIndex = generateNewIndex((direction === 1 ? indexId + 1 : indexId - 1), schemaLength);
                    newSchema.json = arrayMove(newSchema.json, indexId, useIndex);
                } else if (indexId !== null && subIndexId !== null) {
                    // @ts-ignore
                    const schemaLength = newSchema.json[indexId].fields.length - 1;
                    const useIndex = generateNewIndex((direction === 1 ? subIndexId + 1 : subIndexId - 1), schemaLength);
                    // @ts-ignore
                    newSchema.json[indexId].fields = arrayMove(newSchema.json[indexId].fields, subIndexId, useIndex);
                    console.log(newSchema.json[indexId].fields);
                }
            }
           
            return { ...newSchema };
        });
    }
    
    // React
    const getSchemaData = useCallback(() => {
        setUseSchema(null);
        axios.get(`${Config.apiUrl}/schemas/list/${id}`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                TitleService.set(`${id}`);

                // Assign internal id so keys can be unique
                if (response.data.json) {
                    let i: number, j: number;
                    const responseLength = response.data.json.length;
                    for (i = 0; i < responseLength; i++) {
                        response.data.json[i]._internalId = i;

                        if (response.data.json[i].fields && response.data.json[i].fields.length) {
                            const rLength = response.data.json[i].fields.length;
                            for (j = 0; j < rLength; j++) {
                                response.data.json[i].fields[j]._internalId = (i + 1000) + j;
                            }
                        }
                    }
                }
                setUseSchema(response.data);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }, [ id ]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Schemas');
        getSchemaData();
        return () => axiosCancelSource.current?.cancel();
    }, [ getSchemaData ]);

    // Icons
    const getIcon = (widget: string) => {
        let icon;
        switch (widget) {
            case 'string':
                icon = faFont;
                break;
            case 'text':
                icon = faTextWidth;
                break;
            case 'select':
                icon = faArrowCircleDown;
                break;
            case 'multiple':
                icon = faList;
                break;
            case 'markdown':
                // update icon
                icon = faParagraph;
                break;
            case 'number':
                icon = faSortNumericUp;
                break;
            case 'reference':
                icon = faFile;
                break;
            case 'object':
                icon = faNetworkWired;
                break;
            case 'datastore':
                icon = faDatabase;
                break;
            case 'image':
                icon = faImage;
                break;
            case 'boolean':
                icon = faDiceTwo;
                break;
            default:
                icon = faCircle;
                break;
        }
        return icon;
    }

    const updateField = (name: string, value: string) => {
        if (useSchema?.json) {
            if (current !== null && subCurrent === null)
                useSchema.json[current][name] = value;
            else if (current !== null && subCurrent !== null)
                useSchema.json[current].fields[subCurrent][name] = value;

            setUnsaved(true);
            setUseSchema({ ...useSchema });
        }
    }

    const handleDeveloperValidateSchema = (value: string) => {
        try {
            const e = JSON.parse(value);
            if (e) 
                setCustomJSONParse(true);
        } catch (e) {
            setCustomJSONParse(false);
        }

        setCustomJSON(value);
    }

    const updateDeveloperSchema = () => {
        setUseSchema((schema) => {
           if (schema && schema.json) {
                schema.json = JSON.parse(customJSON || JSON.stringify(schema.json));
                setUnsaved(true);
                setModal(false);
                return { ...schema };
           } else
                return null;
        });
    }

    const saveSchema = () => {
        const formData = { name: id, schema: JSON.stringify(useSchema), _csrf };
        axios.post(`${Config.apiUrl}/schemas/update`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setUnsaved(false);
                fetchSchemaData();
                toast.success("Your changes have been saved!");
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const createSchemaObject = (indexId: number | null = null) => {
        const currentSchema = useSchema;
        const pushData = {
            name: `${indexId !== null ? 'sub_noname' : 'noname'}_${indexId === null ? currentSchema?.json?.length : (currentSchema?.json![indexId].fields ? currentSchema?.json[indexId].fields.length : 0)}`,
            title: '',
            required: false,
            type: 'text',
            hint: '',
            maxLength: 0,
            default: '',
            fields: [],
            items: []
        }
        if (currentSchema && currentSchema.json) {
            if (indexId === null) 
                currentSchema.json.push(pushData);
            else if (indexId !== null) {
                if (!currentSchema.json[indexId].fields || !currentSchema.json[indexId].fields.length) 
                    currentSchema.json[indexId].fields = [];

                currentSchema.json[indexId].fields.push(pushData);
            }

            setUseSchema({ ...currentSchema } as SchemaData);
            setUnsaved(true);
        }
    }

    const removeSchemaObject = (indexId: number | null, subIndexId: number | null = null) => {
        if (useSchema && useSchema.json) {
            if (subIndexId === null && indexId !== null)
                useSchema.json.splice(indexId, 1);
            else if (subIndexId !== null && indexId !== null)
                useSchema.json[indexId].fields.splice(subIndexId, 1);

            setCurrent(null);
            setSubCurrent(null);

            setUseSchema({ ...useSchema });
            setUnsaved(true);
        }
    }

    const createSelectOption = () => {
        if (useSchema) {
            const oldSchema = useSchema;
            if (oldSchema && oldSchema.json) {
                if (current !== null && subCurrent !== null && oldSchema.json[current] && oldSchema.json[current].fields) {
                    if (!oldSchema.json[current].fields[subCurrent].items)
                        oldSchema.json[current].fields[subCurrent].items = [];

                    oldSchema.json[current].fields[subCurrent].items.push(``);
                } else if (current !== null && subCurrent === null) {
                    if (!oldSchema.json[current].items)
                        oldSchema.json[current].items = [];

                    oldSchema.json[current].items.push(``);
                }

                setUnsaved(true);
                setUseSchema({ ...oldSchema });
            }
        }
    }

    const deleteSelectOption = () => {
        if (useSchema) {
            const oldSchema = useSchema;
            if (oldSchema && oldSchema.json) {
                if (current !== null && subCurrent !== null && oldSchema.json[current] && oldSchema.json[current].fields) {
                    if (!oldSchema.json[current].fields[subCurrent].items)
                        oldSchema.json[current].fields[subCurrent].items = [];

                    oldSchema.json[current].fields[subCurrent].items = oldSchema.json[subCurrent].fields[subCurrent].items.slice(0, -1);
                } else if (current !== null && subCurrent === null) {
                    if (!oldSchema.json[current].items)
                        oldSchema.json[current].items = [];

                    oldSchema.json[current].items = oldSchema.json[current].items.slice(0, -1);
                }

                setUnsaved(true);
                setUseSchema({ ...oldSchema });
            }
        }
    }

    const updateSelectOption = (key: number, value: string) => {
        setUnsaved(true);
        setUseSchema((schema) => {
            if (schema && schema.json) {
                if (current !== null && subCurrent !== null && schema.json[current].fields[subCurrent])
                    schema.json[current].fields[subCurrent].items[key] = value || '';
                else if (current !== null && subCurrent === null)
                    schema.json[current].items[key] = value || '';

                return { ...schema }
            } else
                return null;
        });
    }

    let useSchemaItem: SchemaObjectData | null = null;
    if (useSchema?.json) {
        if (current !== null && subCurrent === null)
            useSchemaItem = useSchema?.json[current];
        else if (current !== null && subCurrent !== null)
            useSchemaItem = useSchema?.json[current].fields[subCurrent];
    }

    console.log(useSchemaItem);

    // Return 
    return (
        <>
            <ReactTooltip />
            <Prompt when={unsaved} message={"You have unsaved changes, are you sure you want to leave without saving?"} />
            <div className={`${modal ? 'opacity-100' : 'opacity-0 visibility-hidden pointer-events-none'} fixed z-50 h-screen flex w-full top-0 bottom-0 left-0 right-0 bg-black bg-opacity-20`}>
                <div className={"m-auto w-full sm:w-5/6 md:w-5/6 lg:w-1/2 px-10"}>
                    <div className={"rounded bg-white relative p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Developers</h2>
                        <div>
                            <div className={"flex justify-end mb-2"}>
                                <button type={"button"} onClick={() => setJSONViewer(!JSONViewer)} className={"button-tiny button-gray"}>
                                    {JSONViewer ? 'Raw JSON' : 'Formatted'}
                                </button>
                            </div>

                            <div className={"bg-gray-100 overflow-y-scroll h-128"}>
                                {JSONViewer 
                                    ? <ReactJson src={useSchema?.json || {}} />
                                    : <textarea style={{ resize: 'none' }} className={"input-basic w-full h-full block"} rows={10} spellCheck={false} autoComplete={"off"} onChange={(e) => handleDeveloperValidateSchema(e.target.value)} defaultValue={JSON.stringify(useSchema?.json)} value={customJSON || JSON.stringify(useSchema?.json, null, 4)} />
                                }
                            </div>

                            <div className={"flex justify-between mt-4"}>
                                <div className={"w-auto my-auto"}>
                                    {customJSONParse
                                        ? <span className={"text-success-100 text-base uppercase font-bold"}><FontAwesomeIcon icon={faCheck} /> VALID</span>
                                        : <span className={"text-error-100 text-base uppercase font-bold"}><FontAwesomeIcon icon={faTimes} /> INVALID</span>
                                    }
                                </div>
                                <button type={"submit"} onClick={updateDeveloperSchema} className={`${customJSONParse ? 'opacity-100' : 'opacity-50 pointer-events-none'} button-small button-cyan`}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className={"w-full sm:w-auto my-auto mr-auto"}>
                        <h1 className={"pageHeading"}>{id}</h1>
                        <Breadcrumb className="mt-4" items={[ { name: 'Schemas', uri: '/schemas' }, { name: id || '', uri: window.location.pathname } ]} />
                    </div>
                    <div className="w-full sm:w-auto my-auto">
                        <div className={`flex space-x-5`}>
                            <button data-tip="Sort Mode" type={"button"} className={"w-12 button-small button-black"} onClick={() => setDragging(!dragging)}>
                                <FontAwesomeIcon icon={faSortAlphaDown} className={`text-lg ${dragging ? 'text-cyan-100' : ''}`} />
                            </button>
                            <button data-tip="JSON" type={"button"} className={"w-12 button-small button-black"} onClick={() => setModal(true)}>
                                <FontAwesomeIcon icon={faFileCode} className={`text-lg`} />
                            </button>
                            <button type="button" onClick={() => saveSchema()} className={`button-small button-cyan ${unsaved ? 'opacity-100' : 'opacity-50 pointer-events-none'}`}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div> 

            <div className="grid grid-cols-1 lg:grid-cols-6">
                <div className="col-span-full lg:col-span-2 4xl:col-span-1 overflow-y-scroll xl:h-header border-r">
                    <div>
                        {useSchema?.json?.map((item: SchemaObjectData, index: number) => (
                            <div key={item._internalId}>
                                <div className={`relative block w-full ${(subCurrent === null && current === index) ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-200 `}>
                                    <button onClick={() => { setCurrent(index); setSubCurrent(null); }} type="button" className={`truncate w-full block text-left text-black pl-8 ${!dragging ? 'pr-8' : 'pr-14'} py-2 transition ease-in-out duration-300 relative`}>
                                        <FontAwesomeIcon icon={getIcon(item.type)} className="mr-1" /> {item.name}
                                    </button>
                                    {(!dragging && item.type === 'object') && (
                                        <button onClick={() => createSchemaObject(index)} type="button" className="absolute right-0 top-0 hover:bg-gray-300 py-2 px-4 transition ease-in-out duration-300">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>
                                    )}
                                    {dragging && (
                                        <div className="absolute flex space-x-2 top-0 right-0 h-full">
                                            <button onClick={() => handleDragging(1, index, null)} type="button" className="hover:bg-gray-300 px-2 transition ease-in-out duration-300">
                                                <FontAwesomeIcon icon={faCaretDown} />
                                            </button>
                                            <button onClick={() => handleDragging(0, index, null)} type="button" className="hover:bg-gray-300 px-2 transition ease-in-out duration-300">
                                                <FontAwesomeIcon icon={faCaretUp} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {item.fields?.map((j: SchemaObjectData, i: number) => (
                                    <div key={j._internalId} className="relative">
                                        <button key={j._internalId} onClick={() => { setCurrent(index); setSubCurrent(i); }} type="button" className={`truncate ${(current === index && subCurrent === i) ? 'bg-gray-100' : 'bg-white'} block w-full text-left text-black pl-14 ${!dragging ? 'pr-8' : 'pr-14'} py-2 hover:bg-gray-200 transition ease-in-out duration-300`}>
                                            <FontAwesomeIcon icon={getIcon(j.type)} className="mr-1" /> {j.name}
                                        </button>
                                        {dragging && (
                                            <div className="absolute flex space-x-2 top-0 right-0 h-full">
                                                <button onClick={() => handleDragging(1, index, i)} type="button" className="hover:bg-gray-300 px-2 transition ease-in-out duration-300">
                                                    <FontAwesomeIcon icon={faCaretDown} />
                                                </button>
                                                <button onClick={() => handleDragging(0, index, i)} type="button" className="hover:bg-gray-300 px-2 transition ease-in-out duration-300">
                                                    <FontAwesomeIcon icon={faCaretUp} />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div> 

                    <button onClick={() => createSchemaObject()} type="button" className="block w-full text-left bg-white text-black pl-8 pr-8 py-2 hover:bg-gray-200 transition ease-in-out duration-300">
                        <FontAwesomeIcon icon={faPlus} className="mr-1" /> New Item
                    </button>
                </div>
                <div className="col-span-full lg:col-span-4 4xl:col-span-5 xl:h-header inputSchema">
                    {(useSchemaItem !== null) && (
                        <div className="grid grid-cols-1 gap-6 p-5">
                            <div className="flex flex-wrap justify-between">
                                <div className="w-auto">
                                    <h2 className={"h2"}>{useSchemaItem.title} ({useSchemaItem.name})</h2>
                                </div>
                                <div className="w-auto">
                                    <button type="button" className="button-normal button-red" onClick={() => removeSchemaObject(current, subCurrent)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div className={"text-base font-semibold pb-1"}>Unique Key</div>
                                <input type={"text"} name="unique_key" value={useSchemaItem.name} onChange={(e) => updateField('name', e.target.value)} maxLength={24} />
                            </div>
                            <div>
                                <div className={"text-base font-semibold pb-1"}>Title</div>
                                <input type={"text"} value={useSchemaItem.title} onChange={(e) => updateField('title', e.target.value)} maxLength={256} />
                            </div>
                            <div>
                                <div className={"text-base font-semibold pb-1"}>{(useSchemaItem.type !== 'datastore' && useSchemaItem.type !== 'reference') && 'Default '}Value</div>
                                {useSchemaItem.type === "reference"
                                    ? (
                                        <select onChange={(e) => updateField('default', e.target.value)}>
                                            <option key={""} value={""}>Dropdown</option>
                                            {collectionData.map((col) => <option key={col.collectionId} value={col.collectionId} selected={col.collectionId === useSchemaItem?.default}>{col.collectionId}</option>)}
                                        </select>
                                    ) : useSchemaItem.type === "datastore" ? (
                                        <select onChange={(e) => updateField('default', e.target.value)}>
                                                <option key={""} value={""}>Dropdown</option>
                                                {dataStoreData.map((data) => <option key={data.key} value={data.key} selected={data.key === useSchemaItem?.default}>{data.key}</option>)}
                                            </select>
                                    ) : (useSchemaItem.type === "select" || useSchemaItem.type === "multiple") ? (
                                        <select className={!useSchemaItem.items ? 'opacity-50 pointer-events-none' : ''} onChange={(e) => updateField('default', e.target.value)}>
                                            <option key={"none"} value={""}>Dropdown</option>
                                            {useSchemaItem.items?.map((itemValue: string, itemKey: number) => <option key={itemKey} value={itemValue} selected={itemValue === useSchemaItem?.default}>{itemValue}</option>)}
                                        </select>
                                    ) : useSchemaItem.type === "boolean" ? (
                                        <select onChange={(e) => updateField('default', e.target.value)}>
                                            <option key={""} value={""}>Dropdown</option>
                                            <option key={"true"} value={1}>True</option>
                                            <option key={"false"} value={0}>False</option>
                                        </select>
                                    ) : <input type={"text"} className={`input-basic ${['string', 'text', 'number', 'boolean'].includes(useSchemaItem.type) ? '' : 'pointer-events-none opacity-50'}`} placeholder={['string', 'text', 'number', 'boolean'].includes(useSchemaItem.type) ? '' : 'Disabled'} value={useSchemaItem.default} onChange={(e) => updateField('default', e.target.value)} />
                                }
                            </div>
                            <div>
                                <div className={"text-base font-semibold pb-1"}>Type</div>
                                <select onChange={(e) => updateField('type', e.target.value)}>
                                    {(subCurrent !== null ? listOfSubWidgets : listOfWidgets).map((v) => <option key={v} value={v} selected={v === useSchemaItem?.type}>{v}</option>)}
                                </select>
                            </div>
                            <div>
                                <div className={"text-base font-semibold pb-1"}>Required</div>
                                <select onChange={(e) => updateField('required', e.target.value)}>
                                    {/* @ts-ignore */}
                                    <option value={0} selected={!useSchemaItem.required || useSchemaItem.required === "0"}>False</option>
                                    {/* @ts-ignore */}
                                    <option value={1} selected={useSchemaItem.required || useSchemaItem.required === "1"}>True</option>
                                </select>
                            </div>
                            <div>
                                <div className={"text-base font-semibold pb-1"}>Hint</div>
                                <input type={"text"} name="hint" value={useSchemaItem.hint || ''} onChange={(e) => updateField('hint', e.target.value)} />
                            </div>
                            {['string', 'text', 'markdown'].includes(useSchemaItem.type) && (
                                <div>
                                    <div className={"text-base font-semibold pb-1"}>Character Limit</div>
                                    <input type={"number"} name="max_length" value={useSchemaItem.maxlength || ''} onChange={(e) => updateField('maxlength', e.target.value)} />
                                </div>
                            )}
                            {['select', 'multiple'].includes(useSchemaItem.type) && (
                               <div>
                                    <div className={"flex text-base font-semibold pb-1"}>
                                        List of Items
                                    </div>
                                    <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3"}>
                                        {useSchemaItem.items?.map((itemValue: string, itemKey: number) => (
                                            <input key={`select${itemKey}`} type={"text"} placeholder={"Item"} onChange={(e) => updateSelectOption(itemKey, e.target.value)} defaultValue={itemValue || ''} />
                                        ))}
                                        <div className={"flex gap-2"}>
                                            <button type={"button"} onClick={() => deleteSelectOption()} className={"focus:outline-none w-16 button-small button-red"}>
                                                <FontAwesomeIcon icon={faMinus} className={"text-center"} />
                                            </button>
                                            <button type={"button"} onClick={() => createSelectOption()} className={"focus:outline-none w-16 button-small button-cyan"}>
                                                <FontAwesomeIcon icon={faPlus} className={"text-center"} />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Schemas;