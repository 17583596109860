import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import { DataStoreData } from "../../interfaces/DataStoreData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    dataStoreData: DataStoreData[];
    setDataStoreData: (schema: DataStoreData[]) => void;
    fetchDataStoreData: () => void;
}

export const DataStoreContext = createContext<ContextType | null>(null);

export const DataStoreProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ dataStoreData, setDataStoreData ] = useState<DataStoreData[]>([]);

    const fetchDataStoreData = useCallback(() => {
        axios.get(`${Config.apiUrl}/datastore/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setDataStoreData(response.data);
            else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        fetchDataStoreData();
        return () => axiosCancelSource.current?.cancel();
    }, [ fetchDataStoreData ]);

    return <DataStoreContext.Provider value={{ dataStoreData, setDataStoreData, fetchDataStoreData }}>
        {children}
    </DataStoreContext.Provider>;
}

export const useDataStoreContext = (): ContextType => {
    const context = useContext(DataStoreContext);

    if (context == null) {
        throw new Error('useDataStoreContext must be used within a CollectionProvider');
    }

    return context;
}