import axios, { CancelTokenSource } from 'axios';
import {FC, FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import Config from "../../Config";
import {toast} from "react-toastify";
import useCSRF from "../hooks/useCSRF";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from "react-confirm-alert";
import TitleService from "../../services/TitleService";
import {AutomationData} from "../../interfaces/AutomationData";
import {useCollectionContext} from "../contexts/Collection.context";
import CronService from "../../services/CronService";
import Breadcrumb from '../other/Breadcrumb';
import { useEditorContext } from '../contexts/Editor.context';

const Automation:FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { _csrf } = useCSRF();
    const { sessionData } = useEditorContext();
    const [ modal, setModal ] = useState<boolean>(false);
    const [ automationData, setAutomationData ] = useState<AutomationData[]>([]);

    // Collections
    const { collectionData } = useCollectionContext();

    // Form
    const [ label, setLabel ] = useState<string>('');
    const [ service, setService ] = useState<string>('');
    const [ action, setAction ] = useState<string>('');
    const [ collection, setCollection ] = useState<string>('');
    const [ continuous, setContinuous ] = useState<string>('');
    const [ cron, setCron ] = useState<string>('1d');
    const [ when, setWhen ] = useState<string>('1');

    // Data
    const servicesList = [ 'form', 'documents' ];
    const actionList = [ 'delete' ];
    const whenList = [ 1, 2, 4, 5, 7, 14, 21, 28, 31, 60 ];
    const continuousList = [ 0, 1 ];

    const getData = useCallback(() => {
        axios.get(`${Config.apiUrl}/automation/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setAutomationData(response.data);
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Automation');
        getData();
        return () => axiosCancelSource.current?.cancel();
    }, [ getData ]);

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const postFormData = { name: label, service, when, cron, action, collection, continuous, _csrf };

        axios.post(`${Config.apiUrl}/automation/create`, postFormData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                const newData: AutomationData[] = automationData;
                newData.push(response.data);
                setAutomationData([ ...newData ]);
                setModal(false);
                setLabel('');
                setAction('');
                setService('');
                setCollection('');
                setWhen('1');
                setContinuous('0');
                toast.success("Automation task successfully created!");
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleDelete = (id: string) => {
        let i;
        const postFormData = { name: id, _csrf };

        axios.post(`${Config.apiUrl}/automation/remove`, postFormData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success("Task successfully destroyed!");
                const dataLength = automationData.length;
                const newData: AutomationData[] = [];

                for (i = 0; i < dataLength; i++) {
                    if (automationData[i]._id !== response.data._id)
                        newData.push(automationData[i]);
                }

                setAutomationData([ ...newData ]);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));

    }

    const cronTimes = [
        {
            "name": "1d",
            "value": "Every day"
        },
        {
            "name": "2d",
            "value": "Every 2 days"
        },
        {
            "name": "1w",
            "value": "Every week"
        },
        {
            "name": "1m",
            "value": "Every month"
        },
    ]

    return (
        <>
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 transition ease-in-out duration-300 ${modal ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        {/* Add stuff here */}
                        <div>
                            <h2 className={"h1"}>Create Task</h2>
                            <form method={"post"} onSubmit={handleCreate}>
                                <div className={"grid grid-cols-1 gap-4 py-4"}>
                                    <div>
                                        <label className={"font-semibold text-gray-600 mb-1 block"}>Label</label>
                                        <input type={"text"} className={"input-basic"} value={label} placeholder="Name of Automation Task" onChange={(e) => setLabel(e.target.value)} />
                                    </div>

                                    <div>
                                        <label className={"font-semibold text-gray-600 mb-1 block"}>Action</label>
                                        <select className={"input-basic"} onChange={(e) => setAction(e.target.value)}>
                                            <option value={""} key={""}>Select</option>
                                            {actionList.map((item) => <option key={item} value={item} selected={item === action}>{item.slice(0, 1).toUpperCase()}{item.slice(1, item.length)}</option>)}
                                        </select>
                                    </div>

                                    <div>
                                        <label className={"font-semibold text-gray-600 mb-1 block"}>Service</label>
                                        <select className={"input-basic"} onChange={(e) => setService(e.target.value)}>
                                            <option value={""} key={""}>Select</option>
                                            {servicesList.map((item) => <option key={item} value={item} selected={item === service}>{item.slice(0, 1).toUpperCase()}{item.slice(1, item.length)}</option>)}
                                        </select>
                                    </div>

                                    {service === "documents" && (
                                        <div>
                                            <label className={"font-semibold text-gray-600 mb-1 block"}>Collection</label>
                                            <select className={"input-basic"} onChange={(e) => setCollection(e.target.value)}>
                                                <option value={""} key={""}>Select</option>
                                                {collectionData.map((item) => <option key={item.collectionId} value={item.collectionId} selected={item.collectionId === collection}>{item.collectionId}</option>)}
                                            </select>
                                        </div>
                                    )}

                                    <div>
                                        <label className={"font-semibold text-gray-600 mb-1 block"}>Older than</label>
                                        <select className={"input-basic"} onChange={(e) => setWhen(e.target.value)}>
                                            <option value={""} key={""}>Select</option>
                                            {whenList.map((item) => <option key={item} value={item} selected={String(item) === when}>{item} days</option>)}
                                        </select>
                                    </div>

                                    <div>
                                        <label className={"font-semibold text-gray-600 mb-1 block"}>Perform Every</label>
                                        <select className={"input-basic"} onChange={(e) => setCron(e.target.value)}>
                                            <option value={""} key={""}>Select</option>
                                            {cronTimes.map((item) => <option key={item.name} value={item.name} selected={item.name === cron}>{item.value}</option>)}
                                        </select>
                                    </div>


                                    <div>
                                        <label className={"font-semibold text-gray-600 mb-1 block"}>Repeat Task</label>
                                        <select className={"input-basic"} onChange={(e) => setContinuous(e.target.value)}>
                                            <option value={""} key={""}>Select</option>
                                            {continuousList.map((item) => <option key={item} value={item} selected={String(item) === action}>{item === 1 ? 'Yes' : 'No'}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <button type={"submit"} className={"ml-auto block button-small button-red text-white"}>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Automation</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Automation', uri: window.location.pathname } ]} />
                    </div>
                    {(sessionData && sessionData.level >= 3) && (
                        <div className={"w-auto my-auto text-sm text-gray-600"}>
                            <div className={"flex"}>
                                <button type={"button"} onClick={() => setModal(!modal)} className={"button-small button-cyan text-white"}>
                                    <FontAwesomeIcon icon={faPlus} className={"mr-1"} /> Add
                                </button>
                            </div>  
                        </div>
                    )}
                </div>
            </div>

            <div className="main-container-none p-6">    
                <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"}>
                    {automationData.length !== 0 ? (
                        automationData.map((item) => (
                            <div className={"rounded-lg border border-gray-300 bg-white shadow-lg"}>
                                <div className={"p-4"}>
                                    <div className={"text-base text-gray-900 uppercase font-bold"}>{item.label}</div>
                                    <div className={"text-sm text-gray-500"}>
                                        Every {CronService.format(item.cron)}
                                    </div>
                                    <div className={"text-sm pt-3"}>
                                        Will {item.action} {item.service === 'form' ? 'form submissions' : `${item.collectionId.toLowerCase()}`} older than {item.service_when} days {item.continuous ? 'continuously' : 'one time'}.
                                    </div>
                                </div>

                                <button
                                    type={"button"}
                                    onClick={() => confirmAlert({ message: "Are you sure you want to do delete this automation task?", buttons: [{ label: "Yes", onClick: () => handleDelete(item._id) }, { label: "No", onClick: () => false }] })}
                                    className={"focus:outline-none w-full text-center py-2 border-t border-gray-300 uppercase font-semibold text-red-400 hover:text-red-500 hover:bg-gray-100 transition ease-in-out duration-300"}
                                >
                                    Destroy
                                </button>
                            </div>
                        ))
                    ) : (
                        <div className={"table-none w-full col-span-full"}>
                            No automated tasks found, <button type={"button"} onClick={() => setModal(true)} className={"focus:outline-none text-gray-400 hover:text-gray-600 transition font-semibold"}>create one</button>!
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default Automation;