import {ChangeEvent, FC, useState, useEffect, useRef} from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from "react-mde";
import { ObjectProps } from './GlobalObject';
import "react-mde/lib/styles/css/react-mde-all.css";

const Markdown:FC<ObjectProps> = (props) => {

    const [ selectedTab, setSelectedTab ] = useState('write');

    const onChange = (e: string) => {
        const objectData = {
            target: {
                value: e
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    // resize textarea to content size on render
    useEffect(() => {
      if (textareaRef !== null && textareaRef.current !== null) {
        resize(textareaRef.current)
      }
    }, [])
  
    // resize helper method
    const resize = (htmlElement: HTMLTextAreaElement) => {
      // htmlElement.style.height = 'inherit'
      htmlElement.style.height = `${htmlElement.scrollHeight}px`
    }
  


    return (
        <div>
            <label className="block pb-1">{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className="text-sm text-gray-500 pb-2">{props.hint}</div>}
            <ReactMde
                onChange={(e) => onChange(e)}
                value={props.value}
                selectedTab={selectedTab === 'write' ? 'write' : 'preview'}
                onTabChange={() => setSelectedTab(selectedTab => selectedTab === 'write' ? 'preview' : 'write')}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{String(markdown || '').replace(/\n/gi, '  \n')}</ReactMarkdown>)
                }
                childProps={{
                    textArea: {
                        maxLength: props.maxlength ? Number(props.maxlength) : 4000,
                      onKeyUp: (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                        const target = event.target as HTMLTextAreaElement
            
                        resize(target)
                      }
                    }
                  }}
                  refs={{
                    textarea: textareaRef
                  }}
                  minEditorHeight={250}
            />
            <div className="text-xs text-gray-500 pt-1">{((Number(props.maxlength) || 4000) - String(props.value || '').length).toLocaleString()} character{((Number(props.maxlength) || 1024) - String(props.value || '').length) !== 1 ? 's' : ''} left</div>
        </div>
    )
}

export default Markdown;