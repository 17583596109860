const toggleStaging = false;

const config = {
    'name': 'BeneCMS',
    'apiUrl': (process.env.NODE_ENV === 'production') ? (toggleStaging ? 'https://api-staging.benecms.com' : 'https://api.benecms.com') : 'http://localhost:8080',
    'mediaUrl': (process.env.NODE_ENV === 'production') ? (toggleStaging ? 'https://files-d.benecms.com' : 'https://files.benecms.com') : 'https://files-d.benecms.com',
    'fileUrl': (process.env.NODE_ENV === 'production') ? (toggleStaging ? 'https://files-d.benecms.com' : 'https://files.benecms.com') : 'https://files-d.benecms.com',
    'publicUrl': (process.env.NODE_ENV === 'production') ? (toggleStaging ? 'https://api-staging.benecms.com/rest' : 'https://api.benecms.com/rest') : 'http://localhost:8080/rest',
    'manageUrl': (process.env.NODE_ENV === 'production') ? 'https://manage.benecms.com' : 'http://localhost:3000',
}

export default config;