import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    isPartial?: boolean;
    isDark?: boolean;
}

function Loading(props: IProps) {
    return (
        <div className={`flex ${!props.isPartial ? 'w-full bg-gradient-to-b from-gray-900 to-gray-700 h-screen' : 'w-full h-partial'}`}>
            <div className={`m-auto text-white ${props.isDark ? 'text-black' : 'text-white'}`}>
                <FontAwesomeIcon spin icon={faCircleNotch} size="2x" />
            </div>
        </div>
    )
}

export default Loading;