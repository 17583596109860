import {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {EditorData} from "../../interfaces/EditorData";
import {faPlus, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, {CancelTokenSource} from "axios";
import { useEditorContext } from '../contexts/Editor.context';
import Config from "../../Config";
import useCSRF from "../hooks/useCSRF";
import TitleService from "../../services/TitleService";
import Avatar from "../other/Avatar";
import Breadcrumb from '../other/Breadcrumb';
import InputGroup from '../other/InputGroup';

const Editors = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { sessionData } = useEditorContext();
    const { _csrf } = useCSRF();
    const [ editorData, setEditorData ] = useState<EditorData[]>([]);
    
    const [ modal, setModal ] = useState(false);
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ givePerms, setGivePerms ] = useState('0');
    const [ search, setSearch ] = useState('');

    const getEditors = useCallback(async () => {
        axios.get(`${Config.apiUrl}/editors/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setEditorData(response.data);
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Editors');
        getEditors().then(() => console.log('Data loaded'));
        return () => axiosCancelSource.current?.cancel();
    }, [ getEditors ]);

    const handleEditorAdd = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = { name: emailAddress, level: givePerms, _csrf };

        axios.post(`${Config.apiUrl}/editors/create`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setModal(false);
                setEmailAddress('');
                setEditorData((editorData) => [...editorData, response.data]);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));

    }

    const handleEditorRemove = (itemId: number) => {
        const formData = { name: editorData[itemId].editorId, _csrf };
        axios.post(`${Config.apiUrl}/editors/remove`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setEditorData((editorData) => {
                    delete editorData[itemId];
                    return [ ...Object.values(editorData) ];
                });
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    return (
        <>
            <div className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 z-50 ${modal ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-112 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Add an Editor</h2>
                        <form method={"post"} onSubmit={handleEditorAdd} className={"grid grid-cols-1 py-4 gap-8"}>
                            <div>
                                <label className={"block pb-2"}>Email Address</label>
                                <input type={"text"} className={"input-basic"} name={"emailAddress"} placeholder={"john@blackbeancreative.com"} onChange={(e) => setEmailAddress(e.target.value)} required />
                            </div>
                            <div>
                                <label className={"block pb-2"}>Permissions (Read/Write)</label>
                                <select className={"input-basic"} name={"givePermissions"} onChange={(e) => setGivePerms(e.target.value)}>
                                    <option value={0}>Content / Documents</option>
                                    <option value={1}>Collections, Tags and Users</option>
                                    <option value={2}>Collections, Tags, Users & Schemas</option>
                                    <option value={3}>Manager</option>
                                </select>
                            </div>
                            <div className={"flex"}>
                                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Editors</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Editors', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <InputGroup type="text" icon={faSearch} onChange={(e) => setSearch(e)} value={search} placeholder="Search" />
                    </div>
                    {(sessionData && sessionData.level >= 3) && (
                        <div className="w-auto my-auto">
                            <button type={"button"} onClick={() => setModal(true)} className={"button-small button-cyan"}>
                                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Editor
                            </button>
                        </div>
                    )}
                </div>
            </div>
 
            <div className="main-container-none p-6">  
                <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 5xl:grid-cols-6 gap-4"}>
                    {editorData.map((item, index) => (item.editorId.toLowerCase().includes(search.toLowerCase())) && (
                        <div key={item.editorId} className={"w-full rounded-2xl border border-gray-300 hover:border-gray-400 transition ease-in-out duration-300"}>
                            <div className={"flex h-48 bg-gray-200 rounded-t-2xl"}>
                                <div className={"m-auto"}>
                                    <Avatar size={'large'} editorId={item.editorId} />
                                </div>
                            </div>
                            <div className={"p-4 bg-white"}>
                                <div className={"text-xs text-gray-400 pt-4 uppercase"}>Email Address</div>
                                <div className={"font-semibold"}>
                                    {item.editorId}
                                </div>
                                <div className={"text-xs text-gray-400 pt-4 uppercase"}>Role</div>
                                <div className={"text-gray-600 uppercase text-base font-semibold break-words"}>
                                    {(!item.level || item.level === 0) && "Documents"}
                                    {item.level === 1 && "Documents, Collections and Users"}
                                    {item.level === 2 && "Documents, Collections, Users and Schemas"}
                                    {item.level === 3 && "Admin"}
                                    {item.level >= 4 && "Creator"}
                                </div>
                            </div>
                            {(sessionData && sessionData.level >= 3 && (sessionData && sessionData.level >= item.level)) && (
                                <div className={"bg-white rounded-b-2xl w-full flex py-2 border-t border-gray-300"}>
                                    {sessionData && sessionData.editorId !== item.editorId
                                        ? <button type={"button"} onClick={() => confirmAlert({ message: "Are you sure you want to do this?", buttons: [{ label: "Yes", onClick: () => handleEditorRemove(index) }] })} className={"focus:outline-none mx-auto text-red-400 hover:text-red-500 transition ease-in-out duration-300 uppercase text-base font-semibold text-center"}>Revoke Access</button>
                                        : <div className={"text-base mx-auto uppercase text-gray-400 font-semibold"}>This is You</div>
                                    }
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Editors;