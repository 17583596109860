import {FC, FormEvent, useEffect, useRef, useState} from 'react';
import {faKey, faProjectDiagram, faUser, faUserEdit, faUserLock} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import axios, {CancelTokenSource} from "axios";
import Config from "../../Config";
import {useEditorContext} from "../contexts/Editor.context";
import useCSRF from "../hooks/useCSRF";
import TitleService from "../../services/TitleService";
import Cookies from "universal-cookie";
import {Link, useParams} from "react-router-dom";

const Login:FC = (props) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { loginId } = useParams<{ loginId: string }>();

    const { setSessionData } = useEditorContext();
    const { _csrf } = useCSRF();
    const [ tab, setTab ] = useState(0);
    const [ firstLogin, setFirstLogin ] = useState<boolean>(false);
    const [ newAccount, setNewAccount ] = useState<boolean>(false);
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ projectId, setProjectId ] = useState<string>(loginId || '');
    const [ password, setPassword ] = useState('');
    const [ passwordConfirm, setPasswordConfirm ] = useState('');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Login');

        // Check if Project UUID is preset
        const cookies = new Cookies();
        const getCookie = cookies.get('manageProject');
        if (getCookie && !loginId)
            setProjectId(getCookie);

        return () => axiosCancelSource.current?.cancel();
    }, [ loginId ]);

    useEffect(() => {
        setPassword('');
    }, [ firstLogin ]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = { name: emailAddress, password, project: projectId, _csrf };

        axios.post(`${Config.apiUrl}/editors/login`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setSessionData(response.data.data);
                if (response.data.firstLogin) {
                    setFirstLogin(true);

                    if (response.data.newAccount)
                        setNewAccount(true);
                } else
                    return window.location.reload();
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== passwordConfirm)
            toast.error("Your passwords do not match!");
        else {
            const formData = {name: emailAddress, project: projectId, newAccount, password, _csrf};
            console.log(formData);
            axios.post(`${Config.apiUrl}/editors/update`, formData, {
                cancelToken: axiosCancelSource.current?.token,
                withCredentials: true
            }).then((response) => {
                if (!response.data.error)
                    return window.location.reload();
                else
                    toast.error(response.data.error);
            }).catch(() => toast.error('Unexpected error occurred!'));
        }
    }

    const handleForgot = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = { name: emailAddress, project: projectId };
        axios.post(`${Config.apiUrl}/editors/forgot`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success("Your recovery email has been sent!");
                setTab(0);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    return (
        <>
            <div className={"flex w-screen bg-gradient-to-r from-gray-900 to-gray-700 h-screen"}>
                <div className={"m-auto bg-white rounded-xl shadow w-112 overflow-hidden"}>
                    <div className="grid grid-cols-2 border-b">
                        <Link to="/" className="py-3 text-center text-darkblue transition ease-in-out duration-300 border-r font-semibold">
                            <FontAwesomeIcon icon={faUserEdit} className="mr-1" /> Editor
                        </Link>
                        <Link to="/admin/login" className="py-3 text-center text-darkblue bg-gray-100 transition ease-in-out duration-300 font-semibold">
                            <FontAwesomeIcon icon={faUserLock} className="mr-1" /> Admin
                        </Link>
                    </div>
                    <div className="p-8">
                        {firstLogin ? (
                            <>
                                <div className={"text-xs text-center text-gray-600 uppercase font-semibold"}>
                                    {newAccount ? 'New Account' : 'Password Recovery'}
                                </div>
                                <h1 className={"text-center"}>Enter your new password</h1>
                                <form method={"post"} onSubmit={handleUpdate}>
                                    <div className={"grid gap-4 pt-8"}>
                                        <div className={"loginBox"}>
                                            <div className={"icon"}>
                                                <FontAwesomeIcon icon={faKey} />
                                            </div>
                                            <input type={"password"} name={"password"} placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                        </div>

                                        <div className={"loginBox"}>
                                            <div className={"icon"}>
                                                <FontAwesomeIcon icon={faKey} />
                                            </div>
                                            <input type={"password"} name={"confirmPassword"} placeholder="Confirm Password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} required />
                                        </div>

                                        <div className={"flex"}>
                                            <button type={"submit"} className={"ml-auto button-small button-cyan"}>Update!</button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        ) : (
                            <>
                                <h1 className={"text-center"}>{!tab ? 'Welcome!' : 'Recovery'}</h1>
                                <form method={"post"} onSubmit={!tab ? handleSubmit : handleForgot}>
                                    <div className={"grid gap-4 pt-8"}>
                                        <div className={"loginBox"}>
                                            <div className={"icon"}>
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <input type={"email"} name={"emailAddress"} placeholder="Email Address" autoComplete={"current-email"} onChange={(e) => setEmailAddress(e.target.value)} required />
                                        </div>

                                        {!tab && (
                                            <div className={"loginBox"}>
                                                <div className={"icon"}>
                                                    <FontAwesomeIcon icon={faKey} />
                                                </div>
                                                <input type={"password"} name={"password"} placeholder="Password" autoComplete={"current-password"} onChange={(e) => setPassword(e.target.value)} required />
                                            </div>
                                        )}

                                        <div className={"loginBox"}>
                                            <div className={"icon"}>
                                                <FontAwesomeIcon icon={faProjectDiagram} />
                                            </div>
                                            <input type={"text"} name={"projectId"} placeholder="Project" value={projectId} onChange={(e) => setProjectId(e.target.value)} required />
                                        </div>

                                        <div className={"flex"}>
                                            <button type={"button"} className={"focus:outline-none w-full text-center md:w-1/2 md:mr-auto md:text-left md:my-auto font-semibold"} onClick={() => setTab(!tab ? 1 : 0)}>
                                                {!tab ? 'Forgot your password?' : 'I know my password!'}
                                            </button>
                                            <button type={"submit"} className={"ml-auto button-small button-cyan"}>{!tab ? 'Sign in' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;