import { toast } from 'react-toastify';
import { faEye, faEyeSlash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelTokenSource } from 'axios';
import { FormEvent, Fragment, useRef, useState, useMemo } from 'react';
import Config from '../../../Config';
import { ProjectData } from '../../../interfaces/ProjectData';
import { UserData } from '../../../interfaces/UserData';
import useCSRF from '../../hooks/useCSRF';

interface EditUserFormProps {
    user: UserData
    projectData: ProjectData
    onClose(): void
}

type UserExtraInfo = {
    firstName: string
    lastName: string
    company: string
    phone: string
    city: string
    address: string
    postalCode: string
}

export const EditUserForm = ({ user, projectData, onClose }: EditUserFormProps) => {

    const { userExtraInfo, restStore } = useMemo(() => {
        const {
            firstName, lastName,
            company,
            phone,
            city,
            address,
            postalCode,
            ...restStore } = (user.datastore || {})
        return {
            userExtraInfo: {
                firstName,
                lastName,
                company,
                phone,
                city,
                address,
                postalCode,
            }, restStore
        }

    }, [user.datastore])

    const isVerified = !user.activationCode
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const [emailAddress, setEmailAddress] = useState(user.emailAddress)
    const [givePerms, setGivePerms] = useState(user.role || '');
    const [dataStore, setDataStore] = useState<[string, string][]>(Object.entries(restStore || {}));
    const [userData, setUserData] = useState<UserExtraInfo>(userExtraInfo);
    const [passwordFieldIsVisible, setPasswordFieldIsVisible] = useState(false)
    const [passwordValueIsVisible, setPasswordValueIsVisible] = useState(false)
    const [password, setPassword] = useState('')

    const handleUserDataUpdate = (key: keyof UserExtraInfo, value: string) => {
        const copy = { ...userExtraInfo }
        copy[key] = value
        setUserData(copy)
    }

    const { _csrf } = useCSRF();
    const handleUserEdit = (e: FormEvent<HTMLFormElement>) => {
        let newDataStore = Object.fromEntries(dataStore.filter(([key]) => !!key))
        newDataStore = Object.assign(newDataStore, userData)

        e.preventDefault();
        const formData = { name: user.emailAddress, newName: emailAddress !== user.emailAddress ? emailAddress : '', role: givePerms || '', datastore: newDataStore, password, _csrf };
        axios.post(`${Config.apiUrl}/users/update`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success(`Edited ${emailAddress}`);
                onClose()
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));

    }
    const updateAssociativeArray = (index: number, key: string, value: string) => {
        setDataStore((dataStore) => {
            const newDataStore = [...dataStore];
            newDataStore[index] = [key, value];
            return newDataStore;
        });
    };

    const onResetPasswordClick = () => {
        const formData = { emailAddress: user.emailAddress, _csrf };
        axios.post(`${Config.apiUrl}/users/reset_password`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success(`Reset password email is sent to the ${user.emailAddress}`);
                onClose()
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }
    const onResendVerificationClick = () => {
        const formData = { emailAddress: user.emailAddress, _csrf };
        axios.post(`${Config.apiUrl}/users/activate`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success(`Activation email is sent to${user.emailAddress}`);
                onClose()
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }
    return (
        <form method={"post"} onSubmit={handleUserEdit} className={"grid grid-cols-1 py-4 gap-4 overflow-scroll max-h-modal pr-4"}>
            <div>
            <div className="pb-3">
                <label className={"block pb-2"}>Email Address</label>
                <input type={"text"} className={"input-basic"} name={"emailAddress"} placeholder={"john@blackbeanmarketing.com"} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} required />
            </div>
                <div className="pb-3">
                    <label className={"block pb-2"}>First Name</label>
                    <input type={"text"} className={"input-basic"} name={"firstName"} placeholder={"John"} value={userData.firstName} onChange={(e) => handleUserDataUpdate('firstName', e.target.value)} />
                </div>
                <div className="pb-3">
                    <label className={"block pb-2"}>Last Name</label>
                    <input type={"text"} className={"input-basic"} name={"lastName"} placeholder={"Doe"} value={userData.lastName} onChange={(e) => handleUserDataUpdate('lastName', e.target.value)} />
                </div>
                <div className="pb-3">
                    <label className={"block pb-2"}>Company</label>
                    <input type={"text"} className={"input-basic"} name={"lastName"} placeholder={"BlackBean"} value={userData.company} onChange={(e) => handleUserDataUpdate('company', e.target.value)} />
                </div>
                <div className="pb-3">
                    <label className={"block pb-2"}>Phone Number</label>
                    <input type={"tel"} className={"input-basic"} name={"phoneNumber"} placeholder={"+5555555"} value={userData.phone} onChange={(e) => handleUserDataUpdate('phone', e.target.value)} />
                </div>
                <div className="pb-3">
                    <label className={"block pb-2"}>City</label>
                    <input type={"text"} className={"input-basic"} name={"city"} placeholder={"Kelowna"} value={userData.city} onChange={(e) => handleUserDataUpdate('city', e.target.value)} />
                </div>
                <div className="pb-3">
                    <label className={"block pb-2"}>Address</label>
                    <input type={"text"} className={"input-basic"} name={"address"} placeholder={"1st Lane 1"} value={userData.address} onChange={(e) => handleUserDataUpdate('address', e.target.value)} />
                </div>
                <div>
                    <label className={"block pb-2"}>Postal Code</label>
                    <input type={"text"} className={"input-basic"} name={"postalCode"} placeholder={"0000"} value={userData.postalCode} onChange={(e) => handleUserDataUpdate('postalCode', e.target.value)} />
                </div>
            </div>
            <div>
                <button type={"button"} className={"button-small button-cyan ml-auto pb-2"} onClick={() => setPasswordFieldIsVisible(!passwordFieldIsVisible)}>
                    Set Password
                </button>
                {passwordFieldIsVisible && <div className="mt-6 flex">
                    <input type={passwordValueIsVisible ? "text" : "password"} className={"input-basic"} name={"password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                    <div className={"icon my-auto ml-2"}>
                        <FontAwesomeIcon icon={passwordValueIsVisible ? faEyeSlash : faEye} onClick={() => setPasswordValueIsVisible(!passwordValueIsVisible)} />
                    </div>
                </div>}

            </div>

            {projectData?.userRoles && (<div>
                <label className={"block pb-2"}>Role</label>
                <select className={"input-basic"} name={"givePermissions"} onChange={(e) => setGivePerms(e.target.value)} value={givePerms || 'None'}>
                    <option value="" key="">None</option>
                    {projectData.userRoles.map((item, index) => <option value={item} key={index}>{item}</option>)}
                </select>
            </div>)}
            <div>
                <label className={"block pb-2"}>Data Storage</label>
                <div className="grid grid-cols-2 gap-4">
                    {dataStore.map((item, index) => (
                        <Fragment key={index}>
                            <div>
                                <input type="text" value={item[0]} onChange={(e) => updateAssociativeArray(index, String(e.target.value), item[1])} className="w-full input-basic focus:outline-none" />
                            </div>
                            <div>
                                <input type="text" value={item[1]} onChange={(e) => updateAssociativeArray(index, item[0], String(e.target.value))} className="w-full input-basic focus:outline-none" />
                            </div>
                        </Fragment>
                    ))}
                    <button type="button" className="w-24 button-small button-gray" onClick={() => setDataStore((ds) => [...ds, ['', '']])}>
                        <FontAwesomeIcon icon={faPlus} /> Add
                    </button>
                </div>
            </div>
            <div className={"flex"}>
                {isVerified ?
                    <button type={"button"} className={"button-small button-cyan ml"} onClick={onResetPasswordClick}>
                        Reset Password
                    </button>
                    :
                    <button type={"button"} className={"button-small button-cyan ml"} onClick={onResendVerificationClick}>
                        Resend Verification
                    </button>
                }
                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                    Submit
                </button>
            </div>
        </form>
    )
}
