import {FC, useState, useEffect, useRef, FormEvent} from 'react';
import {Link} from 'react-router-dom';
import {faCaretDown, faCaretUp, faCropAlt, faPlus, faSearch, faSort, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, {CancelTokenSource} from "axios";
import {toast} from "react-toastify";
import Config from "../../../Config";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import {useEditorContext} from "../../contexts/Editor.context";
import { useSchemaContext } from '../../contexts/Schema.context';
import Breadcrumb from '../../other/Breadcrumb';
import InputGroup from '../../other/InputGroup';
import { SchemaData } from '../../../interfaces/SchemaData';
import { confirmAlert } from '../../other/TextConfirm';

const Collections: FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const { sessionData } = useEditorContext();
    const { schemaData, fetchSchemaData } = useSchemaContext();

    const [ modal, setModal ] = useState<number | null>(null);
    const [ search, setSearch ] = useState<string>('');
    const [ formInput, setFormInput ] = useState<string>('');

    // Order
    const [ filteredSchemaData, setFilteredSchemaData ] = useState<SchemaData[]>(schemaData || []);
    const [ orderType, setOrderType ] = useState<string>('DESC');
    const [ orderField, setOrderField ] = useState<string>('schemaId');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Schemas');
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    useEffect(() => {
        const oldSchemaData = schemaData;
        if (orderType === 'ASC') {
            oldSchemaData?.sort((a: SchemaData, b: SchemaData) => {
                // @ts-ignore
                if (a[orderField] < b[orderField]) return -1;
                // @ts-ignore
                if (a[orderField] > b[orderField]) return 1;
                return 0;
            });
        } else {
            oldSchemaData?.sort((a: SchemaData, b: SchemaData) => {
                // @ts-ignore
                if (a[orderField] > b[orderField]) return -1;
                // @ts-ignore
                if (a[orderField] < b[orderField]) return 1;
                return 0;
            });
        }

        setFilteredSchemaData([ ...oldSchemaData ]);
    }, [ schemaData, orderType, orderField ]);

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${Config.apiUrl}/schemas/create`, { name: formInput, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchSchemaData();
                setModal(null);
                setFormInput('');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleDelete = (id: string) => {
        axios.post(`${Config.apiUrl}/schemas/remove`, { name: id, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchSchemaData();
                setModal(null);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const updateOrder = (type: string, field: string) => {
        setOrderField(field);
        setOrderType(type);
    }

    return (
        <>
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 z-20 ${modal !== null ? 'opacity-100' : 'pointer-events-none opacity-0'} transition ease-in-out duration-300`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(null)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Wizard</h2>
                        <form method={"post"} onSubmit={handleCreate} className={"grid grid-cols-1 py-4 gap-8"}>
                            <div>
                                <label className={"block pb-2"}>Name</label>
                                <input type={"text"} className={"input-basic"} name={"name"} placeholder={"Maximum: 96 characters"} value={formInput} onChange={(e) => setFormInput(e.target.value)} required />
                            </div>
                            <div className={"flex"}>
                                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className={"w-auto my-auto mr-auto"}>
                        <h1 className={"pageHeading"}>Schemas</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Schemas', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <InputGroup type="text" icon={faSearch} placeholder="Search" value={search} onChange={(e) => setSearch(e)} />
                    </div>
                    <div className={"w-auto"}>
                        <button className="button-small button-cyan" onClick={() => setModal(0)}>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                        </button>
                    </div>
                </div>
            </div>

            <div className="main-container-none"> 
                <div className={"table-container"}>
                    <div className="table-head">
                        <div className="w-8" />
                        <button type="button" onClick={() => updateOrder(orderType === 'ASC' ? 'DESC' : 'ASC', 'schemaId')} className="text-left w-64 md:w-72 lg:w-96 xl:w-128">
                            Name
                            <FontAwesomeIcon className={`ml-2 ${orderField !== 'schemaId' ? 'pointer-events-none opacity-30' : ''}`} icon={orderField === 'schemaId' ? (orderType === 'ASC' ? faCaretUp : faCaretDown) : faSort} />
                        </button>
                        <div className="w-20 ml-auto" />
                    </div>
                    {filteredSchemaData && filteredSchemaData.map((item) => (!search || (search && item.schemaId.toLowerCase().includes(search.toLowerCase()))) && (
                        <Link key={item.schemaId} to={`/schema/${item.schemaId}`} className="table-row">
                            <div className="w-8 my-auto">
                                <FontAwesomeIcon icon={faCropAlt} />
                            </div>
                            <div className={"w-64 md:w-72 lg:w-96 xl:w-128 my-auto"}>
                                {item.schemaId}
                            </div>
                            <div className="ml-auto w-20 flex gap-x-2 justify-end" onClick={(e) => e.preventDefault()}>
                                {(sessionData && sessionData?.level >= 2) 
                                    ? <button type={"button"} className="button-tiny button-red" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this schema?", value: 'sdf', onSuccess: () => handleDelete(item.schemaId) })}><FontAwesomeIcon icon={faTrash} /></button>
                                    : ''
                                }
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Collections;