import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import { MediaData } from "../../interfaces/MediaData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    mediaLoaded: boolean;
    mediaData: MediaData[];
    setMediaData: (media: MediaData[]) => void;
    fetchMediaData: () => void;
}

export const MediaContext = createContext<ContextType | null>(null);

export const MediaProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ mediaData, setMediaData ] = useState<MediaData[]>([]);
    const [ mediaLoaded, setMediaLoaded ] = useState<boolean>(false);

    const fetchMediaData = useCallback(() => {
        axios.get(`${Config.apiUrl}/media/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setMediaData(response.data.reverse());
                setMediaLoaded(true);
            } else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        fetchMediaData();
        return () => axiosCancelSource.current?.cancel();
    }, [fetchMediaData]);

    return <MediaContext.Provider value={{ mediaLoaded, mediaData, setMediaData, fetchMediaData }}>
        {children}
    </MediaContext.Provider>;
}

export const useMediaContext = (): ContextType => {
    const context = useContext(MediaContext);

    if (context == null) {
        throw new Error('useMediaContext must be used within a MediaProvider');
    }

    return context;
}