import {FC, useState, createContext, useContext, useEffect, useRef} from 'react';
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";
import {toast} from "react-toastify";
import { AnnouncementData } from '../../interfaces/GlobalData';
import { ProjectData } from '../../interfaces/ProjectData';

interface ContextType {
    announcement: AnnouncementData | null;
    setAnnouncement: (data: AnnouncementData) => void;
    localAnnouncement: string;
    setLocalAnnouncement: (data: string) => void;
    projectData: ProjectData | null;
}

export const BaseContext = createContext<ContextType | null>(null);

export const BaseProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ projectData, setProjectData ] = useState<ProjectData | null>(null);
    const [ announcement, setAnnouncement ] = useState<AnnouncementData | null>(null);
    const [ localAnnouncement, setLocalAnnouncement ] = useState<string>(localStorage.getItem('localAnnouncement') || '');

    useEffect(() => {
        localStorage.setItem('localAnnouncement', localAnnouncement);
    }, [ localAnnouncement, setLocalAnnouncement ]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        axios.get(`${Config.apiUrl}/globals/announcement`,{ cancelToken: axiosCancelSource.current?.token, withCredentials: true }).then((response) => {
            if (response.data.message) 
                setAnnouncement(response.data);
        }).catch(() => toast.error('Unexpected error occurred!'));

        axios.get(`${Config.apiUrl}/editors/project`,{ cancelToken: axiosCancelSource.current?.token, withCredentials: true }).then((response) => {
            if (response.data) 
                setProjectData(response.data);
        }).catch(() => toast.error('Unexpected error occurred!'));

        return () => axiosCancelSource.current?.cancel();
    }, []);

    return <BaseContext.Provider value={{ announcement, setAnnouncement, localAnnouncement, setLocalAnnouncement, projectData }}>
        {children}
    </BaseContext.Provider>;
}

export const useBaseContext = (): ContextType => {
    const context = useContext(BaseContext);

    if (context == null) 
        throw new Error('useBaseContext must be used within a BaseProvider');

    return context;
}