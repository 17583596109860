import axios, { CancelTokenSource } from 'axios';
import {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react';
import Config from "../../Config";
import {toast} from "react-toastify";
import { FileData } from "../../interfaces/FileData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt, faFile, faFilePdf, faPlus, faSearch, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import useCSRF from "../hooks/useCSRF";
import moment from "moment";
import TitleService from "../../services/TitleService";
import {confirmAlert} from "react-confirm-alert";
import Pagination from '../other/Pagination';
import Breadcrumb from '../other/Breadcrumb';
import InputGroup from '../other/InputGroup';
import { useFileContext } from '../contexts/File.context';
import {formatFileUrl} from '../../utils/formatS3ItemUrl';

const File = () => {

    const { fileData, fetchFileData } = useFileContext();

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const [ actualSearch, setActualSearch ] = useState<string>('');
    const [ search, setSearch ] = useState<string>('');
    const [ skip, setSkip ] = useState<number>(0);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('File');
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    const handleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setActualSearch(search);
    }

    const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

        const useFile = e.target.files;
        const useFormData = new FormData();

        if (!useFile)
            return toast.error("You do not have a file to be uploaded!");

        if (useFile?.length > 1)
            return toast.error("You can only upload one file at a time!");

        // Start upload code here
        useFormData.append("file", useFile[0], useFile[0].name);
        useFormData.append("_csrf", _csrf);

        axios.post(`${Config.apiUrl}/file/create`, useFormData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!response.data.error) 
                fetchFileData();
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'))
        .finally(() => {
          e.target.value = '';
        });
    }

    const deleteFile = (indexId: number) => {
        const useFile = newFileData[indexId];

        if (!useFile)
            return toast.error('Invalid file selected!');

        const useFormData = { name: useFile.fileId, _csrf }

        axios.post(`${Config.apiUrl}/file/remove`, useFormData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
        }).then((response) => {
            if (!response.data.error) 
                fetchFileData();
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const newFileData: FileData[] = [];
    fileData.map((item) => (!actualSearch || (actualSearch && item.fileId.toLowerCase().includes(actualSearch.toLowerCase())) ? newFileData.push(item) : null));

    return (
        <>
            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>File</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'File', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <Pagination 
                            skip={skip} 
                            total={newFileData.length} 
                            perPage={20} 
                            next={() => setSkip(skip + 28) } 
                            prev={() => setSkip(skip - 28) } 
                        />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <form onSubmit={handleSearch}>
                            <InputGroup icon={faSearch} type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e)} />
                        </form>
                    </div>
                    <div>
                        <input accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id={"fileUpload"} name={"fileUpload"} style={{ display: 'none' }} type={"file"} onChange={onFileUpload} />
                        <label style={{ color: '#fff', padding: '10px 16px' }} className="button-small button-cyan cursor-pointer" htmlFor={"fileUpload"}>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Upload
                        </label>
                    </div>
                </div>
            </div>

            <div className="main-container-none min-h-partial">    
                <div className="main-container-none"> 
                    <div className={"table-container"}>
                        <div className="table-head">
                            <div className="w-10">#</div>
                            <div className="w-full sm:w-64 lg:w-96 mr-auto">File</div> 
                            <div className="hidden md:w-48 lg:block">Uploaded</div>
                            <div className="w-40" />
                        </div>
                        {newFileData.length !== 0
                            ? newFileData.slice(skip, skip + 20).map((item, index) => (
                                <div key={index} className="table-row">
                                    <div className="w-10">{index + skip + 1}</div>
                                    <div className="w-full sm:w-64 lg:w-96 truncate mr-auto">
                                        <FontAwesomeIcon icon={item.source.endsWith('.pdf') ? faFilePdf : faFile} className="mr-2" />
                                        {item.source}
                                    </div> 
                                    <div className="hidden md:w-48 lg:block">
                                        {moment(item.created).fromNow()}
                                    </div>
                                    <div className="w-40 my-auto">
                                        <div className="flex space-x-2">
                                            <a href={formatFileUrl(item.projectUUID, item.source)} className="button-tiny button-cyan transition ease-in-out duration-300">
                                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                                            </a>
                                            <button type="button" className="button-tiny button-red transition ease-in-out duration-300" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this file?", buttons: [{ label: "Yes", onClick: () => deleteFile(index) }, { label: "No", onClick: () => false }] })}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                              )) : <div className={"table-none"}>There are no files at the moment, check back later!</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default File;
