const format = (v: string) => {
    let value;
    switch (v) {
        case '1d':
            value = '1 day';
            break;
        case '2d':
            value = '2 days';
            break;
        case '1w':
            value = 'week';
            break;
        case '1m':
            value = 'month';
            break;
        default:
            value = '1 day';
            break;
    }

    return value;
}
// eslint-disable-next-line
export default { format };