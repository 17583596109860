import { toast } from 'react-toastify';
import axios, { CancelTokenSource } from 'axios';
import { FormEvent, useRef, useState } from 'react';
import Config from '../../../Config';
import { ProjectData } from '../../../interfaces/ProjectData';
import useCSRF from '../../hooks/useCSRF';

interface CreateUserFormProps {
    projectData: ProjectData
    onClose(): void
}
export const CreateUserForm = ({ projectData, onClose }: CreateUserFormProps) => {

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const [emailAddress, setEmailAddress] = useState('')
    const [givePerms, setGivePerms] = useState('');

    const { _csrf } = useCSRF();
    const handleUserAdd = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = { name: emailAddress, role: givePerms || '', _csrf };

        axios.post(`${Config.apiUrl}/users/create`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success(`Added ${emailAddress}`);
                onClose();
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    return (
        <form method={"post"} onSubmit={handleUserAdd} className={"grid grid-cols-1 py-4 gap-8"}>
            <div>
                <label className={"block pb-2"}>Email Address</label>
                <input type={"text"} className={"input-basic"} name={"emailAddress"} placeholder={"john@blackbeancreative.com"} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} required />
            </div>

            {projectData?.userRoles && (
                <div>
                    <label className={"block pb-2"}>Role</label>
                    <select className={"input-basic"} name={"givePermissions"} onChange={(e) => setGivePerms(e.target.value)} value={givePerms || 'None'}>
                        <option value="" key="">None</option>
                        {projectData?.userRoles.map((item, index) => <option value={item} key={index}>{item}</option>)}
                    </select>
                </div>
            )}
            <div className={"flex"}>
                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                    Submit
                </button>
            </div>
        </form>
    )
}

