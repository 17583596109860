import {ChangeEvent, Fragment} from 'react';
import { FC } from 'react';
import { SchemaObjectData } from "../../interfaces/SchemaObjectData";
import Markdown from "./Markdown";
import Input from "./Input";
import Select from "./Select";
import Image from "./Image";
import {DocumentData} from "../../interfaces/DocumentData";
import Reference from "./Reference";
import Boolean from "./Boolean";
import List from "./List";
import Multiple from "./Multiple";
import Textarea from "./Textarea";
import DataStore from './DataStore';
import File from './File';

export interface ObjectProps extends SchemaObjectData {
    onChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => void;
    onFileSet: (isFileSaved: boolean) => void;
}

export interface GlobalObjectProps {
    useDocument: DocumentData;
    fields: ObjectProps[];

    // Callbacks
    updateField: (name: string, value: string, useInteger?:boolean, useArray?:boolean) => void;
    updateSubField: (field: string, name: string, value: string, useInteger?:boolean, useArray?:boolean) => void;

    // Other
    isMetadata?: boolean;
}

const GlobalObject:FC<GlobalObjectProps> = (props) => {

    const { useDocument, updateField, updateSubField, fields, isMetadata } = props;

    return (
        <>
            {fields.map((field) => (
                <div key={field.name}>
                    {field.type === 'string' && <Input {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'list' && <List {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name] : []} onChange={(e) => updateField(field.name, e.target.value, false, true)} />}
                    {field.type === 'text' && <Textarea {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'number' && <Input {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value, true)} />}
                    {field.type === 'select' && <Select {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'multiple' && <Multiple {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value, false, true)} />}
                    {field.type === 'image' && <Image {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'file' && <File {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'markdown' && <Markdown {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'reference' && <Reference {...field} useDocumentCollection={useDocument.collectionId} useDocumentName={useDocument.documentId} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'datastore' && <DataStore {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'boolean' && <Boolean {...field} value={useDocument.json && typeof useDocument.json[field.name] !== 'undefined' ? useDocument.json[field.name]  : field.default} onChange={(e) => updateField(field.name, e.target.value)} />}
                    {field.type === 'object' && (
                        isMetadata ? (
                            <div className="grid grid-cols-1 gap-8">
                                {(field.fields as ObjectProps[])?.map((subfield) => (
                                    <Fragment key={subfield.name}>
                                        {subfield.type === 'string' && <Input {...subfield} isSEO={(isMetadata && (subfield.name === 'title' || subfield.name === 'description' || subfield.name === 'keywords'))} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                        {subfield.type === 'text' && <Textarea {...subfield} isSEO={(isMetadata && (subfield.name === 'title' || subfield.name === 'description' || subfield.name === 'keywords'))} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                        {subfield.type === 'image' && <Image {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                    </Fragment>
                                ))}
                            </div>
                        ) : (
                            <div className="panel bg-white">
                                <div className="panel-head">{field.title} {field.title.toLowerCase() !== field.name.toLowerCase() && <span>({field.name})</span>}</div>
                                <div className="panel-body grid grid-cols-1 gap-6">
                                    {field.hint && <div className={"text-base text-gray-500"}>{field.hint}</div>} 
                                    {(field.fields as ObjectProps[])?.map((subfield) => (
                                        <Fragment key={subfield.name}>
                                            {subfield.type === 'list' && <List {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : []} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value, false, true)} />}
                                            {subfield.type === 'string' && <Input {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'text' && <Textarea {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'number' && <Input {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value, true)} />}
                                            {subfield.type === 'select' && <Select {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'multiple' && <Multiple {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value, false, true)} />}
                                            {subfield.type === 'reference' && <Reference {...subfield} useDocumentCollection={useDocument.collectionId} useDocumentName={useDocument.documentId} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'datastore' && <DataStore {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'image' && <Image {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'file' && <File {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                            {subfield.type === 'markdown' && <Markdown {...subfield} value={(useDocument.json && useDocument.json[field.name] && typeof useDocument.json[field.name][subfield.name] !== 'undefined') ? useDocument.json[field.name][subfield.name] : subfield.default} onChange={(e) => updateSubField(field.name, subfield.name, e.target.value)} />}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            ))}
        </>
    )


}

export default GlobalObject;