
import Config from "../Config";

const set = (name: string, overrideName: boolean = false) => {
    if (!overrideName)
        document.title = `${name} | ${Config.name}`;
    else
        document.title = name;
    return;
}

const reset = () => {
    document.title = Config.name;
    return;
}
// eslint-disable-next-line
export default { set, reset };