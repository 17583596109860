import { faTimes, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

interface IProps {
    fieldName: string;
}

const PrintWidth = (props: IProps) => {

    const { fieldName } = props;

    const [popup, setPopup] = useState<boolean>(false);

    const [popupdesc, setPopupdesc] = useState<boolean>(false);

    const [popupkeywords, setPopupkeywords] = useState<boolean>(false);

    return (
        <div className="relative">
            {/* SEO Title Popup */}
            {popup && (
                <div className="modalPopup right-0 top-6 w-80 z-20">
                    <button type={"button"} className={"absolute top-5 right-5"} onClick={() => setPopup(false)}>
                        <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                    </button>
                    <div className="h5">SEO Meta Title</div>
                    <p className="py-4 text-sm">
                        Titles are critical to give users a quick insight into the content of a result and why it’s relevant to their query. It's often the primary piece of information used to decide which result to click on, so it's important to use high-quality titles on your web pages.
                    </p>
                    <button type="button" onClick={() => setPopup(false)} className="button-cyan-text font-bold text-sm">Thanks!</button>
                </div>
            )}
            {/* SEO Description Popup */}
            {popupdesc && (
                <div className="modalPopup right-0 top-6 w-80 z-20">
                    <button type={"button"} className={"absolute top-5 right-5"} onClick={() => setPopupdesc(false)}>
                        <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                    </button>
                    <div className="h5">SEO Meta Description</div>
                    <p className="py-4 text-sm">
                        A meta description tag should generally inform and interest users with a short, relevant summary of what a particular page is about. They are like a pitch that convince the user that the page is exactly what they're looking for. There's no limit  on how long a meta description can be, but the search result snippets are truncated as needed, typically to fit the device width.
                    </p>
                    <button type="button" onClick={() => setPopupdesc(false)} className="button-cyan-text font-bold text-sm">Thanks!</button>
                </div>
            )}
            {/* SEO Keywords Popup */}
            {popupkeywords && (
                <div className="modalPopup right-0 top-6 w-80 z-20">
                    <button type={"button"} className={"absolute top-5 right-5"} onClick={() => setPopupkeywords(false)}>
                        <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                    </button>
                    <div className="h5">Keywords</div>
                    <p className="py-4 text-sm">
                        Separate target keywords with commas. Do not use spaces after the commas, unless you want to include them.
                    </p>
                    <button type="button" onClick={() => setPopupdesc(false)} className="button-cyan-text font-bold text-sm">Thanks!</button>
                </div>
            )}
            {/* Title Printwidth */}

            {fieldName === 'title' && (
                    <div className='flex'>
                        <button type="button" onClick={() => setPopup(true)}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="mb-1 text-xs text-cyan-100 hover:text-gray-900 duration-300 ml-2" data-tip="Learn more" />
                        </button>
                    </div>
            )}
            {/* progress bar for useTextWidth with a max of 1300 */}
            {fieldName === 'description' && (
                    <div className='flex'>
                        <button type="button" onClick={() => setPopupdesc(true)}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="mb-1 text-xs text-cyan-100 hover:text-gray-900 duration-300 ml-2" data-tip="Learn more" />
                        </button>
                    </div>

            )}
    {/* keywords popup */}
            {fieldName === 'keywords' && (               
                    <div className='flex'>
                        <button type="button" onClick={() => setPopupkeywords(true)}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="mb-1 text-xs text-cyan-100 hover:text-gray-900 duration-300 ml-2" data-tip="Learn more" />
                        </button>
                    </div>
            )}
        </div>
    )
}

export default PrintWidth;