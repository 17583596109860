import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";
import {TagData} from "../../interfaces/TagData";

interface ContextType {
    tagLoaded: boolean;
    tagData: TagData[];
    setTagData: (External: TagData[]) => void;
    fetchTagData: () => void;
}

export const TagContext = createContext<ContextType | null>(null);

export const TagProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ tagData, setTagData ] = useState<TagData[]>([]);
    const [ tagLoaded, setTagLoaded ] = useState<boolean>(false);

    const fetchTagData = useCallback(() => {
        axios.get(`${Config.apiUrl}/tags/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setTagData(response.data);
                setTagLoaded(true);
            } else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        fetchTagData();
        return () => axiosCancelSource.current?.cancel();
    }, [fetchTagData]);

    return <TagContext.Provider value={{ tagLoaded, tagData, setTagData, fetchTagData }}>
        {children}
    </TagContext.Provider>;
}

export const useTagContext = (): ContextType => {
    const context = useContext(TagContext);

    if (context == null) {
        throw new Error('useTagContext must be used within a TagProvider');
    }

    return context;
}