import {FC, FormEvent, useEffect, useRef, useState} from "react";
import axios, {CancelTokenSource} from "axios";
import Config from "../../../Config";
import {toast} from "react-toastify";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import { useAdminContext } from "../../contexts/Admin.context";
import Breadcrumb from "../../other/Breadcrumb";
import { Redirect } from "react-router-dom";

const Settings:FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { adminLoaded, adminData, setAdminData } = useAdminContext();
    const { _csrf } = useCSRF();

    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ firstName, setFirstName ] = useState<string>('');
    const [ lastName, setLastName ] = useState<string>('');
    const [ companyName, setCompanyName ] = useState<string>('');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Settings')
        return () => axiosCancelSource.current?.cancel();
    }, []);

    useEffect(() => {
        if (adminData) {
            setEmailAddress(adminData.emailAddress);
            setFirstName(adminData.firstName);
            setLastName(adminData.lastName);
            setCompanyName(adminData.companyName);
        }
    }, [ adminData ]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = { name: emailAddress, firstName, lastName, companyName, _csrf };
        console.log(formData);
        axios.post(`${Config.apiUrl}/auth/updateInformation`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setAdminData(response.data);
                toast.success("Your account details have been updated!");
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    return (adminLoaded && adminData !== null) ? (
        <form method={"post"} onSubmit={handleSubmit}>
            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Account Settings</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Settings', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <button type={"submit"} className={"button-small button-cyan"}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className="main-container-none tw-container-small p-6"> 
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4"}>
                    <div>
                        <label htmlFor={"firstName"}>First Name</label>
                        <input type={"text"} name={"firstName"} value={firstName} onChange={(e) => setFirstName(e.target.value)} className={"input-basic"} required />
                    </div>

                    <div>
                        <label htmlFor={"lastName"}>Last Name</label>
                        <input type={"text"} name={"lastName"} value={lastName} onChange={(e) => setLastName(e.target.value)} className={"input-basic"} required />
                    </div>

                    <div>
                        <label htmlFor={"companyName"}>Company Name</label>
                        <input type={"text"} name={"companyName"} value={companyName} onChange={(e) => setCompanyName(e.target.value)} className={"input-basic"} required />
                    </div>
                </div>
            </div>
        </form>
    ) : adminLoaded ? <Redirect to="/" /> : <></>;
}

export default Settings;