import {FC, useState, createContext, useContext, useEffect, useRef} from 'react';
import { EditorData } from "../../interfaces/EditorData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";
import {toast} from "react-toastify";

interface ContextType {
    sessionLoaded: boolean;
    setSessionLoaded: (loaded: boolean) => void;
    sessionData: EditorData | null;
    setSessionData: (user: EditorData | null) => void;
    sessionLogout: () => void;
}

export const EditorContext = createContext<ContextType | null>(null);

export const EditorProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ sessionData, setSessionData ] = useState<EditorData | null>(null);
    const [ sessionLoaded, setSessionLoaded ] = useState<boolean>(false);

    const sessionLogout = () => {
        axios.get(`${Config.apiUrl}/auth/logout?onlyEditor=true`,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setSessionData(null);

            setSessionLoaded(true);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        axios.get(`${Config.apiUrl}/editors/session`,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setSessionData(response.data.data);

            setSessionLoaded(true);
        }).catch(() => toast.error('Unexpected error occurred!'));
        return () => axiosCancelSource.current?.cancel();
    }, []);

    return <EditorContext.Provider value={{ sessionData, setSessionData, sessionLoaded, setSessionLoaded, sessionLogout }}>
        {children}
    </EditorContext.Provider>;
}

export const useEditorContext = (): ContextType => {
    const context = useContext(EditorContext);

    if (context == null) {
        throw new Error('useEditorContext must be used within a EditorProvider');
    }

    return context;
}