import axios, { CancelTokenSource } from 'axios';
import {useCallback, useEffect, useRef, useState} from 'react';
import {FormData} from "../../interfaces/FormData";
import Config from "../../Config";
import {toast} from "react-toastify";
import useCSRF from "../hooks/useCSRF";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from "react-confirm-alert";
import TitleService from "../../services/TitleService";
import Breadcrumb from '../other/Breadcrumb';

const Forms = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { _csrf } = useCSRF();
    const [ modal, setModal ] = useState<number | null>(null);
    const [ filter, setFilter ] = useState<string>('');
    const [ formData, setFormData ] = useState<FormData[]>([]);
    const [ formNames, setFormNames ] = useState<string[]>([]);

    const getList = useCallback(() => {
        axios.get(`${Config.apiUrl}/forms/fields`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setFormNames(response.data);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));

    }, []);


    const getData = useCallback(() => {
        axios.get(`${Config.apiUrl}/forms/list${filter ? `/${filter}` : ''}`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setFormData(response.data);
                TitleService.set(`${filter} Forms`);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }, [filter]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Forms');
        getData();
        getList();
        return () => axiosCancelSource.current?.cancel();
    }, [ getData, getList ]);

    const handleDelete = (id: number) => {
        let i;
        const postFormData = { name: formData[id] ? formData[id]._id : null, _csrf };

        axios.post(`${Config.apiUrl}/forms/remove`, postFormData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                const formDataLength = formData.length;
                const newData: FormData[] = [];

                for (i = 0; i < formDataLength; i++) {
                    if (formData[i]._id !== response.data._id)
                        newData.push(formData[i]);
                }

                setFormData([ ...newData ]);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));

        setModal(null);
    }

    return (
        <>
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 transition ease-in-out duration-300 ${(modal !== null && formData[modal]) ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(null)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        {(modal !== null && formData[modal]) && (
                            <>
                                <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Viewing Submission</h2>
                                <div className={"py-4"}>
                                    <div className={"py-4 pl-4 border-l-2 border-gray-300"}>
                                        {Object.entries(formData[modal].formData).map(([key, value]) => <div><span className={"font-semibold"}>{key}:</span> {value}</div>)}
                                    </div>
                                    <div className={"flex mt-4"}>
                                        <div className={"w-auto my-auto"}>
                                            <div className={"smallHeading pb-2"}>Submitted {moment(formData[modal].created).fromNow()}</div>
                                        </div>
                                        <button type={"submit"} className={"button-small button-red ml-auto"}  onClick={() => modal !== null && confirmAlert({ message: "Are you sure you want to do delete this form submission?", buttons: [{ label: "Yes", onClick: () => handleDelete(modal) }, { label: "No", onClick: () => false }] })}>
                                            <FontAwesomeIcon icon={faTrash} className={"mr-1"} /> Delete
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Form Submissions</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Form Submissions', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <div className={"flex"}>
                            <div className={"my-auto pr-2 text-base text-gray-900 font-semibold uppercase"}>Viewing:</div>
                            <div>
                                <select className={"input-basic"} style={{ width: '10rem' }} onChange={(e) => setFilter(e.target.value)}>
                                    <option key={999} value={''} selected={!filter || filter === ''}>All</option>
                                    {formNames.map((item, index) => <option key={item} value={item} selected={item === filter}>{item}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-container-none"> 
                <div className={"table-container"}>
                    <div className="table-head">
                        <div className="w-10">#</div>
                        <div className={"w-56"}>Name</div>
                        <div className="w-20">Form</div>
                        <div className="w-28 ml-auto">Date</div>
                    </div>
                    {formData.length !== 0
                        ? formData.map((item, index) => (
                            <button type={"button"} onClick={() => setModal(index)} className={"table-row"}>
                                <div className={"w-10 font-semibold"}>
                                    {(index + 1).toLocaleString()}.
                                </div>
                                <div className={"w-56 truncate"}>
                                    {item.formData['name'] || item.formData['fullname'] || item.formData['username'] || item.formData['emailAddress'] || item.formData['email'] || item.formData['email-address'] || item.formData['emailaddress'] || 'Unknown'}
                                </div>
                                <div className={"w-20"}>
                                    {item.formName}
                                </div>
                                <div className={"w-28 ml-auto"}>
                                    {moment(item.created).fromNow()}
                                </div>
                            </button>
                        ))
                        : <div className={"table-none"}>There are no form submissions at the moment, check back later!</div>
                    }
                </div>
            </div>
        </>
    )
}

export default Forms;