import Container from "./components/Container";
import { AdminProvider } from "./components/contexts/Admin.context";
import {EditorProvider} from "./components/contexts/Editor.context";

function App() {
  // mt-12
  return (
    <div className="App">
        <AdminProvider>
            <EditorProvider>
                <Container />
            </EditorProvider>
        </AdminProvider>
        
    </div>
  );
}

export default App;
