import {ChangeEvent, FC, useState} from 'react';
import { ObjectProps } from './GlobalObject';
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const List:FC<ObjectProps> = (props) => {

    const [ list, setList ] = useState<string[]>(( typeof props.value === 'object' && Array.isArray(props.value) ) ? props.value : []);

    const addItem = () => {
        setList((list) => [ ...list, '' ]);
    }

    const removeItem = () => {
        setList((list) => {
            list.pop();
            return [ ...list ];
        });
    }

    const updateItem = (index: number, value: string) => {
        const useList = list;
        useList[index] = value;
        setList([ ...useList ]);

        const objectData = {
            target: {
                value: JSON.stringify(list)
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    console.log(typeof props.value, props.value, list);

    return (
        <div>
            <label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className={"text-sm text-gray-500 mb-2"}>{props.hint}</div>}
            <div className={"flex flex-wrap gap-4"}>
                {list.map((item, key) => (item || (!item && key === list.length - 1)) && (
                    <input
                        style={{ width: "31.9%" }}
                        key={key}
                        type="text"
                        name={"input"}
                        value={item}
                        placeholder={'Text field'}
                        maxLength={768}
                        onChange={(e) => updateItem(key, e.target.value)}
                    />
                ))}
                <div className={"w-32 flex gap-2"}>
                    <button type={"button"} className={"button-small button-red"} onClick={removeItem}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <button type={"button"} className={`button-small button-cyan ${(!list[list.length - 1] && list.length !== 0) ? 'pointer-events-none opacity-50' : ''}`} onClick={addItem}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default List;