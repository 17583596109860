import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import { FileData } from "../../interfaces/FileData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    fileLoaded: boolean;
    fileData: FileData[];
    setFileData: (file: FileData[]) => void;
    fetchFileData: () => void;
}

export const FileContext = createContext<ContextType | null>(null);

export const FileProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ fileData, setFileData ] = useState<FileData[]>([]);
    const [ fileLoaded, setFileLoaded ] = useState<boolean>(false);

    const fetchFileData = useCallback(() => {
        axios.get(`${Config.apiUrl}/file/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setFileData(response.data.reverse());
                setFileLoaded(true);
            } else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        fetchFileData();
        return () => axiosCancelSource.current?.cancel();
    }, [fetchFileData]);

    return <FileContext.Provider value={{ fileLoaded, fileData, setFileData, fetchFileData }}>
        {children}
    </FileContext.Provider>;
}

export const useFileContext = (): ContextType => {
    const context = useContext(FileContext);

    if (context == null) {
        throw new Error('useFileContext must be used within a FileProvider');
    }

    return context;
}