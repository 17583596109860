import { faCheck, faCode, faCog, faCropAlt, faEyeSlash, faHome, faLayerGroup, faSave, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Playground = () => {
    return (
        <div className="font-open-sans container-padding max-w-screen-xl mx-auto">
            <h1>Playground</h1>
            <div className="grid grid-cols-1 gap-8 mt-8">
                <div>
                    <h2 className="pageHeading">Buttons (solid)</h2>
                    <p>These are using separate hover effects than the ones listed.</p>
                    <div className="flex gap-3 mt-4"> 
                        <a href="/#" className="px-8 py-2 rounded-md text-white font-semibold focus:outline-none bg-cyan-100 hover:bg-cyan-60 transition">Primary</a>
                        <a href="/#" className="px-8 py-2 rounded-md text-white font-semibold focus:outline-none bg-black hover:opacity-70 transition">Secondary</a>
                        <a href="/#" className="px-8 py-2 rounded-md text-black font-semibold focus:outline-none bg-yellow-100 hover:bg-yellow-60 transition">Alternative</a>
                        <a href="/#" className="px-8 py-2 rounded-md text-white font-semibold focus:outline-none bg-error-100 hover:bg-error-60 transition">Danger</a>
                        <a href="/#" className="px-8 py-2 rounded-md text-grayblue-60 font-semibold focus:outline-none bg-grayblue-20 transition">Primary</a>
                    </div>
                </div> 
                <div>
                    <h2 className="pageHeading">Buttons (hover)</h2>
                    <p>These are using default hover effects that are listed.</p>
                    <div className="flex gap-3 mt-4"> 
                        <a href="/#" className="px-8 py-2 rounded-md border-2 text-white font-semibold focus:outline-none border-cyan-100 bg-cyan-100 hover:bg-transparent hover:text-black transition">Primary</a>
                        <a href="/#" className="px-8 py-2 rounded-md border-2 text-white font-semibold focus:outline-none border-black bg-black hover:bg-transparent hover:text-black transition">Secondary</a>
                        <a href="/#" className="px-8 py-2 rounded-md border-2 text-black font-semibold focus:outline-none border-yellow-100 bg-yellow-100 hover:bg-transparent hover:text-black transition">Alternative</a>
                        <a href="/#" className="px-8 py-2 rounded-md border-2 text-white font-semibold focus:outline-none border-success-100 bg-success-100 hover:bg-transparent hover:text-black transition">Alternative</a>
                    </div>
                </div>
                <div>
                    <h2 className="pageHeading">Buttons (icon)</h2>
                    <p>These are using default hover effects that are listed.</p>
                    <div className="flex gap-3 mt-4"> 
                        <a href="/#" className="px-4 py-2 rounded-md border-2 text-white font-semibold focus:outline-none border-cyan-100 bg-cyan-100 hover:bg-transparent hover:text-black transition">
                            <FontAwesomeIcon icon={faEyeSlash} />
                        </a>
                        <a href="/#" className="px-4 py-2 rounded-md border-2 text-white font-semibold focus:outline-none border-black bg-black hover:bg-transparent hover:text-black transition">
                            <FontAwesomeIcon icon={faCode} />
                        </a>
                        <a href="/#" className="px-4 py-2 rounded-md border-2 text-black font-semibold focus:outline-none border-yellow-100 bg-yellow-100 hover:bg-transparent hover:text-black transition">
                            <FontAwesomeIcon icon={faCropAlt} />
                        </a>
                        <a href="/#" className="px-4 py-2 rounded-md border-2 text-white font-semibold focus:outline-none border-success-100 bg-success-100 hover:bg-transparent hover:text-black transition">
                            <FontAwesomeIcon icon={faCheck} />
                        </a>
                        <a href="/#" className="px-4 py-2 rounded-md text-grayblue-60 font-semibold focus:outline-none bg-grayblue-20 transition">
                            <FontAwesomeIcon icon={faSave} />
                        </a>
                    </div>
                </div>
                <div>
                    <h2 className="pageHeading">Search</h2>
                    <div className="flex gap-3 mt-4"> 
                        <input type="text" className="px-4 py-2 bg-grayblue-20 border border-grayblue-20 placeholder-grayblue-60 text-grayblue-100 focus:outline-none focus:border-grayblue-40 rounded" placeholder="Text field" />
                    </div>
                </div>
                <div>
                    <h2 className="pageHeading">Element Container Styling</h2>
                    <p>The tabs will be uppercase and semibolded font.</p>
                    <div className="rounded-lg mt-4">
                        <div className="flex bg-grayblue-20 rounded-t-lg">
                            <div className="flex">
                                <a href="/#" className="px-8 py-2 uppercase font-semibold text-white bg-black rounded-tl-lg">
                                    Document
                                </a>
                                <a href="/#" className="px-8 py-2 uppercase font-semibold text-black hover:bg-black hover:text-white transition">
                                    SEO - Metadata
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-b-lg border-2 border-t-0 border-grayblue-20"> 
                        <div className="grid grid-cols-1 gap-6">
                            <div>
                                <label className="block w-full font-bold text-sm uppercase text-black pb-1">Title</label>
                                <input type="text" className="block w-full focus:outline-none focus:border-grayblue-60 p-2 rounded-lg border border-grayblue-20" />
                                <div className="text-xs uppercase text-grayblue-60 pt-1">700 characters remaining</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="pageHeading">Breadcrumb</h2>
                    <div className="mt-2 text-cyan-100 font-semibold text-xl">Name / Name 2 / Name 4 / Name 6</div>
                </div>
                <div>
                    <h2 className="pageHeading block mb-4">Dropdowns</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
                        <div className="rounded-lg bg-darkblue-20">
                            <div className="rounded-t-lg transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-darkblue-40">
                                Item 1
                            </div>
                            <div className="transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-darkblue-40">
                                Item 1  
                            </div>
                            <div className="transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-darkblue-40">
                                Item 1
                            </div>
                            <div className="rounded-b-lg transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-darkblue-40">
                                Item 1
                            </div>
                        </div>
                        <div className="rounded-lg bg-grayblue-20">
                            <div className="rounded-t-lg transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-grayblue-40">
                                Item 1
                            </div>
                            <div className="transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-grayblue-40">
                                Item 1  
                            </div>
                            <div className="transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-grayblue-40">
                                Item 1
                            </div>
                            <div className="rounded-b-lg transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-grayblue-40">
                                Item 1
                            </div>
                        </div>
                        <div className="rounded-lg bg-cyan-60"> 
                            <div className="rounded-t-lg transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-cyan-100">
                                Item 1
                            </div>
                            <div className="transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-cyan-100">
                                Item 1  
                            </div>
                            <div className="transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-cyan-100">
                                Item 1
                            </div>
                            <div className="rounded-b-lg transition ease-in-out duration-300 block w-full text-left px-4 py-1.5 hover:bg-cyan-100">
                                Item 1
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="pageHeading block mb-4">Menus</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
                        <div className="rounded-lg bg-grayblue-100 p-6" style={{ height: '32rem' }}>
                            <div className="text-xs font-semibold uppercase text-grayblue-60 mb-2">Content</div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                            <div className="text-xs font-semibold uppercase text-grayblue-60 mt-6 mb-2">Other</div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-grayblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                        </div>
                        <div className="rounded-lg bg-darkblue-100 p-6" style={{ height: '32rem' }}>
                            <div className="text-xs font-semibold uppercase text-darkblue-60 mb-2">Content</div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                            <div className="text-xs font-semibold uppercase text-darkblue-60 mt-6 mb-2">Other</div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-darkblue-60 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-white ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                        </div>
                        <div className="rounded-lg bg-darkblue-20 p-6" style={{ height: '32rem' }}>
                            <div className="text-xs font-semibold uppercase text-darkblue-40 mb-2">Content</div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                            <div className="text-xs font-semibold uppercase text-darkblue-40 mt-6 mb-2">Other</div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-darkblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                        </div>
                        <div className="rounded-lg bg-grayblue-20 p-6" style={{ height: '32rem' }}>
                            <div className="text-xs font-semibold uppercase text-grayblue-40 mb-2">Content</div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                            <div className="text-xs font-semibold uppercase text-grayblue-40 mt-6 mb-2">Other</div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faHome} className="mr-1" /> Item 1
                            </div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-1" /> Item 2
                            </div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faCog} className="mr-1" /> Item 3
                            </div>
                            <div className="rounded hover:bg-grayblue-40 hover:bg-opacity-30 transition ease-in-out duration-300 font-semibold px-3 py-2 text-grayblue-100 ">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Item 4
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="pageHeading block mb-4">Tables</h2>
                    <div className="rounded-lg border-2 border-grayblue-20">
                        <div className="flex bg-grayblue-20 rounded-t-lg text-sm font-semibold px-4 py-2">
                            <div className="w-10 font-semibold">#</div>
                            <div className="w-64 mr-auto">Name</div>
                            <div className="w-20">Data</div>
                        </div>
                        <div className="flex bg-white hover:bg-grayblue-20 hover:bg-opacity-10 px-4 py-2 border-b border-grayblue-20 transition"> 
                            <div className="w-10 font-semibold">1</div>
                            <div className="w-64 mr-auto">John Doe</div>
                            <div className="w-20">A1</div>
                        </div>
                        <div className="flex bg-white hover:bg-grayblue-20 hover:bg-opacity-10 px-4 py-2 border-b border-grayblue-20 transition"> 
                            <div className="w-10 font-semibold">2</div>
                            <div className="w-64 mr-auto">Jane Doe</div>
                            <div className="w-20">A2</div>
                        </div>
                        <div className="flex bg-white hover:bg-grayblue-20 hover:bg-opacity-10 px-4 py-2 rounded-b-lg transition"> 
                            <div className="w-10 font-semibold">3</div>
                            <div className="w-64 mr-auto">Jack Doe</div>
                            <div className="w-20">B1</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Playground;