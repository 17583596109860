import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import { SchemaData } from "../../interfaces/SchemaData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    schemaData: SchemaData[];
    setSchemaData: (schema: SchemaData[]) => void;
    fetchSchemaData: () => void;
}

export const SchemaContext = createContext<ContextType | null>(null);

export const SchemaProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ schemaData, setSchemaData ] = useState<SchemaData[]>([]);

    const fetchSchemaData = useCallback(() => {
        axios.get(`${Config.apiUrl}/schemas/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setSchemaData(response.data);
            else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        fetchSchemaData();
        return () => axiosCancelSource.current?.cancel();
    }, [ fetchSchemaData ]);

    return <SchemaContext.Provider value={{ schemaData, setSchemaData, fetchSchemaData }}>
        {children}
    </SchemaContext.Provider>;
}

export const useSchemaContext = (): ContextType => {
    const context = useContext(SchemaContext);

    if (context == null) {
        throw new Error('useSchemaContext must be used within a CollectionProvider');
    }

    return context;
}