import {useState, useEffect, useRef, FormEvent} from 'react';
import {faLink, faPencilAlt, faPlus, faSearch, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, {CancelTokenSource} from "axios";
import {toast} from "react-toastify";
import Config from "../../Config";
import {confirmAlert} from "react-confirm-alert";
import useCSRF from "../hooks/useCSRF";
import TitleService from "../../services/TitleService";
import {useEditorContext} from "../contexts/Editor.context";
import { useDataStoreContext } from '../contexts/DataStore.context';
import ReactTooltip from 'react-tooltip';
import Breadcrumb from '../other/Breadcrumb';
import InputGroup from '../other/InputGroup';

const DataStore = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const { sessionData } = useEditorContext();
    const { dataStoreData, fetchDataStoreData } = useDataStoreContext();

    const [ modal, setModal ] = useState<number | null>(null);
    const [ search, setSearch ] = useState<string>('');

    const [ formOriginal, setFormOriginal ] = useState<string>('');
    const [ formKey, setFormKey ] = useState<string>('');
    const [ formValue, setFormValue ] = useState<string>('');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Data Store');
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${Config.apiUrl}/datastore/create`, { name: formKey, value: formValue, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchDataStoreData();
                setModal(null);
                setFormOriginal('');
                setFormKey('');
                setFormValue('');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleEdit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${Config.apiUrl}/datastore/update`, { name: formOriginal, newName: formKey, value: formValue, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchDataStoreData();
                setModal(null);
                setFormOriginal('');
                setFormKey('');
                setFormValue('');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleDelete = (id: string) => {
        axios.post(`${Config.apiUrl}/datastore/remove`, { name: id, _csrf }, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                fetchDataStoreData();
                setModal(null);
                setFormOriginal('');
                setFormKey('');
                setFormValue('');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    return (
        <>
            <ReactTooltip />
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 z-20 ${modal !== null ? 'opacity-100' : 'pointer-events-none opacity-0'} transition ease-in-out duration-300`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(null)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Wizard</h2>
                        <form method={"post"} onSubmit={modal === 0 ? handleCreate : handleEdit} className={"grid grid-cols-1 py-4 gap-8"}>
                            <div>
                                <label className={"block pb-2"}>Key</label>
                                <input type={"text"} className={"input-basic"} name={"key"} placeholder={"Maximum: 16 characters"} value={formKey} onChange={(e) => setFormKey(e.target.value)} required />
                            </div>
                            <div>
                                <label className={"block pb-2"}>Value</label>
                                <input type={"text"} className={"input-basic"} name={"value"} placeholder={"Maximum: 128 characters"} value={formValue} onChange={(e) => setFormValue(e.target.value)} required />
                            </div>
                            
                            <div className={"flex"}>
                                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className={"w-auto my-auto mr-auto"}>
                        <h1 className={"pageHeading"}>Data Store</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'DataStore', uri: window.location.pathname } ]} />
                    </div>
                    {sessionData && sessionData.level >= 3 && (
                        <a data-tip="RESTful URL" className={"w-12 my-auto button-small button-black"} target={"_blank"} rel={"noopener noreferrer"} href={`${Config.publicUrl}/${sessionData.projectUUID}/datastore`}>
                            <FontAwesomeIcon icon={faLink} />
                        </a>
                    )}
                    <div className={"w-full md:w-auto my-auto"}>
                        <InputGroup type="text" icon={faSearch} placeholder="Search" value={search} onChange={(e) => setSearch(e)} />
                    </div>
                    <div className={"w-auto"}>
                        <button className="button-small button-cyan" onClick={() => setModal(0)}>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                        </button>
                    </div>
                </div>
            </div>

            <div className="main-container-none"> 
                <div className={"table-container"}>
                    <div className="table-head">
                        <div className="w-48">Key</div>
                        <div className="w-48 mr-auto">Value</div>
                        <div className="w-20" />
                    </div>
                    {dataStoreData && dataStoreData.map((item, index) => (!search || (search && item.key.toLowerCase().includes(search.toLowerCase()))) && (
                        <div className="table-row">
                            <div className={"w-48 my-auto text-gray-800"}>
                                {item.key}
                            </div>
                            <div className={"w-48 my-auto text-gray-800 mr-auto"}>
                                {item.value}
                            </div>
                            <div className="w-20 flex gap-x-2 justify-end" onClick={(e) => e.preventDefault()}>
                                {(sessionData && sessionData?.level >= 3) 
                                    ? <button type={"button"} className="button-tiny button-cyan" onClick={() => { setModal(1); setFormOriginal(item.key); setFormKey(item.key); setFormValue(item.value); }}><FontAwesomeIcon icon={faPencilAlt} /></button>
                                    : ''
                                }
                                {(sessionData && sessionData?.level >= 3) 
                                    ? <button type={"button"} className="button-tiny button-red" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this datastore item?", buttons: [{ label: "Yes", onClick: () => handleDelete(item.key) }, { label: "No", onClick: () => false }] })}><FontAwesomeIcon icon={faTrash} /></button>
                                    : ''
                                }
                            </div>
                        </div>
                    ))}
                    {(dataStoreData && dataStoreData.length === 0) && <div className={"table-none"}>You do not have any datastore items yet, <button type={"button"} onClick={() => setModal(0)} className={"focus:outline-none text-gray-400 hover:text-gray-600 transition font-semibold"}>create one</button>!</div>}
                </div>
            </div>
        </>
    )
}

export default DataStore;