import Config from "../Config"

export const formatFileName = (name: string): string => {
  return name.replace(/\+/g, '%2B')
}
export const formatMediaUrl = (projectId: string, fileName: string): string => {
  console.log('formatting', projectId, fileName)
  return `${Config.mediaUrl}/${projectId}/media/${formatFileName(fileName)}`
}

export const formatFileUrl = (projectId: string, fileName: string): string => {
  fileName = fileName.replace(/\+/g, '%2B')
  return `${Config.fileUrl}/${projectId}/files/${formatFileName(fileName)}`
}

