import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useEditorContext } from "../contexts/Editor.context";

interface IProps {
    items: { name: string, uri?: string }[];
    className?: string | undefined;
}

// (props: IProps)
const Breadcrumb = (props: IProps) => {

    // console.log(!props ? console.log('false') : null);

    const { sessionData } = useEditorContext();
    const history = useHistory();

    return (props.items[0] && props.items[0].uri !== '/') ? (
        <button type="button" onClick={history.goBack} className={`focus:outline-none block text-left font-semibold opacity-80 hover:opacity-90 transition ease-in-out duration-300`}>
            <FontAwesomeIcon icon={faAngleLeft} className="mr-1" /> Back
        </button> 
    ) : (
        <div className={`focus:outline-none block text-left font-semibold opacity-80 hover:opacity-90 transition ease-in-out duration-300`}>
            {sessionData?.projectUUID}
        </div> 
    )

    /*
    const { sessionData } = useEditorContext();

    return (
        <div className={`hidden md:flex font-semibold tracking-tight text-base ${props.className || ''}`}>
            <Link to="/" key={"baseProjectSlash"} className="opacity-70 pr-2">{sessionData?.projectUUID}</Link>
            <span className="opacity-70"><FontAwesomeIcon icon={faAngleRight} /></span>
            {props.items.map((item, index) => (
                <>
                    {item.uri ? (
                        <Link to={item.uri} key={index} className={`px-2 opacity-80 hover:opacity-90 transition ease-in-out duration-300`}>
                            {item.name}  
                        </Link> 
                    ) : <div key={index} className="opacity-40 px-2">{item.name}</div>} 
                    {index !== props.items.length - 1 && <span className="opacity-70"><FontAwesomeIcon icon={faAngleRight} /></span>}
                </>
            ))} 
        </div>
    )*/
}

export default Breadcrumb;