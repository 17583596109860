import {FC} from "react";

interface IProps {
    size: 'extratiny' | 'tiny' | 'small' | 'medium' | 'large';
    editorId: string;
}

const Avatar:FC<IProps> = (props) => {
    const { size, editorId } = props;

    return (
        size === 'extratiny' ? (
            <div className={"w-8 h-8 bg-teal-400 flex rounded-full border-2 border-gray-300 border-opacity-50 uppercase text-white text-center font-light text-sm"}>
                <div className={"m-auto"}>
                    {editorId.substring(0, 2)}
                </div>
            </div>
        ) : size === 'tiny' ? (
            <div className={"w-10 h-10 bg-teal-400 flex rounded-full border-2 border-gray-300 border-opacity-50 uppercase text-white text-center font-light text-lg"}>
                <div className={"m-auto"}>
                    {editorId.substring(0, 2)}
                </div>
            </div>
        ) : size === 'small' ? (
            <div className={"w-16 h-16 bg-teal-400 flex rounded-full border-2 border-gray-300 border-opacity-50 uppercase text-white text-center font-light text-2xl"}>
                <div className={"m-auto"}>
                    {editorId.substring(0, 2)}
                </div>
            </div>
        ) : size === 'medium' ? (
             <div className={"w-20 h-20 bg-teal-400 flex rounded-full border-2 border-gray-300 border-opacity-50 uppercase text-white text-center font-light text-4xl"}>
                 <div className={"m-auto"}>
                    {editorId.substring(0, 2)}
                </div>
             </div>
        ) : (
             <div className={"w-28 h-28 bg-teal-400 flex rounded-full border-2 border-gray-300 border-opacity-50 uppercase text-white text-center font-light text-4xl"}>
                 <div className={"m-auto"}>
                    {editorId.substring(0, 2)}
                </div>
             </div>
        )
    )
}

export default Avatar;