import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import { CollectionData } from "../../interfaces/CollectionData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    collectionLoaded: boolean;
    collectionData: CollectionData[];
    setCollectionData: (collection: CollectionData[]) => void;
    fetchCollectionData: () => void;
}

export const CollectionContext = createContext<ContextType | null>(null);

export const CollectionProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ collectionData, setCollectionData ] = useState<CollectionData[]>([]);
    const [ collectionLoaded, setCollectionLoaded ] = useState<boolean>(false);

    const fetchCollectionData = useCallback(() => {
        axios.get(`${Config.apiUrl}/collections/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setCollectionData(response.data);
                setCollectionLoaded(true);
            } else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        fetchCollectionData();
        return () => axiosCancelSource.current?.cancel();
    }, [fetchCollectionData]);

    return <CollectionContext.Provider value={{ collectionLoaded, collectionData, setCollectionData, fetchCollectionData }}>
        {children}
    </CollectionContext.Provider>;
}

export const useCollectionContext = (): ContextType => {
    const context = useContext(CollectionContext);

    if (context == null) {
        throw new Error('useCollectionContext must be used within a CollectionProvider');
    }

    return context;
}