interface IProps {
    value: string;
    fieldName: string;
}

const PrintWidth = (props: IProps) => {

    const { value, fieldName } = props;

    const getTextWidth = (text: string) => {
        // @ts-ignore
        const canvas: HTMLCanvasElement = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");

        if (context) {
            context.font = 'bold 15pt arial';
            const metrics = context.measureText(text);
            return Math.round(metrics.width);
        } else
            return 0;
    }

    const useTextWidth = getTextWidth(value || '');

    return (
        <div className="text-xs relative">

            {/* Title Printwidth */}

            {/* progress bar for useTextWidth with a max of 600 */}
            {fieldName === 'title' && (
                <div className='flex mt-2'>
                    <div className="progressbar h-2 rounded overflow-hidden bg-gray-50 my-auto w-full">
                        <div className={`progressbar-fill h-full
                    ${(useTextWidth / 600 * 100 < 40 || useTextWidth / 600 * 100 > 100) ? (
                                'bg-error-100'
                            ) : ("")}
                    ${(useTextWidth / 600 * 100 >= 40 && useTextWidth / 600 * 100 <= 70) ? (
                                'bg-orange-500'
                            ) : ("")}
                    ${(useTextWidth / 600 * 100 > 70 && useTextWidth / 600 * 100 <= 100) ? (
                                'bg-success-100'
                            ) : ("")}                     
                    `} style={{ width: `${useTextWidth / 600 * 100}%` }}>
                        </div>
                    </div>
                    <div className='flex my-auto pl-3'>
                        {(useTextWidth / 600 * 100 < 40 || useTextWidth / 600 * 100 > 100) ? (
                            <div className='text-error-100 font-semibold'>
                                Bad
                            </div>
                        ) : ("")}

                        {(useTextWidth / 600 * 100 >= 40 && useTextWidth / 600 * 100 <= 70) ? (
                            <div className='text-orange-500 font-semibold'>
                                Okay
                            </div>
                        ) : ("")}

                        {(useTextWidth / 600 * 100 > 70 && useTextWidth / 600 * 100 <= 100) ? (
                            <div className='text-success-100 font-semibold'>
                                Good
                            </div>
                        ) : ("")}

                    </div>
                </div>
            )}
            {/* progress bar for useTextWidth with a max of 1300 */}
            {fieldName === 'description' && (
                <div className='flex mt-2'>
                    <div className="progressbar h-2 rounded overflow-hidden bg-gray-50 my-auto w-full">
                        <div className={`progressbar-fill h-full
                    ${(useTextWidth / 1300 * 100 < 40 || useTextWidth / 1300 * 100 > 100) ? (
                                'bg-error-100'
                            ) : ("")}
                    ${(useTextWidth / 1300 * 100 >= 40 && useTextWidth / 1300 * 100 <= 70) ? (
                                'bg-orange-500'
                            ) : ("")}
                    ${(useTextWidth / 1300 * 100 > 70 && useTextWidth / 1300 * 100 <= 100) ? (
                                'bg-success-100'
                            ) : ("")}                     
                    `} style={{ width: `${useTextWidth / 1300 * 100}%` }}>
                        </div>
                    </div>
                    <div className='flex my-auto pl-3'>
                        {(useTextWidth / 1300 * 100 < 40 || useTextWidth / 1300 * 100 > 100) ? (
                            <div className='text-error-100 font-semibold'>
                                Bad
                            </div>
                        ) : ("")}

                        {(useTextWidth / 1300 * 100 >= 40 && useTextWidth / 1300 * 100 <= 70) ? (
                            <div className='text-orange-500 font-semibold'>
                                Okay
                            </div>
                        ) : ("")}

                        {(useTextWidth / 1300 * 100 > 70 && useTextWidth / 1300 * 100 <= 100) ? (
                            <div className='text-success-100 font-semibold'>
                                Good
                            </div>
                        ) : ("")}

                    </div>
                </div>
            )}
    {/* keywords popup */}
            {fieldName === 'keywords' && (               
                <div className='flex mt-2'>
                    <div className='flex my-auto'>

                    {(useTextWidth / 500 * 100 < 5 || useTextWidth / 500 * 100 > 100) ? (
                            <div className='text-error-100 font-semibold'>
                                Bad
                            </div>
                        ) : ("")}

                        {(useTextWidth / 500 * 100 >= 5 && useTextWidth / 500 * 100 <= 100) ? (
                            <div className='text-success-100 font-semibold'>
                                Good
                            </div>
                        ) : ("")}

                    </div>
                </div>
            )}
        </div>
    )
}

export default PrintWidth;