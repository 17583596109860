import axios, { CancelTokenSource } from 'axios';
import {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react';
import Config from "../../Config";
import {toast} from "react-toastify";
import { MediaData } from "../../interfaces/MediaData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt, faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import useCSRF from "../hooks/useCSRF";
import moment from "moment";
import TitleService from "../../services/TitleService";
import {confirmAlert} from "react-confirm-alert";
import Pagination from '../other/Pagination';
import getRelativeSize from '../../services/getRelativeSize';
import Breadcrumb from '../other/Breadcrumb';
import InputGroup from '../other/InputGroup';
import { useMediaContext } from '../contexts/Media.context';
import {formatMediaUrl} from '../../utils/formatS3ItemUrl';

const Media = () => {

    const { mediaData, fetchMediaData } = useMediaContext();

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const [ actualSearch, setActualSearch ] = useState<string>('');
    const [ search, setSearch ] = useState<string>('');
    const [ skip, setSkip ] = useState<number>(0);
    const [ active, setActive ] = useState<number | null>(null);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Media');
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    const handleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setActualSearch(search);
        setActive(null);
    }

    const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

        const useFile = e.target.files;
        const useFormData = new FormData();

        if (!useFile)
            return toast.error("You do not have a file to be uploaded!");

        if (useFile?.length > 1)
            return toast.error("You can only upload one file at a time!");

        if (!useFile[0].type.startsWith('image'))
            return toast.error("You can only upload images!");

        // Start upload code here
        useFormData.append("image", useFile[0], useFile[0].name);
        useFormData.append("_csrf", _csrf);

        axios.post(`${Config.apiUrl}/media/create`, useFormData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!response.data.error)
                fetchMediaData();
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'))
        .finally(() => {
          e.target.value = '';
        });
    }

    const deleteFile = (indexId: number) => {
        const useFile = newMediaData[indexId];

        if (!useFile)
            return toast.error('Invalid file selected!');

        const useFormData = { name: useFile.mediaId, _csrf }

        axios.post(`${Config.apiUrl}/media/remove`, useFormData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
        }).then((response) => {
            if (!response.data.error) {
                fetchMediaData();
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const newMediaData: MediaData[] = [];
    mediaData.map((item) => (!actualSearch || (actualSearch && item.mediaId.toLowerCase().includes(actualSearch.toLowerCase())) ? newMediaData.push(item) : null));

    return (
        <>
            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Media</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Media', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <Pagination 
                            skip={skip} 
                            total={newMediaData.length} 
                            perPage={20} 
                            next={() => { setActive(null); setSkip(skip + 28); }} 
                            prev={() => { setActive(null); setSkip(skip - 28); }} 
                        />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <form onSubmit={handleSearch}>
                            <InputGroup icon={faSearch} type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e)} />
                        </form>
                    </div>
                    <div>
                        <input accept="image/*" id={"fileUpload"} name={"fileUpload"} style={{ display: 'none' }} type={"file"} onChange={onFileUpload} />
                        <label style={{ color: '#fff', padding: '10px 16px' }} className="button-small button-cyan cursor-pointer" htmlFor={"fileUpload"}>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Upload
                        </label>
                    </div>
                </div>
            </div>

            <div className="main-container-none">    
                <div className={`grid grid-cols-12 gap-6 min-h-partial`}>
                    <div className={`col-span-full lg:col-span-8 6xl:col-span-9 p-6`}> 
                        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 6xl:grid-cols-7 gap-4`}> 
                            {newMediaData.length !== 0
                                ? newMediaData.slice(skip, skip + 28).map((item, index) => (
                                    <button key={index} type="button" onClick={() => setActive(skip + index)} className={`focus:outline-none rounded border-4 ${active === (skip + index) ? 'border-teal-400' : 'border-gray-300'}`}>
                                        <img className="w-56 h-40 object-cover object-center" src={formatMediaUrl(item.projectUUID, item.source)} alt={item.mediaId} />
                                    </button>
                                ))
                                : <div className={"block col-span-full w-full rounded-lg table-none"}>You have no media yet!</div>
                            }
                        </div>
                    </div>
                    <div className="col-span-full lg:col-span-4 6xl:col-span-3 p-6 border-l h-full">
                        {active !== null ? (
                            <div>
                                <div className="relative">
                                    <img className="w-full h-auto object-cover bg-gray-100 border-4 border-gray-200" src={formatMediaUrl(newMediaData[active].projectUUID, newMediaData[active].source)} alt={newMediaData[active].mediaId} />
                                    <div className="absolute bottom-3 right-3 px-2 bg-black bg-opacity-80 text-sm text-white font-semibold rounded-lg">
                                        {newMediaData[active].width}x{newMediaData[active].height}
                                    </div>
                                    <div className="absolute bottom-3 left-3 px-2 bg-black bg-opacity-80 text-sm text-white font-semibold rounded-lg">
                                        {getRelativeSize(newMediaData[active].size)}
                                    </div>
                                </div>
                                <div className={"text-gray-700 font-semibold mt-6"}>
                                    {newMediaData[active].mediaId}
                                </div>
                                <div className={"text-gray-500 text-sm"}>
                                    Added {moment(newMediaData[active].created).fromNow()}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
                                    <div>
                                        <div className={"w-20 my-auto"}>
                                            <button type={"button"} onClick={() => confirmAlert({ message: "Are you sure you want to do delete this asset?", buttons: [{ label: "Yes", onClick: () => deleteFile(active) }, { label: "No", onClick: () => false }] })} className={"button-small button-red"}>Delete</button>
                                        </div>
                                    </div>
                                    <div className="text-left md:text-right my-auto">
                                        <a href={formatMediaUrl(newMediaData[active].projectUUID, newMediaData[active].source)} rel="noopener noreferrer" target="_blank" className={"text-gray-700 hover:text-gray-500 font-semibold uppercase transition ease-in-out duration-300"}>
                                            <FontAwesomeIcon icon={faExternalLinkAlt} /> External
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="relative">
                                    <div className="w-full h-64 bg-gray-300" />
                                    <div className="absolute bottom-3 right-3 px-2 bg-black bg-opacity-80 text-sm text-white font-semibold rounded-lg">
                                        1x1
                                    </div>
                                </div>
                                <div className={"text-gray-700 text-center font-semibold mt-10"}>
                                    You do not have an image selected
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media;