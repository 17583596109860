import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    icon: IconDefinition;
    type: string;
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    className?: string;
}

const InputGroup = (props: IProps) => {
    return (
        <div className={`flex ${props.className} border rounded-md`}>
            <div className={"w-10 my-auto text-center text-gray-600"}>
                <FontAwesomeIcon icon={props.icon} />
            </div>
            <input style={{ border: 'none' }} type={props.type || 'text'} className={"py-2 w-full block bg-transparent focus:outline-none"} placeholder={props.placeholder} value={props.value} onChange={(e) => props.onChange(e.target.value)} />
        </div>
    )
};

export default InputGroup;