import { FC } from 'react';
import { ObjectProps } from './GlobalObject';
import PrintWidth from './PrintWidth';
import PrintWidthPopup from './PrintWidthPopup';

interface IProps extends ObjectProps {
    isSEO?: boolean;
}

const Textarea:FC<IProps> = (props) => {

    return (
        <div>
            <div className='flex align-middle'><label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label> {props.isSEO && <div> <PrintWidthPopup fieldName={props.name} /> </div> }</div>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <textarea id={props.name} rows={3} name={props.name} required={props.required} placeholder={props.hint || ''} maxLength={props.maxlength ? Number(props.maxlength) : 1024} onChange={props.onChange}>{props.value}</textarea>
            {props.isSEO && <div> <PrintWidth value={props.value || ''} fieldName={props.name} /> </div> }
            <div className="flex justify-between pt-1">
                <div className="text-xs text-gray-500">{((Number(props.maxlength) || 1024) - String(props.value || '').length).toLocaleString()} character{((Number(props.maxlength) || 1024) - String(props.value || '').length) !== 1 ? 's' : ''} left</div>
            </div>        
        </div>
    )
}

export default Textarea;