import {FC} from 'react';
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
    partial?: boolean;
}

const Loading:FC<IProps> = (props) => {
    const { partial } = props;

    return (
        <div className={`flex w-full ${partial ? 'py-64' : 'h-screen'}`}>
            <div className={"m-auto"}>
                <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
            </div>
        </div>
    )
}

export default Loading;