import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useEffect, useState } from "react";
import { render, unmountComponentAtNode } from "react-dom";

interface IProps {
    value: string;
    message: string;
    onSuccess: () => void;
}

const TextConfirm = (props: IProps) => {

    const { value, message, onSuccess } = props;
    const [ string, setString ] = useState<string>('');
    const [ confirm, setConfirm ] = useState<string>(value);

    useEffect(() => {
        setConfirm(value);
        setString('');
    }, [ value ]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (string === value) {
            onSuccess();
            unmountElementFromDOM();
        }
    }

    useEffect(() => {
        const handleOnClick = (e: MouseEvent) => (e.target as HTMLElement)?.classList.contains('modal-bg') && unmountElementFromDOM();

        window.addEventListener('click', handleOnClick);
        return () => window.removeEventListener('click', handleOnClick);
    }, []);

    return (
        <div style={{ zIndex: 55 }} className={`modal-bg fixed inset-0 w-full bg-black bg-opacity-50 transition ease-in-out duration-300`}>
            <div className={`modal-bg flex w-full h-screen justify-center items-center px-10`}>
                <div className="bg-white w-full md:w-128 rounded-2xl p-8 shadow-xl relative">
                    <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => unmountElementFromDOM()}>
                        <FontAwesomeIcon icon={faTimes} className={"text-2xl mt-1"} /> 
                    </button>
                    <form method="post" onSubmit={handleSubmit}>
                        <div className="mb-4 text-lg">
                            {message}
                        </div>
                        <div className="mb-4">
                            Please type <span className="text-blue-500 font-semibold">{value}</span> to confirm.
                            <input type="text" className="input-basic mt-4" value={string} onChange={(e) => setString(e.target.value)} />
                        </div>
                        <button type="submit" className={`button-small button-cyan ${string === confirm ? '' : 'pointer-events-none opacity-50'} transition ease-in-out duration-300`}>
                            Confirm
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

function mountElementToDOM(properties: IProps) {
    let divTarget = document.getElementById('react-text-alert')
    if (divTarget) {
        // Rerender - the mounted ReactConfirmAlert
        render(<TextConfirm {...properties} />, divTarget)
    } else {
        // Mount the ReactConfirmAlert component
        divTarget = document.createElement('div')
        divTarget.id = 'react-text-alert'
        document.body.appendChild(divTarget)
        render(<TextConfirm {...properties} />, divTarget)
    }
}

function unmountElementFromDOM () {
    const target = document.getElementById('react-text-alert')
    if (target) {
        unmountComponentAtNode(target)
        target.parentNode?.removeChild(target)
    }
}

export function confirmAlert(properties: IProps) {
    mountElementToDOM(properties)
}

export default TextConfirm;