import { FC } from 'react';
import {CommitData} from "../../interfaces/CommitData";
import moment from "moment";
import Avatar from "./Avatar";

interface CommitDataProps extends CommitData {
    size?: 'table' | 'tiny' | 'small' | 'regular' | 'large';
    index?: number;
}

const Commit:FC<CommitDataProps> = (props) => {

    const returnFilter = (type: string) => {
        let value:string;

        switch(type) {
            case 'create':
                value = "Created";
                break;
            case 'update':
                value = "Updated";
                break;
            case 'delete':
                value = "Removed";
                break;
            default:
                value = "Unknown";
                break;
        }
        return value;
    }

    const returnType = () => {
        if (props.schemaId)
            return `Schema: ${props.schemaId}`;
        else if (props.collectionId && !props.documentId)
            return `Collection: ${props.collectionId}`;
        else if (props.collectionId && props.documentId)
            return `Document: ${props.documentId}`;
        else
            return `Unknown`;
    }

    return props.size === 'regular' ? (
        <div className="table-row">
            <div className="w-10 font-semibold my-auto">{(props.index || 0) + 1}</div>
            <div className="w-full sm:w-64 flex mr-auto">
                <div className={"hidden lg:block w-auto"}>
                    <Avatar size={'extratiny'} editorId={props.editorId} />
                </div>
                <div className={"w-auto my-auto pl-3"}>
                    {props.editorId}
                </div>
            </div>
            <div className="hidden lg:w-96 lg:block text-sm xl:text-sm my-auto">{returnFilter(props.type)} {returnType()}</div>
            <div className="w-40 my-auto">{moment(props.created).fromNow()}</div>
        </div>
    ) : props.size === 'small' ? (
        <div className={"bg-gray-50 mb-2 rounded-lg border border-gray-200 p-3"}>
            <div className={"flex"}>
                <div className={"w-auto pt-0.5"}>
                    <Avatar size={"tiny"} editorId={props.editorId} />
                </div>
                <div className={"w-auto pl-3"}>
                    <div className={"font-semibold"}>{props.editorId}</div>
                    <div className={"text-xs"}>{moment(props.created).fromNow()}</div>
                </div>
            </div>
        </div>
    ) : props.size === 'tiny' ? (
        <div className={"table-row"}>
            <div className={"w-auto pt-0.5"}>
                <Avatar size={'tiny'} editorId={props.editorId} />
            </div>
            <div className={"w-auto pl-3"}>
                <div className={"font-semibold text-gray-900"}>{props.editorId}</div>
                <div className={"text-xs text-gray-800"}>{returnFilter(props.type)} {returnType()}</div>
            </div>

            <div className={"w-44 text-right my-auto ml-auto"}>
                {moment(props.created).fromNow()}
            </div>
        </div>
    ) : props.size === 'table' ? (
        <div className="flex">
            <div className={"w-auto"}>
                <Avatar size={'extratiny'} editorId={props.editorId} />
            </div>
            <div className={"w-auto my-auto text-sm pl-3"}>
                {moment(props.created).fromNow()}
            </div>
        </div>
    ) : <></>
}

export default Commit;