import {FC, useState, createContext, useContext, useEffect, useRef} from 'react';
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";
import {AccountData} from "../../interfaces/AccountData";

interface ContextType {
    adminLoaded: boolean;
    setAdminLoaded: (loaded: boolean) => void;
    adminData: AccountData | null;
    setAdminData: (user: AccountData | null) => void;
}

export const AdminContext = createContext<ContextType | null>(null);

export const AdminProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ adminData, setAdminData ] = useState<AccountData | null>(null);
    const [ adminLoaded, setAdminLoaded ] = useState<boolean>(false);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        axios.get(`${Config.apiUrl}/auth/session`,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setAdminData(response.data.data);

            setAdminLoaded(true);
        }).catch(() => console.error('Unexpected error occurred!'));
        return () => axiosCancelSource.current?.cancel();
    }, []);

    return <AdminContext.Provider value={{ adminData, setAdminData, adminLoaded, setAdminLoaded }}>
        {children}
    </AdminContext.Provider>;
}

export const useAdminContext = (): ContextType => {
    const context = useContext(AdminContext);

    if (context == null) {
        throw new Error('useAdminContext must be used within a AdminProvider');
    }

    return context;
}