import {MouseEvent, ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {faFile, faFilePdf, faPlus, faSave, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ObjectProps } from './GlobalObject';
import { FileData } from "../../interfaces/FileData";
import axios, {CancelTokenSource} from "axios";
import {toast} from "react-toastify";
import Config from "../../Config";
import useCSRF from "../hooks/useCSRF";
import InputGroup from '../other/InputGroup';
import { useFileContext } from '../contexts/File.context';
import moment from 'moment';
import {formatFileName} from '../../utils/formatS3ItemUrl';

const File:FC<ObjectProps> = (props) => {

    const { fileData, fetchFileData } = useFileContext();

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const [ modal, setModal ] = useState<boolean>(false);
    const [ loaded, setLoaded ] = useState<boolean>(true);
    const [ fileSrc, setFileSrc ] = useState<string>(props.value || '');
    const [ newFileSrc, setNewFileSrc ] = useState<string>('');
    const [ activeSearch, setActiveSearch ] = useState<string>('');

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        return () => axiosCancelSource.current?.cancel();
    }, [ ]);

    useEffect(() => {
        setFileSrc(props.value || '');
    }, [ props.value ]);

    const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        //e.preventDefault();

        const useFile = e.target.files;
        const useFormData = new FormData();

        if (!useFile)
            return toast.error("You do not have a file to be uploaded!");

        if (useFile?.length > 1)
            return toast.error("You can only upload one file at a time!");

        // Start upload code here
        useFormData.append("file", useFile[0], useFile[0].name);
        useFormData.append("_csrf", _csrf);

        axios.post(`${Config.apiUrl}/file/create`, useFormData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!response.data.error)
                fetchFileData();
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const onChange = (e: MouseEvent<HTMLButtonElement>, item: FileData) => {
        setNewFileSrc(`/files/${formatFileName(item.source)}`);

        console.log('onChange');
    }
    const onSave = () => {
        setFileSrc(newFileSrc);
        setNewFileSrc('');
        setLoaded(true);
        setModal(false);
        const objectData = {
            target: {
                value: newFileSrc
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    const onReset = () => {
        setNewFileSrc('');
        setFileSrc('');
        setLoaded(false);
        const objectData = {
            target: {
                value: ''
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    const pFileData: FileData[] = [];
    fileData.map((item) => (
        (!activeSearch || (activeSearch && item.fileId.toLowerCase().includes(activeSearch.toLowerCase())))
    ) ? pFileData.push(item) : null);

    const useSource = fileSrc.split('/');

    return (
        <>
            {modal && (
                <div className={`fixed w-full flex h-screen top-0 right-0 bottom-0 left-0 bg-black bg-opacity-20 transition ease-in-out duration-500 z-50`}>
                    <div className="w-full m-auto px-10 relative">
                        <div className="relative bg-white rounded w-11/12 sm:w-10/12 md:w-4/5 lg:w-4/6 mx-auto p-6">
                            <div className="flex flex-wrap border-b pb-3 mb-3 border-gray-200">
                                <div className="w-auto mr-auto my-auto">
                                    <span className="pageHeading">
                                        Files
                                    </span>
                                </div>
                                <div className="w-auto">
                                    <div className="flex flex-wrap gap-2">
                                        <div className={"w-full md:w-auto my-auto"}>
                                            <InputGroup type="text" icon={faSearch} placeholder="Search" value={activeSearch} onChange={(e) => setActiveSearch(e)}/>
                                        </div>
                                        <div className="my-auto media-upload-button">
                                            <input accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id={"fileUpload"} name={"fileUpload"} style={{ display: 'none' }} type={"file"} onChange={onFileUpload} />
                                            <label style={{ color: '#fff', padding: '10px 16px', textTransform: 'none' }} className="button-small button-black hover:cursor-pointer" htmlFor={"fileUpload"}>
                                                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Upload
                                            </label>
                                        </div>
                                        {newFileSrc && (
                                            <div className="my-auto">
                                                <button type="button" onClick={onSave} className="h-full button-small button-cyan">
                                                    <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                                                </button>
                                            </div>
                                        )}
                                        <div className="my-auto">
                                            <button type="button" className="button-small button-gray" onClick={() => { setNewFileSrc(''); setModal(false) }}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"table-container"}>
                                <div className="table-head">
                                    <div className="w-10">#</div>
                                    <div className="w-full sm:w-64 mr-auto">File</div> 
                                    <div className="hidden md:w-48 lg:block">Uploaded</div>
                                </div>
                                <div className={"overflow-y-scroll w-full"} style={{ height: '70vh' }}>
                                    {pFileData.length !== 0
                                        ? pFileData.map((item, index) => (
                                            <button key={index} type="button" className={"table-row text-left w-full"} onClick={(e) => onChange(e, item)}> 
                                                <div className="w-10">{index + 1}</div>
                                                <div className="w-full sm:w-64 mr-auto">
                                                    <FontAwesomeIcon icon={item.source.endsWith('.pdf') ? faFilePdf : faFile} className="mr-2" />
                                                    {item.source}
                                                </div> 
                                                <div className="hidden md:w-48 lg:block">
                                                    {moment(item.created).fromNow()}
                                                </div>
                                            </button>
                                        )) : <div className={"table-none"}>There are no files at the moment, check back later!</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <label htmlFor={props.name} className="block pb-1">{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
                <div className="flex">
                    {(useSource && useSource.length !== 0) ? <div className="my-auto">{useSource[useSource.length - 1]}</div> : <></>}
                    <div className="w-auto my-auto relative">
                        <input type={"text"} className="opacity-0 absolute left-0 pointer-events-none" value={loaded ? `${Config.fileUrl}${fileSrc}` : ''} required={props.required} />
                        <button type="button" className="button-small button-cyan" onClick={() => setModal(true)}>Open</button>
                        {fileSrc && (
                            <button type="button" className="button-small button-red ml-2" onClick={() => onReset()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        )}
                        {props.hint && <div className="text-sm text-gray-500 mt-1">{props.hint}</div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default File;
