import {FC, useState, createContext, useContext, useEffect, useRef, useCallback} from 'react';
import { ExternalData } from "../../interfaces/ExternalData";
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";

interface ContextType {
    externalData: ExternalData[];
    setExternalData: (External: ExternalData[]) => void;
    fetchExternalData: () => void;
}

export const ExternalContext = createContext<ContextType | null>(null);

export const ExternalProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [ externalData, setExternalData ] = useState<ExternalData[]>([]);

    const fetchExternalData = useCallback(() => {
        axios.get(`${Config.apiUrl}/externals/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setExternalData(response.data);
            else
                console.error(response.data.error);
        }).catch(() => console.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        fetchExternalData();
        return () => axiosCancelSource.current?.cancel();
    }, [ fetchExternalData ]);

    return <ExternalContext.Provider value={{ externalData, setExternalData, fetchExternalData }}>
        {children}
    </ExternalContext.Provider>;
}

export const useExternalContext = (): ContextType => {
    const context = useContext(ExternalContext);

    if (context == null) {
        throw new Error('useExternalContext must be used within a ExternalProvider');
    }

    return context;
}