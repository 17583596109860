import {ChangeEvent, FC, useState} from 'react';
import { ObjectProps } from './GlobalObject';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Multiple:FC<ObjectProps> = (props) => {

    const [ items, setItems ] = useState<string[]>(( typeof props.value === 'object' && Array.isArray(props.value) ) ? props.value : []);

    const onChange = (newItems: string[]) => {
        const objectData = {
            target: {
                value: JSON.stringify(newItems)
            }
        } as ChangeEvent<HTMLInputElement>;
        props.onChange(objectData);
    }

    const addItem = (value: string) => {
        const newItems = [ ...items, value ];
        setItems((items) => [ ...items, value ]);
        onChange(newItems);
    }

    const removeItem = (index: number) => {
        const useItems = items;

        if (useItems && useItems[index]) {
            delete useItems[index];
            const newItems = Object.values(useItems);
            setItems([ ...newItems ]);

            onChange(newItems);
        }
    }

    return (
        <div>
            <label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <select onChange={(e) => addItem(e.target.value)}>
                <option key={""} value={""}>Select</option>
                {props.items?.map((item) => !items.includes(item) && <option key={item} value={item} selected={item === props.value}>{item}</option>)}
            </select>
            <div className={"flex flex-wrap gap-3 mt-2"}>
                {items.map((item, index) => (
                    <div key={index} className={"inline px-4 py-1 text-sm uppercase text-gray-900 bg-gray-200 rounded-full"}>
                        {item}
                        <button type={"button"} onClick={() => removeItem(index)} className={"ml-2 focus:outline-none text-gray-800 hover:text-gray-700"}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Multiple;