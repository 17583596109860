import {FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import {useProjectContext} from "../../contexts/Project.context";
import axios, {CancelTokenSource} from "axios";
import Config from "../../../Config";
import {toast} from "react-toastify";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import {Redirect, useParams} from "react-router-dom";
import {ProjectData} from "../../../interfaces/ProjectData";
import {faAngleDown, faEye, faFileExport, faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SettingsOption from '../../other/AdminProjectSetting';
import Breadcrumb from '../../other/Breadcrumb';
import { useEditorContext } from '../../contexts/Editor.context';
import { useCollectionContext } from '../../contexts/Collection.context';
import { useFileContext } from '../../contexts/File.context';
import { useMediaContext } from '../../contexts/Media.context';
import { useSchemaContext } from '../../contexts/Schema.context';
import { useExternalContext } from '../../contexts/External.context';
import { useTagContext } from '../../contexts/Tag.context';
import ReactTooltip from 'react-tooltip';
import { useAdminContext } from '../../contexts/Admin.context';
import { confirmAlert } from '../../other/TextConfirm';

const Projects = () => {

    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { _csrf } = useCSRF();
    const { projectData, setProjectData } = useProjectContext();
    const { adminLoaded, adminData } = useAdminContext();
    const { setSessionData, sessionLogout } = useEditorContext();
    const { fetchCollectionData } = useCollectionContext();
    const { fetchTagData } = useTagContext();
    const { fetchSchemaData } = useSchemaContext();
    const { fetchMediaData } = useMediaContext();
    const { fetchFileData } = useFileContext();
    const { fetchExternalData } = useExternalContext();

    const { id = '0' } = useParams<{ id: string }>();

    // Tab
    const [ redirect, setRedirect ] = useState<string>('');

    // Other Data
    const [ exportData, setExportData ] = useState<{ rest: string[], collections: string[], documents: string[], general: string[], tagged: string[], media: string[], files: string[] } | null>(null);
    const [ exportTab, setExportTab ] = useState<string>('');

    // Data
    const [ useProject, setUseProject ] = useState<ProjectData | null>(null);
    const [ projectName, setProjectName ] = useState<string | null>(null);
    const [ color, setColor ] = useState<string | null>(null);
    const [ buildHook, setBuildHook ] = useState<string | null>(null);
    const [ userRoles, setUserRoles ] = useState<string[] | null>(null);
    const [ userPasswordLevel, setUserPasswordLevel ] = useState<number | null>(null);
    const [ userRedirectUri, setUserRedirectUri ] = useState<string | null>(null);
    const [ userTermsUri, setUserTermsUri ] = useState<string | null>(null);
    const [ userPrivacyUri, setUserPrivacyUri ] = useState<string | null>(null);
    const [ userLoginUri, setUserLoginUri ] = useState<string | null>(null);
    const [ userActivateUri, setUserActivateUri ] = useState<string | null>(null);
    const [ userForgotUri, setUserForgotUri ] = useState<string | null>(null);
    const [ userEnabled, setUserEnabled ] = useState<boolean | null>(null);

    // Effect
    useEffect(() => {
        if (projectData) {
            if (projectData[Number(id)]) 
                setUseProject(projectData[Number(id)]);
        }
    }, [ projectData, id ]);

    useEffect(() => {
        TitleService.set(`Project: ${useProject?.name}`);
    }, [ useProject ]);

    useEffect(() => {
        if (useProject !== null) {
            axios.get(`${Config.apiUrl}/export/${useProject.projectUUID}/list`,{
                cancelToken: axiosCancelSource.current?.token,
                withCredentials: true
            }).then((response) => {
                if (!response.data.error)
                    setExportData({ ...response.data, rest: [ response.data.rest.bucketUrl, response.data.rest.mediaUrl, response.data.rest.fileUrl ], datastore: [ String(response.data.datastore) ] });
                else
                    toast.error(response.data.error);
            });
        }
    }, [ useProject ]);

    const handleSelect = (id: number) => {
        if (!projectData[id])
            return toast.error("Invalid project selected!");

        const formData = { name: projectData[id].projectUUID, _csrf };

        axios.post(`${Config.apiUrl}/projects/select`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success('You have switched projects, if you are experiencing issues try refreshing your page!');
                setSessionData({ ...response.data });
                fetchCollectionData();
                fetchTagData();
                fetchSchemaData();
                fetchMediaData();
                fetchFileData();
                fetchExternalData();
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleDelete = (id: number) => {
        if (!projectData[id])
            return toast.error("Invalid project selected!");

        const formData = { name: projectData[id].projectUUID, _csrf };

        axios.post(`${Config.apiUrl}/projects/remove`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success('Your project has been removed!');
                const length = projectData.length; let i;
                const data = [];
                for (i = 0; i < length; i++) {
                    if (response.data.projectUUID !== projectData[i].projectUUID)
                        data.push(projectData[i]);
                }

                setSessionData(null);
                sessionLogout();

                setProjectData([ ...data ]);
                setRedirect('/');
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleUpdate = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const useProject = projectData[Number(id)];

        let hooks = useProject.buildHooks
        if (buildHook !== null) {
            hooks = buildHook.split(',').map((h) => h.trim()).filter(h => !!h)
        }
        const formData = { 
            name: projectName, 
            id: useProject.projectUUID, 
            buildHooks: hooks,
            uuid: useProject.projectUUID, 
            color: (color !== null ? color : useProject.color), 
            userPasswordLevel: userPasswordLevel !== null ? (userPasswordLevel || 1) : (useProject.userPasswordLevel || 1),
            userEnabled: userEnabled !== null ? userEnabled : useProject.userEnabled,
            userRedirectUri: userRedirectUri || useProject.userRedirectUri,
            userPrivacyUri: userPrivacyUri || useProject.userPrivacyUri,
            userTermsUri: userTermsUri || useProject.userTermsUri,
            userActivateUri: userActivateUri || useProject.userActivateUri,
            userForgotUri: userForgotUri || useProject.userForgotUri,
            userLoginUri: userLoginUri || useProject.userLoginUri,
            userRoles: userRoles !== null ? userRoles : useProject.userRoles,
            _csrf 
        };

        axios.post(`${Config.apiUrl}/projects/update`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success('Your project has been updated!');

                const length = projectData.length; let i;
                const data = [];
                for (i = 0; i < length; i++) {
                    console.log(projectData[i].projectUUID, response.data.projectUUID);
                    if (response.data.projectUUID === projectData[i].projectUUID)
                        data.push(response.data);
                    else
                        data.push(projectData[i]);
                }

                setProjectName(null);
                setColor(null);
                setBuildHook(null);
                setProjectData([ ...data ]);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Projects')
        return () => axiosCancelSource.current?.cancel();
    }, []);

    return (adminLoaded && adminData !== null) ? (
        <>
            {redirect && <Redirect to={redirect} />}
            {useProject && (
                <>
                    <ReactTooltip place="left" />
                    <div className="header-container-none">
                        <div className="header">
                            <div className="w-auto mr-auto">
                                <h1 className={"pageHeading"}>{useProject?.name} ({useProject?.projectUUID})</h1>
                                <Breadcrumb className="mb-4" items={[ { name: 'Projects', uri: window.location.pathname }, { name: useProject?.projectUUID || ''} ]} />
                            </div>
                            <div className={"w-auto my-auto"}>
                                <button type="button" onClick={() => handleSelect(Number(id))} className={"button-small button-black mr-2"}>
                                    View as Editor
                                </button>
                                <button type={"button"} onClick={handleUpdate} className={"button-small button-cyan"}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="main-container-none"> 
                        <div className="grid grid-cols-1 lg:grid-cols-3">
                            <div className="col-span-full lg:col-span-2 border-r">
                                <form method={"post"} onSubmit={handleUpdate}>
                                    <div className="p-6 border-b">
                                        <h2 className={"block pb-4"}>Settings</h2>
                                        <div className="grid grid-cols-1 gap-4">
                                            <SettingsOption label="Title" type="text" name="projectName" value={projectName !== null ? projectName : useProject.name} onChange={(e) => setProjectName(e as string)} />
                                            <SettingsOption label="Buildhook" type="text" name="buildHook" value={buildHook !== null ? buildHook : useProject.buildHooks.join(',')} onChange={(e) => setBuildHook(e as string)} />
                                            <SettingsOption label="Accent Color" type="colorpicker" name="color" value={color !== null ? color : useProject.color} onChange={(e) => setColor(e as string)} />
                                        </div>
                                    </div>
                                    
                                    <div className="p-6">
                                        <h2 className={"block pb-4"}>Users</h2>
                                        <div className="grid grid-cols-1 gap-4">
                                            <SettingsOption label="Enable Users" type="enable" name="userEnable" value={userEnabled !== null ? userEnabled : useProject.userEnabled} onChange={(e) => setUserEnabled(e as boolean)} />
                                            <SettingsOption label="Password Security" type="level" name="userPasswordLevel" value={userPasswordLevel !== null ? (userPasswordLevel || 1) : (useProject.userPasswordLevel || 1)} onChange={(e) => setUserPasswordLevel(e as number)} />
                                            <SettingsOption label="Roles" type="multiple" name="userRoles" placeholder="Press enter to add to list" value={userRoles !== null ? userRoles : useProject.userRoles} onChange={(e) => setUserRoles(e as string[])} />
                                            <SettingsOption label="Redirect / Callback" type="text" name="userRedirectUri" value={userRedirectUri !== null ? userRedirectUri : useProject.userRedirectUri} onChange={(e) => setUserRedirectUri(e as string)} />
                                            <SettingsOption label="Login / Sign In" type="text" name="userLoginUri" value={userLoginUri !== null ? userLoginUri : useProject.userLoginUri} onChange={(e) => setUserLoginUri(e as string)} />
                                            <SettingsOption label="Forgot Password" type="text" name="userForgotUri" value={userForgotUri !== null ? userForgotUri : useProject.userForgotUri} onChange={(e) => setUserForgotUri(e as string)} />
                                            <SettingsOption label="Activate your Account" type="text" name="userActivateUri" value={userActivateUri !== null ? userActivateUri : useProject.userActivateUri} onChange={(e) => setUserActivateUri(e as string)} />
                                            <SettingsOption label="Terms of Service" type="text" name="userTermsUri" value={userTermsUri !== null ? userTermsUri : useProject.userTermsUri} onChange={(e) => setUserTermsUri(e as string)} />
                                            <SettingsOption label="Privacy Policy" type="text" name="userPrivacyUri" value={userPrivacyUri !== null ? userPrivacyUri : useProject.userPrivacyUri} onChange={(e) => setUserPrivacyUri(e as string)} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="p-6 border-b">
                                    <h2 className={"block mb-4"}>Plan Details</h2>
                                    <div>
                                        <div className="flex flex-wrap justify-between py-2">
                                            Tier
                                            <div>
                                                {useProject?.planTier}
                                            </div>
                                        </div>
                                        <div className="border-t flex flex-wrap justify-between py-2">
                                            Max. Collections
                                            <div>
                                                {useProject?.maxCollections.toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="border-t flex flex-wrap justify-between py-2">
                                            Max. Schemas
                                            <div>
                                                {useProject?.maxSchemas.toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="border-t flex flex-wrap justify-between py-2">
                                            Max. Documents
                                            <div>
                                                {useProject?.maxDocuments.toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="border-t flex flex-wrap justify-between py-2">
                                            Max. Media
                                            <div>
                                                {useProject?.maxMedia.toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="border-t flex flex-wrap justify-between py-2">
                                            Max. Upload Size
                                            <div>
                                                {Math.round(useProject?.maxUploadSize / 1024)} MB
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6 border-b">
                                    <div className="flex justify-between mb-6">
                                        <h2 className={"block"}>Export</h2>
                                        <div className="flex space-x-2 my-auto">
                                            <a data-tip="REST Routes" href={`${Config.apiUrl}/export/${useProject.projectUUID}/list`} target="_blank" rel="noopener noreferrer" className="button-tiny button-cyan">
                                                <FontAwesomeIcon icon={faList} />
                                            </a>
                                            <a data-tip="REST JSON" href={`${Config.apiUrl}/export/${useProject.projectUUID}/json`} target="_blank" rel="noopener noreferrer" className="button-tiny button-cyan">
                                                <FontAwesomeIcon icon={faFileExport} />
                                            </a> 
                                        </div>
                                    </div>
                                    <div className={"grid grid-cols-1 gap-2"}>
                                        {exportData && Object.keys(exportData).map((item, key) => (
                                            <div className={"block w-full"}>
                                                <button key={key} type={"button"} onClick={() => exportTab === item ? setExportTab('') : setExportTab(item)} className={`${item !== exportTab ? 'rounded-lg' : 'rounded-t-lg' } flex font-semibold focus:outline-none block w-full text-left text-sm py-2 px-4 bg-gray-200 hover:bg-gray-300 transition ease-in-out duration-300`}>
                                                    <div className={"w-auto mr-auto"}>
                                                        {item.toUpperCase()}
                                                    </div>
                                                    <FontAwesomeIcon icon={faAngleDown} className={`my-auto transform ${exportTab === item ? '-rotate-90' : 'rotate-0'} transition-all ease-in-out duration-300`} />
                                                </button>
                                                {exportTab === item && (
                                                    <div className={"text-sm px-4 bg-gray-100 text-gray-600 rounded-b-2xl"}>
                                                        {/* @ts-ignore */}
                                                        {exportData[item].map((string: string, index) => (
                                                            <div className={`flex justify-between py-3 ${index !== 0 && 'border-t border-gray-200'}`}>
                                                                <div>
                                                                    <span className={"font-semibold"}>GET</span> {string.replace(Config.apiUrl, '')}
                                                                </div>
                                                                <a href={string} target={"_blank"} rel={"noopener noreferrer"} type={"button"} className={"focus:outline-none"}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="p-6">
                                    <h2 className={"block mb-6"}>Danger Zone</h2>
                                    <p className="mb-8 text-base">
                                        By deleting this project, every data that is stored under this project will be permanently <span className="font-semibold">erased</span> and will not be recoverable.
                                        We recommend you to export all of your data before proceeding with this action.
                                    </p>
                                    <button type={"button"} className={"button-small button-red"} onClick={() => confirmAlert({ message: "By deleting this project, you will lose ALL of your data permanently. Are you sure you want to do this?", value: useProject?.name, onSuccess: () => handleDelete(Number(id)) })}>
                                        Permanently Delete this Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    ) : adminLoaded ? <Redirect to="/" /> : <></>;
}

export default Projects;